import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

// custom validator to check that two fields match
export function EmailArray(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const emailArray = control.value;

    const emails = emailArray.split(",");

    for (const i in emails) {
      const email = emails[i];
      console.log(email);

      if (
        email &&
        !email
          .trim()
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        // console.log("return emailArray error for ", email);
        return { invalidEmailArray: { invalidEmailIndex: i } };
      }
    }
    // console.log("return emailArray success");
    return null;
  };
}
