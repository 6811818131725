import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Quest } from "../quest.model";
import { QuestService } from "../quest.service";

@Component({
  selector: "app-quest-show",
  templateUrl: "./quest-show.component.html",
  styleUrls: ["./quest-show.component.css"],
})
export class QuestShowComponent implements OnInit {
  constructor(private route: ActivatedRoute, private questService: QuestService) {}

  @Input() questId: string;
  @Input() quest: Quest;

  ngOnInit(): void {
    if (!this.quest) this.getQuest();
  }

  getQuest(): void {
    const id = this.questId ? this.questId : this.route.snapshot.paramMap.get("id");

    this.questService.getQuest(id).subscribe((quest) => {
      this.quest = quest;
    });
  }
}
