<div class="row">
  <!-- <div class="col-6 btn btn-secondary text-white" (click)="displayJSON(true)">JSON</div>

  <div class="col-6 btn btn-secondary text-white" (click)="displayJSON(false)">Form Fields</div> -->
  <div class="col-sm-9">
    <div class="btn-group btn-group-toggle text-white" data-toggle="buttons">
      <label class="btn btn-secondary" (click)="displayJSON(true)">
        <input type="radio" name="options" id="option1" autocomplete="off" /> JSON
      </label>
      <label class="btn btn-secondary active" (click)="displayJSON(false)">
        <input type="radio" name="options" id="option2" autocomplete="off" checked /> Form Fields
      </label>
    </div>
  </div>

  <div class="col-sm-3 text-right">
    <div class="btn btn-success" (click)="save()">Save Quest</div>
  </div>
</div>

<div class="row mt-5" *ngIf="showJSON">
  <div class="col-lg-12 mt-5">
    <!-- <textarea name="quest_{{ questId }}" rows="25" cols="10" class="form-control form-control-lg" (change)="onValueChange($event)">
            {{ quest | json }}    
        </textarea
    > -->
    <div
      id="quest-editor-json-{{ quest.id }}"
      class="ace w-100"
      [ace]="config"
      (valueChange)="onValueChange($event)"
      (change)="onContentChange($event)"
      style="height: 1000px"
    ></div>
  </div>
</div>

<div *ngIf="!showJSON && quest">
  <div class="row mt-5">
    <div class="col-lg-3">Name:</div>
    <div class="col-lg-9">
      <input [(ngModel)]="quest.name" name="name" />
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-3">Description:</div>
    <div class="col-lg-9">
      <input [(ngModel)]="quest.description" name="description" />
    </div>
  </div>
  <!-- <div class="row mt-5" *ngFor="let file of quest.files; let j = index; trackBy: trackByFn">
    <div class="col-sm-3">File #{{ j + 1 }}:</div>
    <div class="col-sm-8">
      <input [(ngModel)]="quest.files[j].name" name="quest.files[{{ j }}]" />
    </div>
    <div class="col-sm-1 text-right">
      <div class="btn btn-danger" (click)="deleteFile(j)">X</div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-sm-12 text-right">
      <div class="btn btn-success" (click)="addFile()">Add File</div>
    </div>
  </div> -->
  <div class="row mt-5" *ngFor="let instruction of quest.instructions; let i = index; trackBy: trackByFn">
    <div class="col-sm-3">Instruction #{{ i + 1 }}:</div>
    <div class="col-sm-8">
      <input [(ngModel)]="quest.instructions[i]" name="quest.instruction[{{ i }}]" />
    </div>
    <div class="col-sm-1 text-right">
      <div class="btn btn-danger" (click)="deleteInstruction(i)">X</div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-sm-12 text-right">
      <div class="btn btn-success" (click)="addInstruction()">Add Instruction</div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-3">Code Language:</div>
    <div class="col-lg-9">
      <!-- <input [(ngModel)]="quest.codeLanguage" name="language" /> -->
      <select class="form-control" [(ngModel)]="quest.codeLanguage" name="language" (change)="changeAceMode($event.target.value)">
        <option value="html">HTML</option>
        <option value="javascript">JavaScript</option>
        <option value="json">JSON</option>
        <option value="python">Python</option>
      </select>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-3">Time Limit:</div>
    <div class="col-lg-9">
      <input [(ngModel)]="quest.timeLimit" name="timeLimit" />
    </div>
  </div>
  <div class="row mt-5 no-gutters" style="height: 1050px">
    <div class="col-sm-8 mt-5">
      <div class="col-sm-12 bg-dark text-right">
        <div class="btn-group btn-group-toggle text-white" data-toggle="buttons">
          <label class="btn btn-small btn-secondary" (click)="setDisplayCodeTypeInEditor(0)">
            <input type="radio" name="options" id="option1" autocomplete="off" /> Starting Code
          </label>
          <label class="btn btn-small btn-secondary active" (click)="setDisplayCodeTypeInEditor(1)">
            <input type="radio" name="options" id="option2" autocomplete="off" checked /> Complete Code
          </label>
          <label class="btn btn-small btn-secondary" (click)="setDisplayCodeTypeInEditor(2)">
            <input type="radio" name="options" id="option3" autocomplete="off" /> Code Hint
          </label>
          <label class="btn btn-small btn-secondary" (click)="setDisplayCodeTypeInEditor(3)">
            <input type="radio" name="options" id="option3" autocomplete="off" /> Wrapper Code
          </label>
        </div>
      </div>
      <div class="col-sm-12 bg-dark text-right">
        <div class="btn btn-dark btn-small" *ngIf="quest.codeLanguage == 'html'" [cdkCopyToClipboard]="CodeHelperLinks.bootstrap">
          Copy Bootstrap Link
        </div>
        <div class="btn btn-dark btn-small" *ngIf="quest.codeLanguage == 'html'" [cdkCopyToClipboard]="CodeHelperLinks.jQuery">
          Copy jQuery Link
        </div>
        <div class="btn btn-dark btn-small" *ngIf="quest.codeLanguage == 'html'" [cdkCopyToClipboard]="CodeHelperLinks.canvasHelper">
          Copy Canvas Helper Link
        </div>
        <div class="btn btn-dark btn-small" *ngIf="quest.codeLanguage == 'html'" [cdkCopyToClipboard]="CodeHelperLinks.htmlWrapperCode">
          Copy HTML Wrapper Code
        </div>
        <div class="btn btn-dark btn-small" *ngIf="quest.codeLanguage == 'javascript'" [cdkCopyToClipboard]="CodeHelperLinks.jsWrapperCode">
          Copy Javascript Wrapper Code
        </div>
        <div class="btn btn-success btn-small" *ngIf="quest.codeLanguage == 'html'" (click)="formatHTML()">Beautify</div>
        <div class="btn btn-success btn-small" *ngIf="quest.codeLanguage == 'python'" (click)="runPython()">Run</div>
      </div>
      <div class="col-sm-12 bg-dark text-right">
        <div class="btn-group btn-group-toggle text-white" data-toggle="buttons" *ngFor="let file of quest.files; let i = index">
          <label class="btn btn-small btn-secondary" (click)="showFileCodeInEditor(file)">
            <input type="radio" name="options" id="option1" autocomplete="off" /> {{ file.name }}
          </label>
        </div>
      </div>
      <div
        id="quest-editor-{{ quest.id }}"
        class="ace w-100"
        [ace]="config"
        (valueChange)="onValueChange($event)"
        (change)="onContentChange($event)"
        style="height: 1000px"
      ></div>
    </div>
    <div class="col-sm-4 mt-5 p-0" style="height: 1000px">
      <div class="col-sm-12">
        <div class="row bg-dark">
          <div class="btn btn-small btn-dark text-center text-white col-sm-12">Code Preview</div>
        </div>
      </div>

      <div *ngIf="quest.codeLanguage != 'python'" class="col-sm-12 p-0" style="height: 1000px">
        <app-code-preview [code]="codeForPreview" [wrapperCode]="quest.wrapperCode" [codeLanguage]="quest.codeLanguage"></app-code-preview>
      </div>
      <div *ngIf="quest.codeLanguage == 'python'" class="col-sm-12 p-0" style="height: 1000px">
        <app-python-result [pythonResult]="previewContent"></app-python-result>
      </div>
    </div>
  </div>
</div>
<div class="row mt-5">
  <div class="col-sm-3"></div>
  <div class="col-sm-3"></div>
  <div class="col-sm-3"></div>
  <div class="col-sm-3 text-right">
    <div class="btn btn-success" (click)="save()">Save Quest</div>
  </div>
</div>
