import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { QuestService } from "@app/quests/quest.service";
import { AccountService, GameService, AlertService } from "@app/_services";
import { GameInstanceService } from "../game-instance/game-instance.service";
import { GameInstance } from "../shared/game-instance.model";
import { PlayerService } from "../shared/player.service";
import { Location } from "@angular/common";
@Component({
  selector: "app-game-instance-detail",
  templateUrl: "./game-instance-detail.component.html",
  styleUrls: ["./game-instance-detail.component.css"],
})
export class GameInstanceDetailComponent implements OnInit {
  gameCode: number;
  gameInstance: GameInstance;
  showJSON: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private gameInstanceService: GameInstanceService,
    private location: Location,
    private playerService: PlayerService,
    private accountService: AccountService,
    private gameService: GameService,
    private questService: QuestService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.gameCode = +this.route.snapshot.paramMap.get("gameCode");
    this.gameInstanceService.getGameInstanceByGameCode(this.gameCode).subscribe((gameInstance) => {
      if (!gameInstance) {
        console.log("Empty gameInstance received");
      }
      this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
    });
  }

  save(): void {
    this.gameInstanceService.updateGameInstance(this.gameInstance).subscribe((gameInstance) => {
      this.gameInstance = gameInstance;
      this.alertService.success("Saved changes for this game instance!");
    });
  }

  resetStartingQuest(): void {
    this.gameInstanceService.resetStartingQuest(this.gameInstance).subscribe((gameInstance) => {
      this.gameInstance = gameInstance;
      this.alertService.success("Reset the starting quest for this game instance!");
    });
  }

  displayJSON(val: boolean) {
    this.showJSON = val;
  }

  goBack(): void {
    this.location.back();
  }

  onValueChange(event): void {
    this.gameInstance = JSON.parse(event.target.value);
  }

  public onContentChange(): void {
    console.log("onContentChange");

    // this.getCodeFromEditor();
  }
}
