import { GameInstance } from "@app/games/shared/game-instance.model";
import { Account, Game } from "@app/_models";
import { Stage } from "./stage.enum";

export class Fixture {
  id?: string;
  name: string;
  description: string;
  fixtureTime: Date;
  fixtureLocation: string;
  stage: Stage;
  qualifiedPlayers: (string | Account)[];
  practiceGame: string | Game;
  game: string | Game;
  gameInstance: string | GameInstance;

  constructor(id?: string) {
    if (id) {
      this.id = id;
    } else {
      this.name = "New fixture";
      this.description = "Fixture description";
    }
  }
}
