import { Component, Input, OnInit } from "@angular/core";
import { AccountService, AlertService } from "@app/_services";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Competition } from "../shared/competition.model";
import { Account, Role } from "@app/_models";
import { CompetitionService } from "../shared/competition.service";
import { Fixture } from "../shared/fixture.model";
import { PaymentStatus } from "../shared/paymentStatus.enum";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-competition-show",
  templateUrl: "./competition-show.component.html",
  styleUrls: ["./competition-show.component.css"],
})
export class CompetitionShowComponent implements OnInit {
  account: Account;
  PaymentStatus = PaymentStatus;
  Role = Role;
  competition: Competition;
  fixtures: Fixture[] = [];
  fixtureCount: any = {};
  @Input() competitionId: string;
  referralCode: string = "";
  discount: number = 0;
  showDiscount: boolean = false;
  friendEmails: string;
  friendEmailsArr: string[] = [];
  emailMessage = "";
  sharing = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private competitionService: CompetitionService,
    private location: Location,
    private accountService: AccountService,
    private alertService: AlertService,
    private titleService: Title
  ) {
    this.accountService.account.subscribe((x) => (this.account = x));
  }

  ngOnInit(): void {
    this.referralCode = this.route.snapshot.queryParams["referralCode"] || "";
    this.getCompetition();
  }

  getCompetition(): void {
    const id = this.competitionId ? this.competitionId : this.route.snapshot.paramMap.get("id");

    if (id) {
      this.competitionService.getCompetitionWithFixtures(id).subscribe((competition) => {
        this.competition = competition;
        this.titleService.setTitle(this.competition.name);
        let action = this.route.snapshot.queryParams["action"];
        if (action === "registerPlayer") {
          this.registerPlayer();
        }

        // Scores are now calculated on the server
        this.updateScores();

        for (const fixture of this.competition.fixtures) {
          if (typeof fixture != "string") {
            if (this.fixtureCount[fixture.stage]) this.fixtureCount[fixture.stage]++;
            else this.fixtureCount[fixture.stage] = 1;
          }
        }

        this.emailMessage = "Join me for this coding challenge. I am so ready to beat you :-)!";
      });
    }
  }

  private calculateTotalScores() {
    let index = 0;

    for (let i = 0; i < this.competition.registeredPlayers.length; i++) {
      this.competition.registeredPlayers[i].totalScore = 0;
    }

    for (const competitionPlayer of this.competition.registeredPlayers) {
      for (const fixture of this.competition.fixtures) {
        if (typeof fixture !== "string" && fixture.gameInstance && typeof fixture.gameInstance !== "string") {
          for (const fixturePlayer of fixture.gameInstance.players) {
            if (
              competitionPlayer.account &&
              fixturePlayer.account &&
              typeof competitionPlayer.account != "string" &&
              fixturePlayer.account == competitionPlayer.account.id
            ) {
              const score = fixturePlayer.score;
              for (let q = 0; q < score.length; q++) {
                this.competition.registeredPlayers[index].totalScore += score[q];
              }
            }
          }
        }
      }
      index++;
    }
  }
  alreadyRegistered(): boolean {
    for (const player of this.competition.registeredPlayers) {
      let playerAccount: Account = <Account>player.account;
      if (this.account && playerAccount && playerAccount.id == this.account.id) {
        this.discount = player.discount; //used to show price
        return true;
      }
    }
    return false;
  }

  registerPlayer(): void {
    if (!this.account) {
      this.router.navigate(["/account/login"], {
        queryParams: { returnUrl: this.router.routerState.snapshot.url + "?action=registerPlayer&referralCode=" + this.referralCode },
      });
    } else {
      this.competitionService.registerPlayer(this.competition, this.referralCode).subscribe((registeredPlayers: any) => {
        this.competition.registeredPlayers = registeredPlayers;
      });
    }
  }

  unregisterPlayer(playerId: string): void {
    this.competitionService.unregisterPlayer(this.competition, playerId).subscribe((registeredPlayers: any) => {
      this.competition.registeredPlayers = registeredPlayers;
    });
  }

  getDiscount(): void {
    this.competitionService.getDiscount(this.competition.id, this.referralCode).subscribe((message) => {
      console.log("Discount", message);
      this.discount = message.discount;
      this.showDiscount = true;
    });
  }
  joinGame(gameCode: number, registerAsGuest: boolean) {
    this.router.navigate([`/games/lobby/gameCode/${gameCode}`], { queryParams: { registerAsGuest: registerAsGuest } });
  }

  practiceGame(gameId: string) {
    this.router.navigate([`/games/lobby/game/${gameId}`]);
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.competitionService.updateCompetition(this.competition).subscribe((competition) => {
      this.competition = competition;
      this.alertService.success("Update competition successful");
    });
  }

  moveUp(i: number) {
    // this.competition.fixtures = this.arrayService.moveUp(i, this.competition.fixtures);
  }

  moveDown(i: number) {
    // this.competition.fixtures = this.arrayService.moveDown(i, this.competition.fixtures);
  }

  alreadyPaid(playerId: string = this.account.id): boolean {
    for (const player of this.competition.registeredPlayers) {
      let playerAccount: Account = <Account>player.account;
      if (this.account && playerAccount && playerAccount.id == playerId) {
        if (player.paymentStatus == PaymentStatus.PAID) {
          return true;
        }
      }
    }
    return false;
  }

  markPaymentStatus(playerId: string, paymentStatus: string): void {
    const player = {
      account: playerId,
      paymentStatus: paymentStatus,
    };

    this.competitionService.updatePlayer(this.competition, playerId, player).subscribe((competition) => {
      for (const i in this.competition.registeredPlayers) {
        const playerAccount: Account = <Account>this.competition.registeredPlayers[i].account;
        if (playerAccount.id == playerId) {
          this.competition.registeredPlayers[i].paymentStatus = <PaymentStatus>paymentStatus;
        }
      }
    });
  }

  inviteFriends(playerId: string): void {
    this.friendEmailsArr = this.friendEmails.split(",");

    this.competitionService
      .invite(this.competition.id, playerId, this.friendEmailsArr, this.emailMessage)
      .subscribe((competition) => console.log("invited to " + competition.name));
  }

  shareScores(options: string[]): void {
    this.alertService
      .confirmDialog(
        "This will send email to all players. Send message only if you have not already sent it. Are you sure you want to share scores? "
      )
      .subscribe((dialogReturn) => {
        if (dialogReturn) {
          for (const option of options) {
            switch (option) {
              case "host":
                break;

              case "player":
                this.sharing = true; //disable the button until re-loading of the page
                break;

              default:
                break;
            }
          }

          this.competitionService.shareScores(this.competition.id, options).subscribe((message: any) => {
            this.alertService.success(`Shared scores with ${message.to}`);
          });
        }
      });
  }

  updateScores(): void {
    //TODO - Optimize such that scores are updated only if the newly calculated scores don't match what was on the server
    this.competitionService.updateScores(this.competition.id).subscribe((competition) => {
      this.competition = competition;
      this.competition.registeredPlayers.sort((a, b) => b.totalScore - a.totalScore);
    });
  }
}
