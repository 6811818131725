import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";

import { GameRoutingModule } from "./games-routing.module";
import { GameInstanceComponent } from "./game-instance/game-instance.component";
import { GamesComponent } from "./games.component";
import { GameCreateComponent } from "./game-create/game-create.component";
import { GameLobbyComponent } from "./game-lobby/game-lobby.component";
import { GameDetailComponent } from "./game-detail/game-detail.component";
import { GameJoinComponent } from "./game-join/game-join.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AceModule, AceConfigInterface, ACE_CONFIG } from "ngx-ace-wrapper";
import { GamePlayComponent } from "./game-play/game-play.component";
import { GameEditorComponent } from "./game-editor/game-editor.component";
import { GameLeaderboardComponent } from "./game-leaderboard/game-leaderboard.component";
import { GameQuestComponent } from "./game-quest/game-quest.component";
import { QuestsModule } from "@app/quests/quests.module";
import { CodeModule } from "@app/code/code.module";
import { GameLivePreviewComponent } from "./game-live-preview/game-live-preview.component";
import { GameInstanceDetailComponent } from "./game-instance-detail/game-instance-detail.component";
import { GameFooterComponent } from "./game-footer/game-footer.component";
import { GameShowComponent } from "./game-show/game-show.component";

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
  tabSize: 7,
};

@NgModule({
  declarations: [
    GameInstanceComponent,
    GamesComponent,
    GameCreateComponent,
    GameLobbyComponent,
    GameDetailComponent,
    GameJoinComponent,
    GamePlayComponent,
    GameEditorComponent,
    GameLeaderboardComponent,
    GameQuestComponent,
    GameLivePreviewComponent,
    GameInstanceDetailComponent,
    GameFooterComponent,
    GameShowComponent,
  ],
  imports: [
    CommonModule,
    GameRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    AceModule,
    QuestsModule,
    CodeModule,
  ],
  exports: [GameDetailComponent, GameShowComponent],
  providers: [
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG,
    },
  ],
})
export class GamesModule {}
