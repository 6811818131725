import { Component, OnInit, AfterViewInit } from "@angular/core";

import { AccountService } from "./_services";
import { Account, Role } from "./_models";
import { AppNavigation } from "./_models/appNavigation";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { Title } from "@angular/platform-browser";

@Component({ selector: "app", templateUrl: "app.component.html" })
export class AppComponent implements OnInit, AfterViewInit {
  Role = Role;
  account: Account;
  // navItems: AppNavigation[];

  constructor(private accountService: AccountService, private router: Router, private titleService: Title) {
    this.accountService.account.subscribe((x) => (this.account = x));
  }

  ngOnInit(): void {
    this.titleService.setTitle("Children's Coding Club");
  }

  ngAfterViewInit() {}

  login() {
    this.router.navigate(["/account/login"]);
  }

  logout() {
    this.accountService.logout();
  }
}
