<div class="row text-center">
  <div class="col-sm-12">
    <h1 class="mt-3">
      <span class="badge badge-pill badge-important">
        Waiting for others to finish and get ratings ... Till then enjoy their live code preview ...
      </span>
    </h1>

    <!-- <h1>
      <span class="badge badge-pill badge-important">
        and ensure we have your updated email address - {{ account.email }} - so we can tell you about your ranking</span
      ><br />
      <a routerLink="/account-settings/update" target="_blank" class="btn btn-success btn-small m-2">Update Your Email</a>
    </h1> -->
  </div>
</div>

<div *ngIf="gameInstance && gameInstance.players">
  <div class="row no-gutters d-flex justify-content-center" *ngFor="let player of gameInstance.players; let i = index">
    <!-- For current player, show their code and correct code preview side by side -->
    <div class="col-sm-12" *ngIf="player == gameInstance.players[currentPlayerIndex] && !reviewedSelfCode">
      <div class="row mb-3 mt-3">
        <div class="col-sm-12 text-center">
          <div class="btn btn-medium btn-light text-right text-dark">Did you get your code right? Compare below:</div>
        </div>
        <div class="col-sm-12 text-center">
          <div class="btn-group text-center col-sm-3" role="group">
            <button type="button" class="btn btn-danger" (click)="setAnswerReviewed()">Got it!</button>
          </div>
        </div>
      </div>

      <div class="row d-flex no-gutters">
        <div class="col-sm-6 d-flex flex-fill text-center m-auto">
          <div class="btn btn-small btn-dark btn-block text-extra-large text-center text-white col-sm-12">Your Code</div>
        </div>
        <div class="col-sm-6 d-flex flex-fill text-center">
          <div class="btn btn-small btn-info btn-block text-extra-large text-center text-white col-sm-12">Correct Code</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 p-0" style="height: 1000px" *ngIf="game">
          <app-code-preview
            [wrapperCode]="game.quests[gameInstance.currentQuestIndex].wrapperCode"
            [code]="player.code[gameInstance.currentQuestIndex]"
            [codeLanguage]="game.quests[gameInstance.currentQuestIndex].codeLanguage"
          ></app-code-preview>
        </div>
        <div class="col-sm-6 p-0 text-white border-left" style="height: 1000px" *ngIf="game">
          <app-code-preview
            [wrapperCode]="game.quests[gameInstance.currentQuestIndex].wrapperCode"
            [code]="game.quests[gameInstance.currentQuestIndex].completeCode"
            [codeLanguage]="game.quests[gameInstance.currentQuestIndex].codeLanguage"
          ></app-code-preview>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters d-flex justify-content-center" *ngFor="let player of gameInstance.players; let i = index">
    <!-- Show live preview of everyone other than the player themselves, and either I am not host, or if I am host, then I should be playing -->
    <div
      class="col-sm-12"
      *ngIf="
        player != gameInstance.players[currentPlayerIndex] &&
        (player.account != gameInstance.hostAccount || (player.account == gameInstance.hostAccount && gameInstance.hostPlaying))
      "
    >
      <!-- Ask for rating only if other player has finished their quest, and only for those whose rating has not been done-->
      <div class="row mb-3 mt-3" *ngIf="questCompleteStatus[i] && !playerRatingStatus[i]">
        <!-- <div class="btn btn-small btn-light text-right text-dark col-sm-3">Rating:</div> -->
        <div class="col-sm-12 text-center">
          <div class="btn btn-medium btn-light text-right text-dark">Rate your peer's code below</div>
        </div>
        <div class="col-sm-12 text-center">
          <div class="btn-group col-sm-6" role="group">
            <button type="button" class="btn btn-danger" (click)="peerScore(player.account, 1)">Incorrect</button>
            <button type="button" class="btn btn-warning" (click)="peerScore(player.account, 2)">Mostly Correct</button>
            <button type="button" class="btn btn-success" (click)="peerScore(player.account, 3)">All Correct</button>
          </div>
        </div>
      </div>

      <div class="row bg-dark">
        <div class="btn btn-small btn-dark text-extra-large text-center text-white col-sm-12">
          {{ player.name }}'s Preview - {{ player.playerStatus }}
        </div>
      </div>
      <div class="row bg-dark" *ngIf="amIHost() && (!questCompleteStatus[i] || !hasPlayerRatedAllPlayers(i))">
        <div class="btn btn-small btn-danger text-center text-white col-sm-12" (click)="forceFinish(player.account)">
          Forcefully finish this player's quest (Use with caution only if the player is not responding)
        </div>
      </div>
      <!-- Don't show live preview if I have rated them. If peer scoring is not allowed, then my ratings are dummy for other player, so show me the preview -->
      <div class="row" *ngIf="!playerRatingStatus[i] || (!gameInstance.peerRatingAllowed && !amIHost())">
        <div class="col-sm-12 p-0" style="height: 1000px" *ngIf="game">
          <app-code-preview
            [wrapperCode]="game.quests[gameInstance.currentQuestIndex].wrapperCode"
            [code]="player.code[gameInstance.currentQuestIndex]"
            [codeLanguage]="game.quests[gameInstance.currentQuestIndex].codeLanguage"
          ></app-code-preview>
        </div>
      </div>
    </div>
  </div>
</div>

<app-game-footer [(gameInstance)]="gameInstance"></app-game-footer>
