import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable, of, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Course } from "./course.model";
import { tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CourseService {
  baseUrl = `${environment.apiUrl}/courses`;

  constructor(private http: HttpClient) {}

  addCourse(course: Course): Observable<Course> {
    const url = this.baseUrl + "/create";

    return this.http.post<Course>(url, course).pipe(
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  getCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(this.baseUrl).pipe(
      tap((_) => this.log("fetched courses")),
      catchError(this.handleError<Course[]>("getCourses", []))
    );
  }

  getCourse(id: string): Observable<Course> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Course>(url).pipe(
      tap((_) => this.log("fetched goal with id: `${id}`")),
      catchError(this.handleError<Course>("getCourse"))
    );
  }

  getCourseWithUnits(id: string): Observable<Course> {
    const url = `${this.baseUrl}/${id}/units`;
    return this.http.get<Course>(url).pipe(
      tap((_) => this.log("fetched goal with id: `${id}`")),
      catchError(this.handleError<Course>("getCourseWithUnits"))
    );
  }

  updateCourse(course: Course): Observable<Course> {
    const id = typeof course === "string" ? course : course.id;
    const url = `${this.baseUrl}/${id}`;
    this.log(url);
    return this.http.put<Course>(url, course).pipe(
      tap((_) => this.log("updated course")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  removeUnitFromCourse(courseId: string, unitId: string): Observable<Course> {
    const url = `${this.baseUrl}/${courseId}/units/${unitId}`;

    return this.http.delete<Course>(url).pipe(
      tap((_) => this.log(`removed unit id=${unitId} from course`)),
      catchError(this.handleError<Course>("removeUnitFromCourse"))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    // RAVI - TODO: Implement messageService from heroes tutorial
    // this.messageService.add(`HeroService: ${message}`);
  }
}
