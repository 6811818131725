<section class="text-left">
  <div class="container">
    <div class="row mt-3">
      <div class="col-12">
        <div class="text-medium text-bold">
          <button class="btn btn-link">
            <a [routerLink]="['/coursework']" routerLinkActive="router-link-active">All Courses</a>
          </button>
          <button class="btn btn-link ml-3 mr-3">/</button>
          <button class="btn btn-link">
            <a [routerLink]="['/coursework/', courseId, 'show']" routerLinkActive="router-link-active">{{ courseName }} </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container">
  <div class="row mt-5 text-center" *ngIf="unit">
    <div class="col-sm-12">
      <h2 class="display-4">{{ unit.gameName }}</h2>
      <h4>{{ unit.description }}</h4>
    </div>
  </div>
  <div class="row" *ngIf="unit">
    <div class="col-12">
      <div class="progress mb-2" *ngIf="account && unitGameMap && unit.games">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          [ngStyle]="{ width: (unitGameMap.get(unit.id) * 100) / unit.games.length + '%' }"
        >
          {{ (unitGameMap.get(unit.id) * 100) / unit.games.length | number: "1.0-0" }}%
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5 flex-fill d-flex align-items-stretch justify-content-center">
    <div class="col-lg-4 flex-fill d-flex align-items-stretch mb-5" *ngFor="let game of games; let i = index">
      <div class="card flex-fill align-items-stretch rounded-0 text-center">
        <!-- <img src="..." class="card-img-top" alt="..." /> -->
        <a routerLink="/games/{{ game._id }}/show">
          <div class="card-body text-center">
            <h2 class="card-title">
              {{ game.name }}
            </h2>

            <p class="card-text">{{ game.description }}</p>
          </div>
        </a>
        <div class="mt-auto">
          <!-- <span
            *ngIf="(account && account.role === Role.Admin) || (account && game.createdBy.toString() == account.id.toString())"
            class="btn btn-small btn-link col-lg-3 m-2"
            (click)="delete(game)"
          >
            Delete
          </span> -->
          <!-- <a routerLink="/games/{{ game._id }}/show" class="btn btn-small btn-danger col-6 m-2">Play now</a> -->
          <div
            *ngIf="
              game.gameSubscription == 'FREE' ||
                (account && game.gameSubscription == 'PREMIUM' && account.accountSubscription == 'PREMIUM');
              else dontAllowHosting
            "
          >
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-small btn-danger dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Play Now
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" routerLink="/games/lobby/game/{{ game._id }}/" [queryParams]="{ peerRatingAllowed: false }"
                  >Play Alone</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/games/lobby/game/{{ game._id }}/" [queryParams]="{ peerRatingAllowed: true }"
                  >Play with friends & rate each other</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/games/lobby/game/{{ game._id }}/" [queryParams]="{ peerRatingAllowed: false }"
                  >Play with friends & only you rate others</a
                >
              </div>
            </div>
          </div>
          <div [ngClass]="gameScoreMap.get(game._id) > -1 ? 'btn btn-large text-info' : 'btn btn-large text-danger'" *ngIf="account">
            {{ gameScoreMap.get(game._id) > -1 ? "Your Best Score: " + gameScoreMap.get(game._id) : "Not Played" }}
          </div>
          <ng-template #dontAllowHosting>
            <span class="btn btn-small btn-black">Play</span>
          </ng-template>
          <br />
          <a routerLink="/games/{{ game._id }}/edit" class="btn btn-small btn-link col-6 m-2" *ngIf="account && account.role === Role.Admin"
            >Edit</a
          >
        </div>
      </div>
      <!-- <div id="accordion_unit">
          <div class="card">
            <div class="card-header" id="heading_game_{{ i }}">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse_game_' + i"
                      aria-expanded="true"
                      aria-controls="collapse_game_{{ i }}"
                    >
                      Game #{{ i + 1 }}: {{ game.name }}
                    </button>
                  </h5>
                </div>
              </div>
            </div>

            <div id="collapse_game_{{ i }}" class="collapse" aria-labelledby="heading_game_{{ i }}" data-parent="#accordion_unit">
              <div class="card-body">
                <app-game-show [gameId]="game._id"></app-game-show>
              </div>
            </div>
          </div>
        </div> -->
    </div>
  </div>
  <!-- Below two divs needed to allow the dropdown button to show -->
  <div class="m-5">&nbsp;</div>
  <div class="m-5">&nbsp;</div>
  <div class="m-5">&nbsp;</div>
  <div class="m-5">&nbsp;</div>
  <div class="m-5">&nbsp;</div>
  <div class="m-5">&nbsp;</div>
  <div class="m-5">&nbsp;</div>
  <div class="m-5">&nbsp;</div>
</div>
