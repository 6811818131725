import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AssignmentsRoutingModule } from "./assignments-routing.module";
import { AssignmentDetailComponent } from "./assignment-detail/assignment-detail.component";
import { AssignmentsComponent } from "./assignments.component";

@NgModule({
  declarations: [AssignmentDetailComponent, AssignmentsComponent],
  imports: [CommonModule, AssignmentsRoutingModule],
  exports: [AssignmentDetailComponent],
})
export class AssignmentsModule {}
