import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-python-result",
  templateUrl: "./python-result.component.html",
  styleUrls: ["./python-result.component.css"],
})
export class PythonResultComponent implements OnInit, OnChanges {
  @Input() pythonResult: any;

  constructor() {}

  ngOnInit(): void {
    if (!this.pythonResult) {
      this.pythonResult = { result: "Your python result will show here ...", type: "info" };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("---Inside ngOnChanges---");
    console.log(this.pythonResult);
  }
}
