import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlertService, AccountService } from "@app/_services";
import { Account, Role } from "@app/_models";
import { Course } from "../shared/course.model";
import { CourseService } from "../shared/course.service";
import { Location } from "@angular/common";
import { UnitService } from "@app/units/shared/unit.service";
import { Unit } from "@app/units/shared/unit.model";
import { ArrayService } from "@app/_services/array.service";

@Component({
  selector: "app-course-detail",
  templateUrl: "./course-detail.component.html",
  styleUrls: ["./course-detail.component.css"],
})
export class CourseDetailComponent implements OnInit {
  account: Account;
  Role = Role;
  course: Course;
  units: Unit[];
  showJSON: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private location: Location,
    private accountService: AccountService,
    private unitService: UnitService,
    private alertService: AlertService,
    private arrayService: ArrayService
  ) {
    this.accountService.account.subscribe((x) => (this.account = x));
  }

  ngOnInit(): void {
    this.getCourse();
    this.unitService.getUnits().subscribe((units) => (this.units = units));
  }

  getCourse(): void {
    const id = this.route.snapshot.paramMap.get("id");
    this.courseService.getCourse(id).subscribe((course) => {
      this.course = course;
    });
  }

  addUnitToCourse(unit: Unit): void {
    if (unit) {
      unit.courses.push(this.course.id);
      this.unitService.updateUnit(unit).subscribe((updatedUnit) => {
        unit = updatedUnit;
        console.log(unit);
      });

      this.course.units.push(unit.id);
      this.courseService.updateCourse(this.course).subscribe((course) => {
        this.course = course;
        console.log(this.course);
      });
    } else {
      let newUnit = new Unit();
      newUnit.courses.push(this.course.id);

      this.unitService.addUnit(newUnit).subscribe((unit) => {
        // this.course.units[this.course.units.length - 1] = unit.id;
        this.course.units.push(unit.id);
        this.courseService.updateCourse(this.course).subscribe((course) => {
          this.course = course;
          console.log(this.course);
        });
      });
    }
  }

  removeUnitFromCourse(i: number) {
    this.courseService.removeUnitFromCourse(this.course.id, this.course.units[i].toString()).subscribe((course) => (this.course = course));
    this.unitService.removeCourseFromUnit(this.course.units[i].toString(), this.course.id).subscribe((unit) => {});
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.courseService.updateCourse(this.course).subscribe((course) => {
      this.course = course;
      this.alertService.success("Update course successful");
    });
  }

  onValueChange(event): void {
    this.course = JSON.parse(event.target.value);
  }

  displayJSON(val: boolean) {
    this.showJSON = val;
  }

  moveUp(i: number) {
    this.course.units = this.arrayService.moveUp(i, this.course.units);
  }

  moveDown(i: number) {
    this.course.units = this.arrayService.moveDown(i, this.course.units);
  }
}
