import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Competition } from "../shared/competition.model";
import { CompetitionService } from "../shared/competition.service";
import { Account, Role } from "@app/_models";
import { AccountService } from "@app/_services";
import { Fixture } from "../shared/fixture.model";
import { CompetitionType } from "../shared/competitionType.model";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-competition-summary",
  templateUrl: "./competition-summary.component.html",
  styleUrls: ["./competition-summary.component.css"],
})
export class CompetitionSummaryComponent implements OnInit {
  account: Account;
  Role = Role;
  competition: Competition;
  competitionType = CompetitionType;
  message: string;

  @Input() competitionId: string;

  constructor(private route: ActivatedRoute, private competitionService: CompetitionService, private accountService: AccountService) {
    this.accountService.account.subscribe((x) => (this.account = x));
  }

  ngOnInit(): void {
    this.getCompetition();
  }

  getCompetition(): void {
    const id = this.competitionId ? this.competitionId : this.route.snapshot.paramMap.get("id");

    if (id) {
      this.competitionService.getCompetitionWithFixtures(id).subscribe((competition) => {
        this.competition = competition;
      });
    }
  }

  delete(competition: Competition): void {
    this.competitionService.deleteCompetition(competition).subscribe(
      (response) => {
        console.log("Delete competition response " + response);
        this.competition = null;
        // const removedCompetitionIndex = this.allCompetitions.findIndex((item) => item.id == competition.id);
        // this.allCompetitions.splice(removedCompetitionIndex, 1);
        //Next callback
        console.log("response received" + response);
        // this.repos = response;
      },
      (error) => {
        //Error callback
        console.log("error caught in component" + error);
        // this.errorMessage = error;
        // this.loading = false;

        //throw error;   //You can also throw the error to a global error handler
      }
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log();

      this.message = error.message;
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log() {
    // RAVI - TODO: Implement messageService from heroes tutorial
    // this.messageService.add(`HeroService: ${message}`);
  }
}
