<div class="row no-gutters">
  <div class="col-sm-12 ml-5"></div>
  <div class="col-sm-8 pr-0 pl-5">
    <div class="text-info font-weight-bold">
      <h1 class="text-info font-weight-bold">{{ quest.name }}</h1>
      <h3 class="text-info font-weight-bold">{{ quest.description }}</h3>
    </div>
    <h2 class="text-info font-weight-bold">Instructions</h2>
    <h2 class="text-info">
      <ul class="text-medium">
        <li *ngFor="let instruction of quest.instructions" class="mt-4">
          {{ instruction }}
        </li>
      </ul>
    </h2>
  </div>

  <!-- HTML Javascript preview and code hint -->
  <div class="col-sm-4 m-0 p-0" *ngIf="quest.codeLanguage != 'python'">
    <div class="col-sm-12">
      <div class="row bg-info">
        <div class="btn btn-small btn-dark text-center col-sm-8" (click)="showCode(false)">Quest Preview</div>
        <div class="btn btn-small btn-danger text-center col-sm-4" (click)="showCode(true)">Code Hint</div>
      </div>
    </div>

    <div class="col-sm-12 p-0" *ngIf="showPreview" style="max-height: 400px; overflow-y: scroll">
      <iframe #questIframe frameborder="1" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="col-sm-12 p-0" *ngIf="!showPreview" style="max-height: 400px; overflow-y: scroll">
      <pre>
        {{ quest.codeHint }}
      </pre>
    </div>
  </div>

  <!-- Python and code hint -->
  <div class="col-sm-4 m-0 p-0" *ngIf="quest.codeLanguage == 'python'">
    <div class="col-sm-12">
      <div class="row">
        <div class="btn btn-success text-white text-center col-sm-6 text-wrap" (click)="showCode(false)">
          Quest Result
          <br />
          <br /><span class="text-lowercase"> (Expected result from your code)</span>
        </div>
        <div class="btn btn-danger text-center col-sm-6 text-wrap" (click)="showCode(true)">
          Code Hint <br />
          <br /><span class="text-lowercase"> (Looking up "Code Hint" reduces your points)</span>
        </div>
      </div>
      <div class="row text-danger">
        <div class="col-sm-12 text-right"></div>
      </div>
    </div>

    <div class="col-sm-12 p-0" *ngIf="showPreview" style="height: 400px; overflow-y: scroll">
      <app-python-result [pythonResult]="previewContent"></app-python-result>
    </div>
    <div class="col-sm-12 p-0 bg-danger" *ngIf="!showPreview" style="height: 400px; overflow-y: scroll">
      <br />
      <pre
        >{{ quest.codeHint }}
      </pre>
    </div>
  </div>
</div>
