import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GameInstance } from "../shared/game-instance.model";
import { GameInstanceService } from "../game-instance/game-instance.service";

import { AccountService, GameService, AlertService } from "@app/_services";
import { Game } from "../shared/game.model";
import { QuestService } from "../../quests/quest.service";
import { Subscription, Observable, interval, of } from "rxjs";
import { QuestStatus } from "../../quests/questStatus.model";
import { Player } from "../shared/player.model";
import { GameInstanceStatus } from "../shared/game-instance-status.model";
import { PlayerService } from "../shared/player.service";
import { Account } from "@app/_models/account";
import { take, map, max, delay } from "rxjs/operators";
import { Quest } from "@app/quests/quest.model";
import { PythonService } from "@app/_services/python.service";
import { Time } from "@angular/common";

@Component({
  selector: "app-game-play",
  templateUrl: "./game-play.component.html",
  styleUrls: ["./game-play.component.css"],
})
export class GamePlayComponent implements OnInit, OnDestroy, AfterViewInit {
  currentPlayerIndex: number;

  gameInstance: GameInstance;
  gameCode: number;
  game: Game;
  gameCountdown: number = 10000; //Intial value such that leaderboard doesn't show up immediately
  gameTimer: any; //should be NodeJS.Timeout

  hostNextStep: string;
  playerNextStep: string;
  playerUpdateTimer: any; //should be //NodeJS.Timeout
  endGameMessage: Subscription;
  joinMessage: Subscription;
  leaveMessage: Subscription;
  forceFinishMessage: Subscription;

  userCodeChanged: boolean = false;
  questStatusChanged: boolean = false;
  questStartTime: number;

  gameAudio: HTMLAudioElement;
  showPlayAudioBtn: boolean;

  pythonResult: any = { result: "Your python result will show here ...", type: "info" };

  constructor(
    private route: ActivatedRoute,
    private gameInstanceService: GameInstanceService,
    private playerService: PlayerService,
    private accountService: AccountService,
    private gameService: GameService,
    private questService: QuestService,
    private alertService: AlertService,
    private router: Router,
    private pythonService: PythonService
  ) {}

  ngOnInit(): void {
    this.gameCode = +this.route.snapshot.paramMap.get("gameCode");
    this.gameInstanceService.getGameInstanceByGameCode(this.gameCode).subscribe((gameInstance) => {
      if (!gameInstance) {
        console.log("Empty gameInstance received");
      }
      this.gameInstance = gameInstance ? gameInstance : this.gameInstance;

      this.gameService.getGameWithQuests(this.gameInstance.game.toString()).subscribe((game) => {
        this.game = game;
        for (let i = 0; i < this.gameInstance.players.length; i++) {
          if (this.gameInstance.players[i].account == this.accountService.accountValue.id) {
            this.currentPlayerIndex = i;
          }
        }
        //Don't reload the quest if the quest is already finished (e.g. in case of pagee reload) or answer has been reviewed
        let questStatus = this.gameInstance.players[this.currentPlayerIndex].questStatus[this.gameInstance.currentQuestIndex];
        if (questStatus == QuestStatus.FINISHED || questStatus == QuestStatus.ANSWERREVIEWED) {
          this.playerDoneWithCurrentQuest();
        } else {
          this.getQuestByIndex(this.gameInstance.currentQuestIndex);
          this.questStartTime = Date.now();
        }
      });

      //Subsribe to messages about new players
      this.joinMessage = this.gameInstanceService.getJoinMessage().subscribe((messageObj: any) => {
        console.log(
          "Join message (game-play) received message: " + messageObj.accountId + " currentQuestIndex: " + messageObj.currentQuestIndex
        );
        //New player joined, get updated list of players
        // this.gameInstanceService.getGameInstanceByGameCode(this.gameInstance.gameCode).subscribe((gameInstance) => {
        //   if (!gameInstance) {
        //     console.log("Empty gameInstance received");
        //   }
        //   this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
        // });
        this.gameInstance = messageObj.gameInstance;
      });

      //Subsribe to messages about player leaving
      this.leaveMessage = this.gameInstanceService.getLeaveMessage().subscribe((messageObj: any) => {
        console.log("getLeaveMessage received message: " + messageObj.accountId + " currentQuestIndex: " + messageObj.currentQuestIndex);
        //Player left, get updated list of players
        this.gameInstance = messageObj.gameInstance ? messageObj.gameInstance : this.gameInstance;
      });
      //Subsribe to end message
      this.endGameMessage = this.gameInstanceService.getEndGameMessage().subscribe((messageObj: any) => {
        console.log("getEndGameMessage received message: currentQuestIndex: " + messageObj.currentQuestIndex);
      });

      //Subsribe to forceFinish message
      this.forceFinishMessage = this.gameInstanceService.getForceFinishMessage().subscribe((messageObj: any) => {
        if (messageObj.accountId == this.accountService.accountValue.id && messageObj.gameInstance.gameCode == this.gameInstance.gameCode) {
          this.gameInstance = messageObj.gameInstance;
          this.stopAudio();
          this.showLivePreview(true);
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.gameAudio = new Audio();
    this.gameAudio.src = "../../../assets/audio/game-music-hindi-prayer.mp3";

    this.playAudio();
  }

  ngOnDestroy() {
    this.joinMessage.unsubscribe();
    this.leaveMessage.unsubscribe();
    this.endGameMessage.unsubscribe();
    this.forceFinishMessage.unsubscribe();

    if (this.gameTimer) {
      console.log("Clearing gameTimer " + this.gameTimer);
      clearInterval(this.gameTimer);
    }
    if (this.playerUpdateTimer) {
      console.log("Clearing playerUpdateTimer " + this.playerUpdateTimer);
      clearInterval(this.playerUpdateTimer);
    }

    this.stopAudio();
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if game has finished
    if (
      this.gameInstance.status == GameInstanceStatus.FINISHED ||
      this.gameInstance.players[this.currentPlayerIndex].questStatus[this.gameInstance.currentQuestIndex] == QuestStatus.FINISHED ||
      this.gameInstance.players[this.currentPlayerIndex].questStatus[this.gameInstance.currentQuestIndex] == QuestStatus.ANSWERREVIEWED
    ) {
      return true;
    }

    const confirmDialog$ = this.alertService.confirmDialog("Do you really want to quit the game?");
    confirmDialog$.subscribe((dialogReturn) => {
      console.log("return value: " + dialogReturn);
      if (dialogReturn) {
        this.gameInstanceService.leaveGame(this.gameInstance);
      }
    });
    // Otherwise ask the user with the dialog service and return its observable which resolves to true or false when the user decides
    return confirmDialog$;
  }

  onUserCodeChanged(userCode: string) {
    this.gameInstance.players[this.currentPlayerIndex].code[this.gameInstance.currentQuestIndex] = userCode;

    this.userCodeChanged = true;
  }

  showLivePreview(forceFinish: boolean = false) {
    console.log("Inside showLivePreview()");
    this.router.navigate([`/games/livePreview/${this.gameInstance.gameCode}`], { queryParams: { forceFinish: forceFinish } });
  }

  //called from html template as well as when gameCountdown runs out
  playerDoneWithCurrentQuest() {
    //Clear the timers so that updateGameInstancePlayer doesn't get called because of timer
    this.clearTimers();

    let questStatus = this.gameInstance.players[this.currentPlayerIndex].questStatus[this.gameInstance.currentQuestIndex];
    if (questStatus != QuestStatus.FINISHED && questStatus != QuestStatus.ANSWERREVIEWED) {
      this.gameInstance.players[this.currentPlayerIndex].questStatus[this.gameInstance.currentQuestIndex] = QuestStatus.FINISHED;
      this.gameInstance.players[this.currentPlayerIndex].questDuration[this.gameInstance.currentQuestIndex] = Math.floor(
        (Date.now() - this.questStartTime) / 1000
      );
      this.questStatusChanged = true;

      this.updateGameInstancePlayer();
    }

    this.stopAudio();

    this.showLivePreview();
  }

  private clearTimers() {
    if (this.gameTimer) {
      console.log("Clearing gameTimer " + this.gameTimer);
      clearInterval(this.gameTimer);

      //Ensure that the time is really cleared
      this.gameTimer = null;
      while (this.gameTimer !== null) {
        this.gameTimer = null;
      }
    }
    if (this.playerUpdateTimer) {
      console.log("Clearing playerUpdateTimer " + this.playerUpdateTimer);
      clearInterval(this.playerUpdateTimer);

      //Ensure that the time is really cleared
      this.playerUpdateTimer = null;
      while (this.playerUpdateTimer !== null) {
        this.playerUpdateTimer = null;
      }
    }
  }

  public isLastQuestDone() {
    return this.gameInstance.currentQuestIndex >= this.game.quests.length - 1;
  }

  public amIHost(): boolean {
    if (this.gameInstance && this.gameInstance.hostAccount.toString() == this.accountService.accountValue.id.toString()) {
      return true;
    }
    return false;
  }

  private startGameTimer(): any {
    console.log("starting a new gameTimeer");
    // console.trace();
    return setInterval(() => {
      console.log("Inside gameTimer " + this.gameTimer + " for: " + this.accountService.accountValue.firstName);
      this.gameCountdown--;

      if (this.gameCountdown <= 0) {
        //Time is up. Score and show leaderboard
        this.playerDoneWithCurrentQuest();
      }
    }, 1000);
  }

  private startPlayerUpdateTimer(): any {
    console.log("Inside playerUpdateTimer");
    //avoid updating all player codes at the same time by delaying updating based on player index
    return setInterval(() => {
      let secondsRemainingInQuest = this.gameInstance.currentQuestTimeLimit - this.timeElapsedSinceQuestStarted();
      if (secondsRemainingInQuest < this.currentPlayerIndex * 5) {
        return;
      }

      interval(this.currentPlayerIndex * 5000)
        .pipe(take(1))
        .subscribe(() => this.updateGameInstancePlayer());
    }, 30000);
  }

  private getQuestByIndex(index: number) {
    console.log("getQuestByIndex: Getting next quest");
    if (index < this.game.quests.length) {
      let quest: Quest = <Quest>this.game.quests[index];

      this.gameCountdown = quest.timeLimit - this.timeElapsedSinceQuestStarted();
      this.playAudio();

      //During next playerUpdate, ensure that its questStatus is marked correctly
      //Ideally this should be set on server side, here it will result in delayed update of status on server
      this.questStatusChanged = true;
      this.gameInstance.players[this.currentPlayerIndex].questStatus[this.gameInstance.currentQuestIndex] = QuestStatus.INPROGRESS;

      // console.log(this.gameInstance);
      //If user doesn't have any code and the quest is not the first quest, use previous quest's codee as the starting point
      if (this.gameInstance.players[this.currentPlayerIndex].code[this.gameInstance.currentQuestIndex] == "") {
        if (quest.startingCode && quest.startingCode != "") {
          this.gameInstance.players[this.currentPlayerIndex].code[this.gameInstance.currentQuestIndex] = quest.startingCode;
        } else {
          if (this.gameInstance.currentQuestIndex > 0) {
            this.gameInstance.players[this.currentPlayerIndex].code[this.gameInstance.currentQuestIndex] =
              this.gameInstance.players[this.currentPlayerIndex].code[this.gameInstance.currentQuestIndex - 1];
          }
        }
      }
      // console.log(this.gameInstance);

      // console.log(this.gameInstance.players[this.currentPlayerIndex].code[this.gameInstance.currentQuestIndex]);

      //start the player update timer after certain amount of time, to avoid all players trying to update their code at the same time
      this.playerUpdateTimer = this.startPlayerUpdateTimer(); //setTimeout(this.startPlayerUpdateTimer, this.currentPlayerIndex * 5);
      this.gameTimer = this.startGameTimer();
      // });
      // }
    } else {
      //record status for the game as "DONE", if I am host
      //end game
      this.showLivePreview();
    }
  }

  private timeElapsedSinceQuestStarted(): number {
    return this.gameInstance ? Math.floor((Date.now() - this.gameInstance.currentQuestStartTime) / 1000) : 0;
  }

  private updateGameInstancePlayer(runCode = false) {
    console.log("updating player - 1");
    if (this.userCodeChanged || this.questStatusChanged) {
      const updatedGameInstance = {
        gameCode: +this.gameCode,
        currentQuestIndex: this.gameInstance.currentQuestIndex,
        currentQuestTimeLimit: this.gameInstance.currentQuestTimeLimit,
        players: [this.gameInstance.players[this.currentPlayerIndex]],
      };

      console.log("updating player - 2");
      if (this.gameInstance.players[this.currentPlayerIndex].questStatus[this.gameInstance.currentQuestIndex] == QuestStatus.FINISHED) {
        this.gameInstanceService
          .updateUserCode(updatedGameInstance, true)
          .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));
      } else {
        this.gameInstanceService.updateUserCode(updatedGameInstance).subscribe((gameInstance) => {
          this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
          if (runCode) {
            this.pythonResult = { result: "Executing...", type: "info" };

            this.pythonService.run(this.accountService.accountValue.urlName, this.game.gameFile).subscribe((pythonOutput: any) => {
              console.log("printing result");
              console.log(pythonOutput);

              this.pythonResult = pythonOutput;
            });
          }
        });
      }

      //reset userCodeChanged and questStatusChanged
      this.userCodeChanged = this.questStatusChanged = false;
    }
  }

  playAudio() {
    if (this.amIHost()) {
      // this.gameAudio.load();
      // this.gameAudio.play();
      this.showPlayAudioBtn = false;
    }
  }

  stopAudio() {
    if (this.amIHost()) {
      // this.gameAudio.pause();
      this.gameAudio.currentTime = 0;
      this.showPlayAudioBtn = true;
    }
  }

  runPython() {
    this.userCodeChanged = true;
    this.updateGameInstancePlayer(true);
  }

  getCurrentQuestCodeLanguage(): string {
    let quest = <Quest>this.game.quests[this.gameInstance.currentQuestIndex];
    return quest.codeLanguage;
  }
}
