import { Component, OnInit } from "@angular/core";
import { UnitService } from "./shared/unit.service";
import { Unit } from "./shared/unit.model";

@Component({
  selector: "app-units",
  templateUrl: "./units.component.html",
  styleUrls: ["./units.component.css"],
})
export class UnitsComponent implements OnInit {
  constructor(private unitService: UnitService) {}
  units: Unit[];

  ngOnInit(): void {
    this.getUnits();
  }

  getUnits(): void {
    this.unitService.getUnits().subscribe((units) => (this.units = units));
  }
}
