import { Role } from "./role";

export class Account {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  phone: string;
  parentEmail: string;
  about: { credentials: string[]; headline: string };
  nickName: string;
  urlName: string;
  codecoins: number;
  codemoji: string;
  email: string;
  role: Role;
  accountSubscription: string;
  jwtToken?: string;

  constructor({
    id,
    title,
    firstName,
    lastName,
    email,
    role,
    accountSubscription,
    jwtToken,
    phone,
    parentEmail,
    about,
  }: {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
    accountSubscription: string;
    jwtToken?: string;
    phone: string;
    parentEmail: string;
    about: { credentials: string[]; headline: string };
  }) {
    this.id = id;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.parentEmail = parentEmail;
    this.about = about;
    this.role = role;
    this.accountSubscription = accountSubscription;
    if (jwtToken) {
      this.jwtToken = jwtToken;
    }
  }
}
