import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { GamesModule } from "./games/games.module";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";

// used to create fake backend
import { fakeBackendProvider } from "./_helpers";

import { AppRoutingModule } from "./app-routing.module";
import { JwtInterceptor, ErrorInterceptor, appInitializer } from "./_helpers";
import { AccountService } from "./_services";
import { AppComponent } from "./app.component";
import { AlertComponent } from "./_components";
import { HomeComponent } from "./app-home";
import { MessagesComponent } from "./messages/messages.component";
import { CourseworkModule } from "./coursework/coursework.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { QuestsModule } from "./quests/quests.module";
import { environment } from "@environments/environment";
import { AssignmentsModule } from "./assignments/assignments.module";
import { CompetitionsModule } from "./competitions/competitions.module";

const config: SocketIoConfig = {
  url: environment.socketIOURL,
  options: { path: environment.socketIOOptionsPath },
};

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    GamesModule,
    CompetitionsModule,
    CourseworkModule,
    QuestsModule,
    AssignmentsModule,
    SocketIoModule.forRoot(config),
    BrowserAnimationsModule,
  ],
  declarations: [AppComponent, AlertComponent, HomeComponent, MessagesComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AccountService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
