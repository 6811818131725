<div
  class="container"
  style="min-height: 100%"
  [ngClass]="{
    'bg-danger': pythonResult.type == 'error',
    'bg-success': pythonResult.type == 'output',
    'bg-info': pythonResult.type == 'info'
  }"
>
  <div class="row">
    <div class="col-12">
      <h2>
        <pre
          >{{ pythonResult.result }}
        </pre>
      </h2>
    </div>
  </div>
</div>
