import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HttpClientModule } from "@angular/common/http";

import { CourseworkRoutingModule } from "./coursework-routing.module";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AceModule, AceConfigInterface, ACE_CONFIG } from "ngx-ace-wrapper";
import { CoursesComponent } from "./courses.component";
import { CourseDetailComponent } from "./course-detail/course-detail.component";
import { UnitsModule } from "@app/units/units.module";
import { CourseworkCreateComponent } from './coursework-create/coursework-create.component';
import { CourseShowComponent } from './course-show/course-show.component';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
  tabSize: 7,
};

@NgModule({
  declarations: [CoursesComponent, CourseDetailComponent, CourseworkCreateComponent, CourseShowComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CourseworkRoutingModule,
    HttpClientModule,
    ClipboardModule,
    AceModule,
    UnitsModule,

    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    // HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
    //   dataEncapsulation: false,
    // }),
  ],
  providers: [
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG,
    },
  ],
})
export class CourseworkModule {}
