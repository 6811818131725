import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Course } from "@app/coursework/shared/course.model";
import { CourseService } from "@app/coursework/shared/course.service";
import { Account, Game, Role } from "@app/_models";
import { GameData } from "@app/_models/gameData";
import { AccountService, GameService } from "@app/_services";
import { AccountGameService } from "@app/_services/account-game.service";
import { Unit } from "../shared/unit.model";
import { UnitService } from "../shared/unit.service";

@Component({
  selector: "app-unit-show",
  templateUrl: "./unit-show.component.html",
  styleUrls: ["./unit-show.component.css"],
})
export class UnitShowComponent implements OnInit {
  constructor(
    private unitService: UnitService,
    private courseService: CourseService,
    private gameService: GameService,
    private accountService: AccountService,
    private accountGameService: AccountGameService,
    private route: ActivatedRoute
  ) {}
  unit: Unit;
  games: Game[] = [];
  @Input() unitId: string;
  courseName: string;
  courseId: string;
  account: Account;
  Role = Role;
  gameData: GameData[];
  unitGameMap = new Map();
  gameScoreMap = new Map();

  ngOnInit(): void {
    this.accountService.account.subscribe((x) => (this.account = x));

    this.getUnitWithGames();
  }

  getUnitWithGames(): void {
    const id = this.unitId ? this.unitId : this.route.snapshot.paramMap.get("id");

    this.unitService.getUnitWithGames(id).subscribe((unit) => {
      this.unit = unit;
      this.games = <Game[]>(<unknown>unit.games);

      this.getBreadcrumbs();
      this.fetchGameData();
    });
  }

  getBreadcrumbs(): void {
    this.courseService.getCourse(<string>this.unit.courses[0]).subscribe((course) => {
      this.courseName = course.name;
      this.courseId = course.id;
    });
  }

  private fetchGameData() {
    if (this.account) {
      this.accountGameService.accountGame.subscribe((x) => {
        if (x) {
          this.gameData = x.gameData;
          let unitId = this.unit.id;

          if (!this.unitGameMap.get(unitId)) {
            this.unitGameMap.set(unitId, this.gameData.filter((v) => v.unit === unitId && v.bestScore > -1).length);
          }

          for (const item of this.gameData) {
            this.gameScoreMap.set(item.game, item.bestScore);
          }
        }
      });
    }
  }
}
