<div class="container mt-5">
  <div class="row mt-5 text-center">
    <div class="col-12">
      <div class="display-4">Let the Coding Games Begin</div>
      <h4 class="mt-2 text-primary">Learn HTML, Javascript, CSS or Python using fun games.</h4>
      <!-- <h2 class="badge-pill badge-light text-center ml-auto mr-auto col-lg-4">Click on courses below to get started.</h2> -->
    </div>
  </div>

  <div class="mb-5 text-center text-dark" *ngFor="let course of courses">
    <div class="row d-flex align-items-stretch no-gutters">
      <div class="col-lg-3 d-flex align-items-stretch rounded-0 border-right-0 text-bold-underline">
        <div class="card flex-fill bg-light">
          <!-- <img src="..." class="card-img-top" alt="..." /> -->
          <div class="card-header">
            <h1 class="card-title">
              {{ course.name }}
            </h1>
            <i class="fa fa-book"></i>&nbsp;{{ course.units.length }} Units
          </div>
          <div class="card-body text-center">
            <p class="card-text">{{ course.description }}</p>
          </div>
          <div class="mt-auto">
            <a routerLink="/coursework/{{ course.id }}/show" class="btn btn-primary col-12 m-2">See All Units</a>
          </div>
          <div class="mt-auto mb-2">
            <a
              routerLink="/coursework/{{ course.id }}/edit"
              class="btn btn-small btn-link col-6 m-2"
              *ngIf="account && account.role === Role.Admin"
              >Edit</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-3 d-flex align-items-stretch rounded-0" *ngFor="let unit of course.units | slice: 0:3">
        <div class="card flex-fill">
          <!-- <img src="..." class="card-img-top" alt="..." /> -->
          <a routerLink="/units/{{ unit._id }}/show">
            <div class="card-body text-center">
              <h2 class="card-title">
                {{ unit.gameName }}
              </h2>
              <p class="text-info" *ngIf="unit.games"><i class="fa fa-book"></i>&nbsp;{{ unit.games.length }} Games</p>
              <div class="progress mb-2" *ngIf="account && unitGameMap && unit.games">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  [ngStyle]="{ width: (unitGameMap.get(unit._id) * 100) / unit.games.length + '%' }"
                >
                  {{ (unitGameMap.get(unit._id) * 100) / unit.games.length | number: "1.0-0" }}%
                </div>
              </div>

              <p class="card-text">{{ unit.description }}</p>
            </div>
          </a>
          <div class="mt-auto mb-2">
            <a
              routerLink="/units/{{ unit._id }}/edit"
              class="btn btn-small btn-link col-6 m-2"
              *ngIf="account && account.role === Role.Admin"
              >Details</a
            >
            <a routerLink="/units/{{ unit._id }}/show" class="btn btn-small btn-link col-6 m-2">Learn & Compete</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="account && account.role === Role.Admin">
  <app-coursework-create></app-coursework-create>
</div>
