import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GamesModule } from "@app/games/games.module";
import { UnitDetailComponent } from "./unit-detail/unit-detail.component";
import { UnitShowComponent } from "./unit-show/unit-show.component";
import { UnitsComponent } from "./units.component";

const routes: Routes = [
  { path: "", component: UnitsComponent },
  { path: ":id/edit", component: UnitDetailComponent },
  { path: ":id/show", component: UnitShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UnitsRoutingModule {}
