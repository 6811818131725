import { Component, Input, OnInit } from "@angular/core";
import { Role } from "@app/_models/role";
import { Account } from "@app/_models/account";
import { AccountService } from "@app/_services/account.service";
import { Competition } from "./shared/competition.model";
import { CompetitionService } from "./shared/competition.service";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { CompetitionType } from "./shared/competitionType.model";

@Component({
  selector: "app-competitions",
  templateUrl: "./competitions.component.html",
  styleUrls: ["./competitions.component.css"],
})
export class CompetitionsComponent implements OnInit {
  constructor(private competitionService: CompetitionService, private accountService: AccountService, private route: ActivatedRoute) {}
  allCompetitions: Competition[];
  hoursOfCode: Competition[] = [];
  clubs: Competition[] = [];
  competitions: Competition[] = [];
  competitionType = CompetitionType;

  @Input() hostId: string = "";
  message: string;
  Role = Role;
  account: Account;

  ngOnInit(): void {
    this.accountService.account.subscribe((x) => (this.account = x));

    //Over-ride @Input()hostId if this value is passed through a query param. This is a way to provide hostId either as input or as queryParam
    if (this.route.snapshot.queryParams["hostId"]) this.hostId = this.route.snapshot.queryParams["hostId"];

    if (this.hostId) {
      this.getCompetitionsByHostId(this.hostId);
    } else {
      this.getCompetitions();
    }
  }

  getCompetitions(): void {
    this.competitionService.getCompetitions().subscribe((competitions) => {
      this.allCompetitions = competitions;

      this.classifyAndRemoveHiddenCompetitions(competitions);
    });
  }

  getCompetitionsByHostId(hostId: string): void {
    this.competitionService.getCompetitionsByHostId(hostId).subscribe((competitions) => {
      this.allCompetitions = competitions;
      this.classifyAndRemoveHiddenCompetitions(competitions);
    });
  }

  private classifyAndRemoveHiddenCompetitions(competitions: Competition[]) {
    for (const competition of competitions) {
      if (!competition.hidden || (this.account && this.account.role == Role.Admin)) {
        switch (competition.type) {
          case CompetitionType.HOUROFCODE:
            this.hoursOfCode.push(competition);
            break;

          case CompetitionType.CLUB:
            this.clubs.push(competition);
            break;

          case CompetitionType.COMPETITION:
            this.competitions.push(competition);
            break;

          default:
            break;
        }
      }
    }
  }

  update(competition: Competition): Observable<Competition> {
    return this.competitionService.updateCompetition(competition).pipe(
      tap((g: Competition) => this.log()),
      catchError(this.handleError<Competition>("update competition"))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log();

      this.message = error.message;
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log() {
    // RAVI - TODO: Implement messageService from heroes tutorial
    // this.messageService.add(`HeroService: ${message}`);
  }
}
