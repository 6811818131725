import { Component, EventEmitter, Input, OnInit, Output, ɵɵsetComponentScope } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EmailArray } from "@app/_helpers/email-array.validator";
import { AlertService } from "@app/_services/alert.service";

@Component({
  selector: "app-communications-winner",
  templateUrl: "./communications-winner.component.html",
  styleUrls: ["./communications-winner.component.css"],
})
export class CommunicationsWinnerComponent implements OnInit {
  title: string = "Winner Communication";
  message: string;

  form: FormGroup;
  submitted = false;

  // https://stackoverflow.com/questions/51478278/input-and-output-for-the-same-variable
  @Input() to: string[];
  @Output() toChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private formBuilder: FormBuilder, private alertService: AlertService) {}

  // Unused component at this time. Called from competition-show but does not do anything.
  // TODO - Re-factor it so that general commmunication can be sent through this component
  ngOnInit(): void {
    console.log(this.to);
    this.form = this.formBuilder.group({
      emailArray: [this.to, [Validators.required, EmailArray()]],
      message: [this.message, [Validators.required]],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    const emails = this.form.get("emailArray").value.split(",");
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    // this.loading = true;
    // this.form.get("email").setValue(this.form.get("email").value.toLocaleLowerCase());

    this.alertService.clear();
  }

  public onFormControlChange($event: Event, targetValue: string, formControlName: string): void {
    switch (formControlName) {
      case "to":
        this.toChange.emit(this.to);
        break;

      case "emailArray":
        break;

      default:
        break;
    }
  }

  public announceWinner(accountId: string = ""): void {}
}
