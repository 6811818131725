import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { AccountGame } from "@app/_models/account-game";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { tap, catchError, map } from "rxjs/operators";
import { GameData } from "@app/_models/gameData";

@Injectable({
  providedIn: "root",
})
export class AccountGameService {
  baseUrl = `${environment.apiUrl}/accountGames`;
  private accountGameSubject: BehaviorSubject<AccountGame>;
  public accountGame: Observable<AccountGame>;

  constructor(private http: HttpClient) {
    this.accountGameSubject = new BehaviorSubject<AccountGame>(null);
    this.accountGame = this.accountGameSubject.asObservable(); //published in getByAccountId and updateGameData
  }

  public get accountGameValue(): AccountGame {
    return this.accountGameSubject.value;
  }

  public resetAccountGameValue() {
    this.accountGameSubject.next(null);
  }

  getAccountGames(): Observable<AccountGame[]> {
    return this.http.get<AccountGame[]>(this.baseUrl).pipe(
      tap((_) => this.log("fetched accountGames")),
      catchError(this.handleError<AccountGame[]>("getAccountGames", []))
    );
  }

  getAccountGame(id: string): Observable<AccountGame> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<AccountGame>(url).pipe(
      tap((_) => this.log("fetched goal with id: `${id}`")),
      catchError(this.handleError<AccountGame>("getAccountGame"))
    );
  }

  getByAccountId(accountId: string): Observable<AccountGame> {
    const url = `${this.baseUrl}/account/${accountId}`;
    return this.http.get<AccountGame>(url).pipe(
      map((accountGame) => {
        this.accountGameSubject.next(accountGame);
        return accountGame;
      }),
      tap((_) => this.log("fetched game data for account with id: `${accountId}`")),
      catchError(this.handleError<AccountGame>("getAccountGame"))
    );
  }

  getByGameId(gameId: string): Observable<AccountGame[]> {
    const url = `${this.baseUrl}/game/${gameId}`;
    return this.http.get<AccountGame[]>(url).pipe(
      tap((_) => this.log("fetched game data for games with id: `${gameId}`")),
      catchError(this.handleError<AccountGame[]>("getAccountGame"))
    );
  }

  /** POST: add a new accountGame to the server */
  addAccountGame(accountGame: AccountGame): Observable<AccountGame> {
    const url = `${this.baseUrl}/create`;
    return this.http.post<AccountGame>(url, accountGame).pipe(
      tap((newAccountGame: AccountGame) => this.log(`added accountGame w/ id=${newAccountGame.id}`)),
      catchError(this.handleError<AccountGame>("addAccountGame"))
    );
  }

  /** DELETE: delete the accountGame from the server */
  deleteAccountGame(accountGame: AccountGame | string): Observable<AccountGame> {
    const id = typeof accountGame === "string" ? accountGame : accountGame.id;
    const url = `${this.baseUrl}/${id}`;

    return this.http.delete<AccountGame>(url).pipe(
      tap((_) => this.log(`deleted accountGame id=${id}`)),
      catchError(this.handleError<AccountGame>("deleteAccountGame"))
    );
  }

  updateGameData(accountId: string, gameData: GameData): Observable<AccountGame> {
    const url = `${this.baseUrl}/account/${accountId}/gameData`;
    this.log(url);
    return this.http.put<AccountGame>(url, gameData).pipe(
      map((accountGame) => {
        this.accountGameSubject.next(accountGame);
        return accountGame;
      }),
      tap((_) => this.log("updated accountGame")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  updateAccountGame(accountGame: AccountGame): Observable<AccountGame> {
    const id = typeof accountGame === "string" ? accountGame : accountGame.id;
    const url = `${this.baseUrl}/${id}`;
    this.log(url);
    return this.http.put<AccountGame>(url, accountGame).pipe(
      tap((_) => this.log("updated accountGame")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  removeGameFromAccountGame(accountGameId: string, gameId: string): Observable<AccountGame> {
    const url = `${this.baseUrl}/${accountGameId}/games/${gameId}`;

    return this.http.delete<AccountGame>(url).pipe(
      tap((_) => this.log(`removed game id=${gameId} from accountGame`)),
      catchError(this.handleError<AccountGame>("removeGameFromAccountGame"))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    // RAVI - TODO: Implement messageService from heroes tutorial
    // this.messageService.add(`HeroService: ${message}`);
  }
}
