import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { GamesModule } from "@app/games/games.module";

import { CompetitionsRoutingModule } from "./competitions-routing.module";
import { CompetitionDetailComponent } from "./competition-detail/competition-detail.component";
import { CompetitionsComponent } from "./competitions.component";
import { CompetitionCreateComponent } from "./competition-create/competition-create.component";
import { CompetitionShowComponent } from "./competition-show/competition-show.component";
import { FixtureDetailComponent } from "./fixture-detail/fixture-detail.component";
import { CompetitionSummaryComponent } from "./competition-summary/competition-summary.component";
import { CommunicationsModule } from "@app/communications/communications.module";

@NgModule({
  declarations: [
    CompetitionDetailComponent,
    CompetitionsComponent,
    CompetitionCreateComponent,
    CompetitionShowComponent,
    FixtureDetailComponent,
    CompetitionSummaryComponent,
  ],
  imports: [CommonModule, CompetitionsRoutingModule, FormsModule, ReactiveFormsModule, CommunicationsModule],
  exports: [CompetitionsComponent, CompetitionCreateComponent],
})
export class CompetitionsModule {}
