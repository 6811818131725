import { Component, OnInit } from '@angular/core';
import { GameInstanceService } from '../game-instance/game-instance.service';
import { AccountService } from '@app/_services';
import { GameInstance } from '../shared/game-instance.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-game-join',
  templateUrl: './game-join.component.html',
  styleUrls: ['./game-join.component.css'],
})
export class GameJoinComponent implements OnInit {
  gameCode: number;
  gameInstance: GameInstance;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  joinPlayer(gameCode: number) {
    this.router.navigate([`/games/lobby/gameCode/${gameCode}`]);
  }
}
