import { Component, OnInit, ViewChild, SimpleChanges, AfterViewInit, OnChanges, Input, EventEmitter, Output } from "@angular/core";
/* Begin Ace Editor imports */
import { Clipboard } from "@angular/cdk/clipboard";
import { CodeHelperLinks } from "@app/_helpers/code-helper-links.enum";

import "brace";
import "brace/mode/html";
import "brace/mode/javascript";
import "brace/mode/python";
import "brace/theme/twilight";
import "brace/ext/language_tools";
import "brace/snippets/html"; //If snippets stop working, bring this file locally or import it from brace directory
import "brace/snippets/javascript";
import "brace/snippets/json";
import "brace/snippets/python";
import "brace/ext/beautify";
// import "snippets";

//Ravi - TODO - emmet not working here, but can get to work it here - node_modules/ace-builds/editor.html
// have to import both - ext/emmet.js" and https://rawgithub.com/nightwing/emmet-core/master/emmet.js
// import "brace/ext/emmet";
// import "emmet";

import { AceComponent, AceDirective, AceConfigInterface } from "ngx-ace-wrapper";
import { Player } from "../shared/player.model";
import { BeautifyService } from "@app/_services/beautify.service";
import { PythonService } from "@app/_services/python.service";

@Component({
  selector: "app-game-editor",
  templateUrl: "./game-editor.component.html",
  styleUrls: ["./game-editor.component.css"],
})
export class GameEditorComponent implements OnInit, AfterViewInit, OnChanges {
  config: AceConfigInterface = {
    mode: "html",
    theme: "twilight",
    readOnly: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    fontFamily: "Inconsolata, Monaco, Consolas, 'Courier New', Courier;",
    fontSize: "12pt",
    tabSize: 4,
    showLineNumbers: false,
  };
  editorType: string = "directive";
  @Input() code: string;
  @Input() codeLanguage: string;
  @ViewChild(AceDirective) editor: AceDirective;
  userCode: string;
  @Output() userCodeChanged = new EventEmitter<string>();
  @Output() playerDone = new EventEmitter();
  @Output() runPython = new EventEmitter();
  CodeHelperLinks = CodeHelperLinks;
  editorTimer: any;

  constructor(private beautifyService: BeautifyService, private pythonService: PythonService, private clipboard: Clipboard) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    console.log("---Inside game-editor ngAfterViewInit---");

    //wait for editor to be created. Number 1000 is arbitrary at this point
    this.editorTimer = setInterval(() => {
      console.log("Inside editorTimer");
      if (this.editor) {
        // console.log(this.code);

        this.userCode = this.code; // === "" ? this.startingCode : this.code;
        // encodeURI is now used beefore sending the code to server from client, as otheerwise tabs were stripped out of python code
        this.editor.ace().setValue(decodeURI(this.userCode));

        if (this.codeLanguage) {
          this.editor.ace().setOptions({
            mode: "ace/mode/" + this.codeLanguage,
          });
        }
        clearInterval(this.editorTimer);
      } else {
        console.log("could not initialize editor's values, will be set to defaults");
      }
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy() {
    clearInterval(this.editorTimer);
  }
  /* Ace editor helper functions */
  public onValueChange(value: string): void {
    console.log("Value change:", value);
  }

  public onContentChange(event: any): void {
    if (this.editor) {
      this.emitUserCode();
      // console.log("userCode: " + this.userCode);
    }
  }

  public formatHTML() {
    this.beautifyService
      .beautifyHTML(this.editor.ace().getValue())
      .subscribe((beautifiedCode) => this.editor.ace().setValue(beautifiedCode["code"]));
  }

  public done() {
    this.playerDone.emit();
  }

  private emitUserCode() {
    this.userCode = this.editor.ace().getValue();
    // encodeURI is now used before sending the code to server from client, as otherwise tabs were stripped out of python code
    this.userCodeChanged.emit(encodeURI(this.userCode));
  }

  run() {
    this.runPython.emit();
  }
}
