<!-- <h4 class="mb-5 font-weight-bold text-center bg-info">Code Editor</h4> -->
<div class="col-sm-12 bg-dark text-right">
  <div class="btn btn-dark btn-small" *ngIf="codeLanguage == 'html'" [cdkCopyToClipboard]="CodeHelperLinks.bootstrap">
    Copy Bootstrap Link
  </div>
  <div class="btn btn-dark btn-small" *ngIf="codeLanguage == 'html'" [cdkCopyToClipboard]="CodeHelperLinks.jQuery">Copy jQuery Link</div>
  <div class="btn btn-dark btn-small" *ngIf="codeLanguage == 'html'" [cdkCopyToClipboard]="CodeHelperLinks.canvasHelper">
    Copy Canvas Helper Link
  </div>
  <div class="btn btn-dark btn-small" *ngIf="codeLanguage == 'html'" [cdkCopyToClipboard]="CodeHelperLinks.htmlWrapperCode">
    Copy HTML Wrapper Code
  </div>
  <div class="btn btn-dark btn-small" *ngIf="codeLanguage == 'javascript'" [cdkCopyToClipboard]="CodeHelperLinks.jsWrapperCode">
    Copy Javascript Wrapper Code
  </div>
  <div class="btn btn-success btn-small" *ngIf="codeLanguage == 'html'" (click)="formatHTML()">Beautify</div>
  <div class="btn btn-success btn-small" *ngIf="codeLanguage == 'python'" (click)="run()">Run</div>
  <div class="btn btn-danger btn-small" (click)="done()">Done</div>
</div>
<div class="col-sm-12 bg-dark">
  <div
    class="ace w-100"
    [ace]="config"
    (valueChange)="onValueChange($event)"
    (change)="onContentChange($event)"
    style="height: 1000px"
  ></div>
</div>
