import { Quest } from "../../quests/quest.model";
import { Unit } from "@app/units/shared/unit.model";
import { Account } from "@app/_models";
import { Competition } from "@app/competitions/shared/competition.model";
export class Game {
  id?: string;
  name: string;
  description?: string;
  gameSubscription?: string;
  createdBy?: string | Account;
  gameFolder?: string;
  gameFile?: string;
  quests?: (Quest | string)[];
  units?: (Unit | string)[];

  constructor(id?: string, name?: string, description?: string, createdBy?: Account, gameFolder?: String, gameFile?: String) {
    this.name = "New Game";
    this.description = "New game description";
    this.gameSubscription = "FREE";
    this.gameFolder = "";
    this.gameFile = "";
    this.units = [];
    this.quests = [];
  }
}
