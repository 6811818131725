import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { QuestsRoutingModule } from "./quests-routing.module";
import { QuestDetailComponent } from "./quest-detail/quest-detail.component";
import { AceModule } from "ngx-ace-wrapper";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CodeModule } from "@app/code/code.module";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { QuestShowComponent } from "./quest-show/quest-show.component";

@NgModule({
  declarations: [QuestDetailComponent, QuestShowComponent],
  imports: [CommonModule, QuestsRoutingModule, FormsModule, ReactiveFormsModule, AceModule, CodeModule, ClipboardModule],
  exports: [QuestDetailComponent, QuestShowComponent],
})
export class QuestsModule {}
