import { Component, OnInit, Input } from "@angular/core";
import { Account, Role, Game } from "@app/_models";
import { Unit } from "../shared/unit.model";
import { UnitService } from "../shared/unit.service";
import { AccountService, AlertService, GameService } from "@app/_services";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { ArrayService } from "@app/_services/array.service";
import { AlertComponent } from "@app/_components";

@Component({
  selector: "app-unit-detail",
  templateUrl: "./unit-detail.component.html",
  styleUrls: ["./unit-detail.component.css"],
})
export class UnitDetailComponent implements OnInit {
  account: Account;
  Role = Role;
  @Input() unit: Unit;
  @Input() unitId: string;
  showJSON: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private unitService: UnitService,
    private gameService: GameService,
    private location: Location,
    private accountService: AccountService,
    private arrayService: ArrayService,
    private alertService: AlertService
  ) {
    this.accountService.account.subscribe((x) => (this.account = x));
  }

  ngOnInit(): void {
    this.getUnit();
  }

  getUnit(): void {
    const id = this.unitId ? this.unitId : this.route.snapshot.paramMap.get("id");

    if (id) {
      this.unitService.getUnit(id).subscribe((unit) => {
        this.unit = unit;
      });
    }
  }

  addGameToUnit(game: Game): void {
    if (game) {
      game.units.push(this.unit.id);
      this.gameService.updateGame(game).subscribe((updatedGame) => {
        game = updatedGame;
        console.log(game);
      });

      this.unit.games.push(game.id);
      this.unitService.updateUnit(this.unit).subscribe((unit) => {
        this.unit = unit;
        console.log(this.unit);
      });
    } else {
      let newGame = new Game();
      newGame.units.push(this.unit.id);

      this.gameService.addGame(newGame).subscribe((game) => {
        // this.unit.games[this.unit.games.length - 1] = game.id;
        this.unit.games.push(game.id);
        this.unitService.updateUnit(this.unit).subscribe((unit) => {
          this.unit = unit;
          console.log(this.unit);
        });
      });
    }
  }

  removeGameFromUnit(i: number) {
    this.unitService.removeGameFromUnit(this.unit.id, this.unit.games[i].toString()).subscribe((unit) => (this.unit = unit));
    this.gameService.removeUnitFromGame(this.unit.games[i].toString(), this.unit.id).subscribe((game) => {});
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.unitService.updateUnit(this.unit).subscribe((unit) => {
      this.unit = unit;
      this.alertService.success("Update unit successful");
    });
  }

  onValueChange(event): void {
    this.unit = JSON.parse(event.target.value);
  }

  displayJSON(val: boolean) {
    this.showJSON = val;
  }

  moveUp(i: number) {
    this.unit.games = this.arrayService.moveUp(i, this.unit.games);
  }

  moveDown(i: number) {
    this.unit.games = this.arrayService.moveDown(i, this.unit.games);
  }
}
