import { Component, OnInit } from "@angular/core";
import { CourseService } from "./shared/course.service";
import { Course } from "./shared/course.model";
import { Role } from "@app/_models/role";
import { Account } from "@app/_models/account";
import { AccountService } from "@app/_services";
import { AccountGameService } from "@app/_services/account-game.service";
import { GameData } from "@app/_models/gameData";
import { Unit } from "@app/units/shared/unit.model";
import { Game } from "@app/games/shared/game.model";

@Component({
  selector: "app-courses",
  templateUrl: "./courses.component.html",
  styleUrls: ["./courses.component.css"],
})
export class CoursesComponent implements OnInit {
  constructor(
    private courseService: CourseService,
    private accountService: AccountService,
    private accountGameService: AccountGameService
  ) {}
  courses: Course[];
  Role = Role;
  account: Account;
  gameData: GameData[];
  unitGameMap = new Map();

  ngOnInit(): void {
    this.accountService.account.subscribe((x) => (this.account = x));

    this.getCourses();
  }

  getCourses(): void {
    this.courseService.getCourses().subscribe((courses) => {
      this.courses = courses.reverse(); //right now, the last course has most games.

      //Don't get units until you get gameData for each unit
      for (const index in this.courses) {
        this.getCourseWithUnits(this.courses[index].id, +index);
      }
    });
  }

  getCourseWithUnits(id: string, index: number): void {
    this.courseService.getCourseWithUnits(id).subscribe((course) => {
      this.courses[index] = course;
      this.fetchGameData(course);
    });
  }

  private fetchGameData(course: Course) {
    if (this.account) {
      this.accountGameService.accountGame.subscribe((x) => {
        if (x) {
          this.gameData = x.gameData;

          //after you get unit, create a map of how many games have been played from each unit
          for (const unit of course.units) {
            let unitId = (<any>unit)._id;
            if (!this.unitGameMap.get(unitId)) {
              this.unitGameMap.set(unitId, this.gameData.filter((v) => v.unit === unitId && v.bestScore > -1).length);
            }
          }
        }
      });
    }
  }
}
