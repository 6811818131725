import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AssignmentsComponent } from "../assignments/assignments.component";
import { UnitsComponent } from "../units/units.component";
import { CoursesComponent } from "./courses.component";
import { CourseDetailComponent } from "./course-detail/course-detail.component";
import { UnitsModule } from "@app/units/units.module";
import { CourseShowComponent } from "./course-show/course-show.component";

const routes: Routes = [
  { path: "", component: CoursesComponent },
  { path: ":id/edit", component: CourseDetailComponent },
  { path: ":id/show", component: CourseShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CourseworkRoutingModule {}
