<div class="container" *ngIf="competition">
  <section class="about-section mt-5" id="competitions">
    <div class="container">
      <div class="row align-items-center no-gutters mb-4 mb-lg-5">
        <div class="col-xl-12 col-lg-12">
          <div class="card-deck">
            <div class="card">
              <div class="card-header text-center display-4">
                {{ competition.name }}<br />
                <!-- <h2 class="text-info">
                  <span *ngFor="let item of fixtureCount | keyvalue; index as i">
                    <span *ngIf="i > 0">, </span>
                    {{ item.value }} {{ item.key }}
                    <span *ngIf="item.value == 1">Round</span>
                    <span *ngIf="item.value > 1">Rounds</span>
                  </span>
                </h2> -->

                <h2 class="text-info">Starts @ {{ competition.time | date: "EEEE, MMM d, y, h:mm a" }} PT</h2>
                <div class="text-center">
                  <!-- <a
                    class="btn btn-danger js-scroll-trigger mb-3"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeoq7JFvK2-0GQ07iXAsoQD0C39zS7DwqfksR8oBVdpY99y9A/viewform"
                    target="_blank"
                    >Register here</a
                  > -->
                  <div *ngIf="!alreadyRegistered()">
                    <div class="btn btn-danger text-center col-sm-12" (click)="registerPlayer()">Register Here <br /></div>
                    <div class="col-12 text-center mx-auto w-auto">
                      <input
                        type="text"
                        [(ngModel)]="referralCode"
                        class="text-center col-8 mx-auto my-auto vertical-align-middle border-solid border-primary"
                        style="max-width: 180px"
                        placeholder="Referral Code (Optional)"
                      />
                      <button
                        class="col-4 btn btn-primary border-primary border-solid border-width-3 mx-auto my-auto vertical-align-middle"
                        (click)="getDiscount()"
                      >
                        Apply
                      </button>
                    </div>

                    <div class="col-12 m-0 text-medium text-info" *ngIf="showDiscount">
                      <b> {{ this.discount }}% discount</b>
                    </div>
                    <!-- <div class="col-12 m-0">
                      <span class="text-small text-info" *ngIf="discount != -1">{{ this.discount }}% discount</span>
                    </div> -->
                  </div>

                  <div *ngIf="!competition.registrationRequired" class="text-medium text-info text-center col-sm-12">
                    [Registration is optional, but it will help you to see your score after the end of the competition]
                  </div>
                  <div *ngIf="alreadyRegistered()">
                    <div class="col-12">
                      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#inviteModal">Invite Friends</button>
                    </div>
                    <div
                      class="modal fade"
                      id="inviteModal"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="inviteModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content text-medium">
                          <div class="modal-header">
                            <h2 class="modal-title" id="inviteModalLabel">Invite Friends</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-12 mb-2">
                                <label>Friend's Emails: <br /></label>
                                (separate by commas)
                              </div>
                              <div class="col-12">
                                <input class="text-center" [(ngModel)]="friendEmails" name="friendEmails" />
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12 mb-2">
                                <label>Message:</label>
                              </div>
                              <div class="col-12">
                                <textarea style="resize: vertical" rows="8" [(ngModel)]="emailMessage" name="emailMessage"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer" *ngIf="account && account.id">
                            <button type="button" class="btn btn-light-gray" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="inviteFriends(account.id)">
                              Send message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12" *ngIf="!alreadyPaid()">
                      <div class="card card-body text-center text-medium bg-light-gray">
                        <h1 class="text-center text-danger">Payment Due:</h1>
                        <div class="btn-group" role="group">
                          <button type="button" class="btn btn-light text-wrap border-radius-10" *ngFor="let price of competition.price">
                            {{ price.duration }}<br />
                            <span class="text-extra-large"> ${{ price.value * (1 - discount / 100) }}</span>
                            <span class="text-small" *ngIf="discount"> <br />{{ discount }}% discount</span>
                            <span class="text-small text-lowercase text-black-50" *ngIf="price.comments != ''"
                              ><br />{{ price.comments }}</span
                            >
                          </button>
                        </div>
                        <div class="mt-2">
                          <p>Zelle @ 4802058913</p>
                          <p>Venmo @ Ravi-Shah-50</p>
                        </div>
                      </div>
                    </div>
                    <div class="btn btn-light-gray btn-small col-sm-12" (click)="unregisterPlayer(account.id)">Unregister</div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <h4 class="card-title text-center"></h4>
                <p class="card-text text-black-50 text-center text-large">
                  {{ competition.description }}
                </p>
                <ul class="list-unstyled list-group-flush text-center text-large">
                  <li class="text-black-50"><br /></li>
                  <li class="text-white" *ngIf="!alreadyRegistered()">
                    <div class="btn-group" role="group">
                      <button
                        type="button"
                        class="btn text-wrap border-radius-10"
                        [ngClass]="{ 'bg-primary': i > 0, 'bg-dark-gray': i == 0 }"
                        *ngFor="let price of competition.price; let i = index"
                      >
                        {{ price.duration }}<br /><span class="text-extra-large"> ${{ price.value * (1 - discount / 100) }}</span>
                        <span class="text-small" *ngIf="discount"> <br />{{ discount }}% discount</span>
                        <span class="text-small text-lowercase" *ngIf="price.comments != ''"
                          ><br /><i>{{ price.comments }}</i></span
                        >
                      </button>
                    </div>

                    <!-- <p class="text-center">
                      <button
                        class="btn btn-transparent-info dropdown-toggle dropdown-toggle-split text-primary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Fees : ${{ competition.price[0].value }}
                        
                      </button>
                    </p> 
                    
                    <div class="collapse col-sm-12" id="collapseExample">
                      <div class="card card-body text-center text-medium">
                        Zelle @ 4802058913 <br />
                        Venmo @ Ravi-Shah-50
                      </div>
                    </div>-->
                  </li>
                  <li class="text-black-50"><br /></li>
                  <li class="text-danger">Required: Laptop or Desktop for programming</li>
                </ul>

                <div
                  class="card col-lg-12 mb-5 ml-0 text-center text-dark text-large d-flex align-items-stretch"
                  *ngFor="let fixture of competition.fixtures; index as i"
                >
                  <div class="card-header text-center bg-white">
                    <h2>{{ i + 1 }}. {{ fixture.name }} ({{ fixture.stage }} Round)<br /></h2>
                    <h3>{{ fixture.description }} <br /></h3>

                    <h3 class="text-info">
                      <i class="far fa-calendar"></i>&nbsp;{{ fixture.fixtureTime | date: "EEEE, MMM d, y, h:mm a" }} PT
                    </h3>
                    <h3 class="text-info">
                      <i class="fas fa-wifi"></i>&nbsp;Location - <a href="{{ fixture.fixtureLocation }}">{{ fixture.fixtureLocation }}</a>
                    </h3>
                    <div class="btn btn-small btn-success mx-2" *ngIf="fixture.practiceGame" (click)="practiceGame(fixture.practiceGame)">
                      Practice
                    </div>
                    <div
                      class="btn btn-small btn-primary mx-2"
                      *ngIf="fixture.gameInstance && alreadyRegistered()"
                      (click)="joinGame(fixture.gameInstance.gameCode, false)"
                    >
                      Compete
                    </div>
                    <div
                      class="btn btn-small btn-primary mx-2"
                      *ngIf="fixture.gameInstance && !alreadyRegistered() && competition.registrationRequired"
                      (click)="registerPlayer()"
                    >
                      Register To Compete
                    </div>
                    <div
                      class="btn btn-small btn-primary mx-2"
                      *ngIf="fixture.gameInstance && !alreadyRegistered() && !competition.registrationRequired && account"
                      (click)="registerPlayer()"
                    >
                      Register & Compete
                    </div>
                    <div
                      class="btn btn-small btn-primary mx-2"
                      *ngIf="fixture.gameInstance && !alreadyRegistered() && !competition.registrationRequired && !account"
                      (click)="joinGame(fixture.gameInstance.gameCode, true)"
                    >
                      Compete as guest
                    </div>
                  </div>
                </div>
                <div class="text-center mb-3">
                  <div *ngIf="!alreadyRegistered()" class="btn btn-danger col-sm-12" (click)="registerPlayer()">Register Here</div>
                  <div *ngIf="!competition.registrationRequired" class="text-medium text-info text-center col-sm-12">
                    [Registration is optional, but it will help you to see your score after the end of the competition]
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h1 class="text-center">Leaderboard</h1>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Score</th>
                <th scope="col"><!-- Referral Code --></th>
                <th scope="col"><!-- Register / Unregister --></th>
                <th scope="col"><!-- Mark paid / unpaid --></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let player of competition.registeredPlayers; let i = index" class="vertical-align-middle">
                <td>{{ player.account.firstName }}</td>
                <td>{{ player.totalScore }}</td>
                <td>
                  <div *ngIf="account && account.id == competition.createdBy">{{ player.referralCode }}</div>
                </td>
                <td>
                  <div
                    *ngIf="(account && account.id == player.account.id) || (account && account.id == competition.createdBy)"
                    class="btn btn-danger btn-small col-sm-12"
                    (click)="unregisterPlayer(player.account.id)"
                  >
                    Unregister
                  </div>
                </td>
                <td>
                  <div
                    *ngIf="account && account.id == competition.createdBy && !alreadyPaid(player.account.id)"
                    class="btn btn-danger btn-small col-sm-12"
                    (click)="markPaymentStatus(player.account.id, PaymentStatus.PAID)"
                  >
                    Mark Paid
                  </div>
                  <div
                    *ngIf="account && account.id == competition.createdBy && alreadyPaid(player.account.id)"
                    class="btn btn-danger btn-small col-sm-12"
                    (click)="markPaymentStatus(player.account.id, PaymentStatus.UNPAID)"
                  >
                    Mark Unpaid
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div *ngIf="account && account.id == competition.createdBy" class="text-center">
      <button class="btn btn-info m-2" (click)="shareScores(['host'])">Share Scores - Sample Email for Host Only</button>

      <button class="btn btn-success m-2" [disabled]="sharing" (click)="shareScores(['player'])">
        <span *ngIf="sharing">Shared Scores with Players</span>
        <span *ngIf="!sharing">Share Scores with Players</span>
      </button>
    </div>
  </section>

  <section class="mt-5">
    <!-- Does not do anything right now. Re-factor it to do something meaningful, like sending general communication to all participants -->
    <div *ngIf="account && account.id == competition.createdBy" class="text-center">
      <app-communications-winner to="test@test.com"></app-communications-winner>
    </div>
  </section>
</div>
