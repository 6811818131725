import { Component, OnInit, Input } from "@angular/core";
import { Game } from "../shared/game.model";
import { GameService } from "../shared/game.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Quest } from "@app/quests/quest.model";
import { QuestService } from "@app/quests/quest.service";
import { AlertService, AccountService } from "@app/_services";
import { Account, Role } from "@app/_models";
import { ArrayService } from "@app/_services/array.service";

@Component({
  selector: "app-game-detail",
  templateUrl: "./game-detail.component.html",
  styleUrls: ["./game-detail.component.css"],
})
export class GameDetailComponent implements OnInit {
  account: Account;
  Role = Role;
  game: Game;
  @Input() gameId: string;
  quests: Quest[] = [];
  updatedGame: Game;
  showJSON: boolean = false;
  // newQuest: Quest;

  constructor(
    private route: ActivatedRoute,
    private gameService: GameService,
    private questService: QuestService,
    private alertService: AlertService,
    private location: Location,
    private accountService: AccountService,
    private arrayService: ArrayService
  ) {
    this.accountService.account.subscribe((x) => (this.account = x));
  }

  ngOnInit(): void {
    this.getGame();
  }

  getGame(): void {
    const id = this.gameId ? this.gameId : this.route.snapshot.paramMap.get("id");
    this.gameService.getGame(id).subscribe((game) => {
      this.game = game;
    });
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.game.createdBy = this.accountService.accountValue.id;
    this.gameService.updateGame(this.game).subscribe((game) => {
      this.game = game;
      this.alertService.success("Update game successful");
    });
  }

  onValueChange(event): void {
    this.game = JSON.parse(event.target.value);
  }

  displayJSON(val: boolean) {
    this.showJSON = val;
  }

  // addQuest() {
  //   let newQuest = new Quest();
  //   newQuest.games.push(this.game);
  //   this.questService.addQuest(newQuest).subscribe((quest) => {
  //     this.game.quests.push(quest.id); //[this.game.quests.length - 1] = quest.id;
  //     this.gameService.updateGame(this.game).subscribe((game) => (this.game = game));
  //   });
  // }

  addQuestToGame(quest: Quest): void {
    let newQuest;
    if (quest) {
      newQuest = quest;
    } else {
      newQuest = new Quest();
    }

    this.questService.addQuest(newQuest).subscribe((quest) => {
      this.game.quests.push(quest.id);
      this.gameService.updateGame(this.game).subscribe((game) => {
        this.game = game;
        console.log(this.game);
      });
    });
  }

  duplicateLastQuest(): void {
    console.log("Inside duplicate");
    this.questService.getQuest(this.game.quests[this.game.quests.length - 1].toString()).subscribe((quest) => {
      delete quest.id;
      this.addQuestToGame(quest);
    });
  }

  removeQuestFromGame(i: number) {
    this.gameService.removeQuestFromGame(this.game.id, this.game.quests[i].toString()).subscribe((game) => (this.game = game));
    this.questService.removeGameFromQuest(this.game.quests[i].toString(), this.game.id).subscribe((quest) => {});
  }

  // deleteQuest(i: number) {
  //   this.questService.deleteQuest(this.game.quests[i]).subscribe(() => this.alertService.success("Deleted quest successfully"));
  //   this.game.quests.splice(i, 1);

  //   this.gameService.updateGame(this.game).subscribe((game) => (this.game = game));
  // }

  moveUp(i: number) {
    // this.game.quests.splice(i - 1, 0, this.game.quests.splice(i, 1)[0].toString());
    this.game.quests = this.arrayService.moveUp(i, this.game.quests);
  }

  moveDown(i: number) {
    // this.game.quests.splice(i + 1, 0, this.game.quests.splice(i, 1)[0].toString());
    this.game.quests = this.arrayService.moveDown(i, this.game.quests);
  }
}
