import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable, of, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Unit } from "./unit.model";
import { tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UnitService {
  baseUrl = `${environment.apiUrl}/units`;

  constructor(private http: HttpClient) {}

  getUnits(): Observable<Unit[]> {
    return this.http.get<Unit[]>(this.baseUrl).pipe(
      tap((_) => this.log("fetched units")),
      catchError(this.handleError<Unit[]>("getUnits", []))
    );
  }

  getUnit(id: string): Observable<Unit> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Unit>(url).pipe(
      tap((_) => this.log("fetched goal with id: `${id}`")),
      catchError(this.handleError<Unit>("getUnit"))
    );
  }

  getUnitWithGames(id: string): Observable<Unit> {
    const url = `${this.baseUrl}/${id}/games`;
    return this.http.get<Unit>(url).pipe(
      tap((_) => this.log("fetched goal with id: `${id}`")),
      catchError(this.handleError<Unit>("getUnit"))
    );
  }

  /** POST: add a new unit to the server */
  addUnit(unit: Unit): Observable<Unit> {
    const url = `${this.baseUrl}/create`;
    return this.http.post<Unit>(url, unit).pipe(
      tap((newUnit: Unit) => this.log(`added unit w/ id=${newUnit.id}`)),
      catchError(this.handleError<Unit>("addUnit"))
    );
  }

  /** DELETE: delete the unit from the server */
  deleteUnit(unit: Unit | string): Observable<Unit> {
    const id = typeof unit === "string" ? unit : unit.id;
    const url = `${this.baseUrl}/${id}`;

    return this.http.delete<Unit>(url).pipe(
      tap((_) => this.log(`deleted unit id=${id}`)),
      catchError(this.handleError<Unit>("deleteUnit"))
    );
  }

  updateUnit(unit: Unit): Observable<Unit> {
    const id = typeof unit === "string" ? unit : unit.id;
    const url = `${this.baseUrl}/${id}`;
    this.log(url);
    return this.http.put<Unit>(url, unit).pipe(
      tap((_) => this.log("updated unit")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  removeGameFromUnit(unitId: string, gameId: string): Observable<Unit> {
    const url = `${this.baseUrl}/${unitId}/games/${gameId}`;

    return this.http.delete<Unit>(url).pipe(
      tap((_) => this.log(`removed game id=${gameId} from unit`)),
      catchError(this.handleError<Unit>("removeGameFromUnit"))
    );
  }

  removeCourseFromUnit(unitId: string, courseId: string): Observable<Unit> {
    const url = `${this.baseUrl}/${unitId}/courses/${courseId}`;

    return this.http.delete<Unit>(url).pipe(
      tap((_) => this.log(`removed course id=${courseId} from unit`)),
      catchError(this.handleError<Unit>("removeCourseFromUnit"))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    // RAVI - TODO: Implement messageService from heroes tutorial
    // this.messageService.add(`HeroService: ${message}`);
  }
}
