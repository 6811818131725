import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges, AfterViewInit, OnChanges } from "@angular/core";
import { AccountService } from "@app/_services";
import { FileSystemService } from "@app/_services/file-system.service";
import { PythonService } from "@app/_services/python.service";
import { Quest } from "../../quests/quest.model";

@Component({
  selector: "app-game-quest",
  templateUrl: "./game-quest.component.html",
  styleUrls: ["./game-quest.component.css"],
})
export class GameQuestComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(private accountService: AccountService, private fileSystemService: FileSystemService, private pythonService: PythonService) {}

  doc: any;
  @Input() quest: Quest;
  @ViewChild("questIframe") iframe: ElementRef;

  showPreview: boolean;
  previewContent: any = "";

  ngOnInit(): void {
    this.showPreview = true;
    if (this.quest.codeLanguage == "python" && this.quest.completeCode) {
      this.runPython(this.quest.completeCode);
    }
  }

  ngAfterViewInit() {
    this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    this.doc.open();
    this.doc.write(this.quest.completeCode);
    this.doc.close();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quest && this.iframe) {
      this.showPreview = true;

      this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      this.doc.open();
      this.doc.write(this.quest.completeCode);
      this.doc.close();
    }
  }

  nextQuest() {}

  showCode(code) {
    this.showPreview = !code;

    if (this.showPreview) {
      //wait for iFrame to be created. Number 100 is arbitrary at this point
      setTimeout(() => {
        this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
        this.doc.open();
        this.doc.write(this.quest.completeCode);
        this.doc.close();
      }, 100);
    }
  }

  runPython(code: string) {
    console.log("Inside runPython:");
    this.previewContent = { result: "Executing...", type: "info" };

    let completeCodeFileName = "temp-quest.py";
    this.fileSystemService.writeFile(code, completeCodeFileName).subscribe((res: any) => {
      console.log(res.message);
      this.pythonService.run(this.accountService.accountValue.urlName, completeCodeFileName).subscribe((pythonOutput: any) => {
        console.log("printing result");
        console.log(pythonOutput);

        this.previewContent = pythonOutput;
      });
    });
  }
}
