import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/_helpers/auth.guard";
import { CodeIdeComponent } from "./code-ide/code-ide.component";

const routes: Routes = [{ path: "", component: CodeIdeComponent, canActivate: [AuthGuard] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CodeRoutingModule {}
