import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { environment } from "@environments/environment";
import { Game } from "@app/_models";
import { MessageService } from "../../_services/message.service";
import { retryWithBackoff } from "@app/_helpers/retryWithBackOff";

@Injectable({
  providedIn: "root",
})
export class GameService {
  constructor(private http: HttpClient, private messageService: MessageService) {}

  baseUrl = `${environment.apiUrl}/games`;

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  addGame(game: Game): Observable<Game> {
    const url = this.baseUrl + "/create";

    return this.http.post<Game>(url, game).pipe(
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  getGames(): Observable<Game[]> {
    return this.http.get<Game[]>(`${this.baseUrl}`).pipe(
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  getGame(id: string): Observable<Game> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Game>(url).pipe(
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  getGameWithQuests(id: string): Observable<Game> {
    const url = `${this.baseUrl}/${id}/quests`;
    return this.http.get<Game>(url).pipe(
      retryWithBackoff(1000),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  updateGame(game: Game): Observable<Game> {
    const id = typeof game === "number" ? game : game.id;
    const url = `${this.baseUrl}/${id}`;
    this.log(url);
    return this.http.put<Game>(url, game).pipe(
      tap((_) => this.log("updated game")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  deleteGame(game: Game | number): Observable<any> {
    const id = typeof game === "number" ? game : game.id;
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  removeUnitFromGame(gameId: string, unitId: string): Observable<Game> {
    const url = `${this.baseUrl}/${gameId}/units/${unitId}`;

    return this.http.delete<Game>(url).pipe(
      tap((_) => this.log(`removed unit id=${unitId} from game`)),
      catchError(this.handleError<Game>("removeUnitFromGame"))
    );
  }

  removeQuestFromGame(gameId: string, questId: string): Observable<Game> {
    const url = `${this.baseUrl}/${gameId}/quests/${questId}`;

    return this.http.delete<Game>(url).pipe(
      tap((_) => this.log(`removed quest id=${questId} from game`)),
      catchError(this.handleError<Game>("removeQuestFromGame"))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a GameService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`GameService: ${message}`);
  }
}
