import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Game } from "@app/games/shared/game.model";
import { Unit } from "@app/units/shared/unit.model";
import { UnitService } from "@app/units/shared/unit.service";
import { Account } from "@app/_models/account";
import { GameData } from "@app/_models/gameData";
import { Role } from "@app/_models/role";
import { GameService } from "@app/_services";
import { AccountGameService } from "@app/_services/account-game.service";
import { AccountService } from "@app/_services/account.service";
import { Course, CoursePopulated } from "../shared/course.model";
import { CourseService } from "../shared/course.service";

@Component({
  selector: "app-course-show",
  templateUrl: "./course-show.component.html",
  styleUrls: ["./course-show.component.css"],
})
export class CourseShowComponent implements OnInit {
  constructor(
    private courseService: CourseService,
    private unitService: UnitService,
    private gameService: GameService,
    private accountService: AccountService,
    private accountGameService: AccountGameService,
    private route: ActivatedRoute
  ) {}
  account: Account;
  Role = Role;
  course: Course;
  units: Unit[] = [];
  gameData: GameData[];
  unitGameMap = new Map();
  gameScoreMap = new Map();

  ngOnInit(): void {
    this.accountService.account.subscribe((x) => (this.account = x));
    this.getCourseWithUnits();
  }

  getCourseWithUnits(): void {
    const id = this.route.snapshot.paramMap.get("id");
    this.courseService.getCourse(id).subscribe((course) => {
      this.course = course;

      this.units = <Unit[]>(<unknown>this.course.units);
      for (const index in this.course.units) {
        this.getUnitWithGames(<string>this.course.units[index], +index);
        this.fetchGameData(<string>this.course.units[index]);
      }
    });
  }

  getUnitWithGames(id: string, index: number): void {
    this.unitService.getUnitWithGames(id).subscribe((unit) => {
      this.units[index] = unit;
    });
  }

  delete(game: Game): void {
    this.gameService.deleteGame(game).subscribe(
      (response) => {
        console.log("response received" + response);
      },
      (error) => {
        //Error callback
        console.log("error caught in component" + error);
      }
    );
  }

  private fetchGameData(id: string) {
    if (this.account) {
      this.accountGameService.accountGame.subscribe((x) => {
        if (x) {
          this.gameData = x.gameData;
          let unitId = id;

          if (!this.unitGameMap.get(unitId)) {
            this.unitGameMap.set(unitId, this.gameData.filter((v) => v.unit === unitId && v.bestScore > -1).length);
          }

          for (const item of this.gameData) {
            this.gameScoreMap.set(item.game, item.bestScore);
          }
        }
      });
    }
  }
}
