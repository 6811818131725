import { Unit } from "@app/units/shared/unit.model";
import { Game } from "@app/games/shared/game.model";

export class Quest {
  id: string;
  name: string;
  description: string;
  instructions: string[];
  codeLanguage: string;
  startingCode: string;
  completeCode: string;
  codeHint: string;
  wrapperCode: string;
  image: string;
  timeLimit: number;
  files: { name: string; language: string; content: string }[];
  games?: (Game | string)[];
  units?: (Unit | string)[];

  constructor(
    id?: string,
    name?: string,
    description?: string,
    instructions?: string[],
    codeLanguage?: string,
    startingCode?: string,
    completeCode?: string,
    wrapperCode?: string,
    codeHint?: string,
    image?: string,
    timeLimit?: number,
    files?: { name: string; language: string; content: string }[],
    games?: (Game | string)[],
    units?: (Unit | string)[]
  ) {
    this.name = "Quest";
    this.description = "Description";
    this.codeLanguage = "html";
    this.timeLimit = 600;
    this.games = [];
    this.units = [];
  }
}
