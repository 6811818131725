import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Competition } from "./competition.model";
import { Fixture } from "./fixture.model";
import { Observable, of, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { AccountService } from "@app/_services";
import { Account } from "@app/_models";
import { MessageService } from "@app/_services/message.service";

@Injectable({
  providedIn: "root",
})
export class CompetitionService {
  baseUrl = `${environment.apiUrl}/competitions`;

  constructor(private http: HttpClient, private accountService: AccountService, private messageService: MessageService) {}

  getCompetitions(): Observable<Competition[]> {
    return this.http.get<Competition[]>(this.baseUrl).pipe(
      tap((_) => this.log("fetched competitions")),
      catchError(this.handleError<Competition[]>("getCompetitions", []))
    );
  }

  getCompetitionsByHostId(id: string): Observable<Competition[]> {
    const url = `${this.baseUrl}?hostId=${id}`;
    return this.http.get<Competition[]>(url).pipe(
      tap((_) => this.log("fetched competitions by host id")),
      catchError(this.handleError<Competition[]>("getCompetitionsByHostId", []))
    );
  }

  getCompetition(id: string): Observable<Competition> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Competition>(url).pipe(
      tap((_) => this.log("fetched competition with id: `${id}`")),
      catchError(this.handleError<Competition>("getCompetition"))
    );
  }

  getCompetitionWithFixtures(id: string): Observable<Competition> {
    const url = `${this.baseUrl}/${id}/fixtures`;
    return this.http.get<Competition>(url).pipe(
      tap((_) => this.log("fetched competition with id: `${id}`")),
      catchError(this.handleError<Competition>("getCompetition"))
    );
  }

  /** POST: add a new competition to the server */
  addCompetition(competition: Competition): Observable<Competition> {
    const url = `${this.baseUrl}/create`;
    return this.http.post<Competition>(url, competition).pipe(
      tap((newCompetition: Competition) => this.log(`added competition w/ id=${newCompetition.id}`)),
      catchError(this.handleError<Competition>("addCompetition"))
    );
  }

  /** DELETE: delete the competition from the server */
  deleteCompetition(competition: Competition | string): Observable<Competition> {
    const id = typeof competition === "string" ? competition : competition.id;
    const url = `${this.baseUrl}/${id}`;

    return this.http.delete<Competition>(url).pipe(
      tap((_) => this.log(`deleted competition id=${id}`)),
      catchError(this.handleError<Competition>("deleteCompetition"))
    );
  }

  updateCompetition(competition: Competition): Observable<Competition> {
    const id = typeof competition === "string" ? competition : competition.id;
    const url = `${this.baseUrl}/${id}`;

    if (competition.host && typeof competition.host !== "string") competition.host = competition.host.id;
    for (let player of competition.registeredPlayers) {
      if (player.account && typeof player.account !== "string") player.account = player.account.id;
    }
    for (let fixture of competition.fixtures) {
      if (typeof fixture !== "string") fixture = fixture.id;
    }
    if (competition.createdBy && typeof competition.createdBy !== "string") competition.createdBy = competition.createdBy.id;

    return this.http.put<Competition>(url, competition).pipe(
      tap((_) => this.log("updated competition")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  /** POST: add a new competition to the server */
  addFixture(competitionId: string, fixture: Fixture): Observable<Fixture> {
    const url = `${this.baseUrl}/${competitionId}/fixtures/create`;
    return this.http.post<Fixture>(url, fixture).pipe(
      tap((fixture: Fixture) => this.log(`added fixture w/ id=${fixture.id}`)),
      catchError(this.handleError<Fixture>("addCompetition"))
    );
  }

  removeFixture(competitionId: string, fixtureId: string): Observable<Fixture> {
    const url = `${this.baseUrl}/${competitionId}/fixtures/${fixtureId}`;

    return this.http.delete<Fixture>(url).pipe(
      tap((_) => this.log(`removed fixture id=${fixtureId} from competition`)),
      catchError(this.handleError<Fixture>("removeFixtureFromCompetition"))
    );
  }

  getDiscount(id: string, referralCode: string): Observable<any> {
    const url = `${this.baseUrl}/${id}/discount?referralCode=${referralCode}`;
    return this.http.get<any>(url).pipe(
      tap((_) => this.log("fetched discount for competition id: `${id}` and referralCode: `${referralCode}`")),
      catchError(this.handleError<any>("getDiscount"))
    );
  }

  registerPlayer(competition: Competition, referralCode?: string): Observable<any> {
    const url = `${this.baseUrl}/${competition.id}/players/`;
    return this.http.post<any>(url, { id: this.accountService.accountValue.id, referralCode: referralCode ? referralCode : "" }).pipe(
      // tap((player: Account) => this.log(`added player to competition w/ id=${player.id}`)),
      catchError(this.handleError<Account>("registerPlayer"))
    );
  }

  unregisterPlayer(competition: Competition, playerId: string): Observable<Competition> {
    const url = `${this.baseUrl}/${competition.id}/players/${playerId}`;

    return this.http.delete<Competition>(url).pipe(
      tap((_) => this.log(`unregistered player id=${playerId}`)),
      catchError(this.handleError<Competition>("unregisterPlayer"))
    );
  }

  updatePlayer(competition: Competition, playerId: string, player: any): Observable<any> {
    const url = `${this.baseUrl}/${competition.id}/players/${playerId}`;
    return this.http.put<any>(url, { player: player }).pipe(
      // tap((player: Account) => this.log(`added player to competition w/ id=${player.id}`)),
      catchError(this.handleError<Account>("registerPlayer"))
    );
  }

  updateScores(competitionId: string): Observable<any> {
    const url = `${this.baseUrl}/${competitionId}/updateScores`;
    return this.http.put<any>(url, {}).pipe(
      // tap((player: Account) => this.log(`added player to competition w/ id=${player.id}`)),
      catchError(this.handleError<Account>("updateScores"))
    );
  }

  invite(competitionId: string, playerId: string, emails: string[], message: string): Observable<Competition> {
    const url = `${this.baseUrl}/${competitionId}/invite`;
    return this.http.post<Competition>(url, { from: playerId, to: emails, message: message }).pipe(
      tap((newCompetition: Competition) => this.log(`invited to competition w/ id=${newCompetition.id}`)),
      catchError(this.handleError<Competition>("invite"))
    );
  }

  //valid options - ['host', 'player']
  shareScores(competitionId: string, options?: string[]): Observable<string> {
    const url = `${this.baseUrl}/${competitionId}/shareScores`;
    return this.http.post<string>(url, { to: options || [""] }).pipe(
      tap((_) => this.log("Shared results with all participants of the competition and their parents")),
      catchError(this.handleError<string>(`shared results of competition w/ id=${competitionId} & received error`))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    // RAVI - TODO: Implement messageService from heroes tutorial
    this.messageService.add(`Competition Service: ${message}`);
  }
}
