<!-- <div class="container-fluid no-gutters p-0 m-0" [style.background-image]="personalBg"> -->
<div class="container-fluid no-gutters p-0 m-0" [style.background-image]="personalBg">
  <div class="row d-flex justify-content-center">
    <div class="col-lg-5 text-center text-dark d-flex align-items-stretch m-3" *ngFor="let game of games">
      <div class="card col-12">
        <div class="row mt-2 no-gutters">
          <div
            *ngIf="game.gameSubscription == 'PREMIUM'"
            class="col-2 bg-danger text-white bold text-medium"
            style="width: 10%; position: relative; left: -20px"
          >
            $$
          </div>
          <div
            *ngIf="game.gameSubscription == 'FREE'"
            class="col-2 bg-success text-white bold text-medium"
            style="width: 10%; position: relative; left: -20px"
          >
            FREE
          </div>
          <div class="col-10"></div>
        </div>

        <!-- <img src="..." class="card-img-top" alt="..." /> -->
        <div class="card-body text-center">
          <h2 class="card-title">{{ game.name }}</h2>
          <p class="card-text">{{ game.description }}</p>
          <span
            *ngIf="
              game.gameSubscription == 'FREE' ||
                (account && game.gameSubscription == 'PREMIUM' && account.accountSubscription == 'PREMIUM');
              else dontAllowHosting
            "
          >
            <div class="btn-group col-3 m-2">
              <button
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Host
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" routerLink="/games/lobby/game/{{ game.id }}/" [queryParams]="{ peerRatingAllowed: false }"
                  >Host & Don't Allow Peer Rating</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/games/lobby/game/{{ game.id }}/" [queryParams]="{ peerRatingAllowed: true }"
                  >Host & Allow Peer Rating</a
                >
              </div>
            </div>
          </span>
          <ng-template #dontAllowHosting>
            <span class="btn btn-black col-3 m-2">Host</span>
          </ng-template>
          <span>
            <a [routerLink]="['.', game.id, 'show']" class="btn btn-primary col-3 m-2">Learn more</a>
          </span>
          <span *ngIf="(account && account.role === Role.Admin) || (account && game.createdBy.toString() == account.id.toString())">
            <a [routerLink]="['.', game.id, 'edit']" class="btn btn-primary col-3 m-2">Details</a>
          </span>
          <span
            *ngIf="(account && account.role === Role.Admin) || (account && game.createdBy.toString() == account.id.toString())"
            class="btn btn-danger col-3 m-2"
            (click)="delete(game)"
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12" *ngIf="this.message">{{ this.message }}</div>
  </div>
</div>

<div *ngIf="account && account.role === Role.Admin">
  <app-game-create></app-game-create>
</div>
