<div class="container">
  <div class="row align-items-center mt-5">
    <div class="col-sm-4"></div>
    <div class="btn btn-danger col-sm-4" (click)="add()">Create Game</div>
    <div class="col-sm-4"></div>
  </div>
  <div class="row align-items-center mb-5">
    <div class="col-sm-4"></div>
    <div class="text-danger text-center col-sm-4">Use with caution. Ideally, create game only through units.</div>
    <div class="col-sm-4"></div>
  </div>
</div>
