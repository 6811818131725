<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top" id="mainNav">
  <div class="container">
    <a class="navbar-brand js-scroll-trigger" href="/">Children's Coding Club</a
    ><button
      class="navbar-toggler navbar-toggler-right"
      type="button"
      data-toggle="collapse"
      data-target="#navbarResponsive"
      aria-controls="navbarResponsive"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <!-- <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/teacher-home"
            routerLinkActive="active"
            class="nav-item nav-link"
            >Teachers</a
          >
        </li> -->
        <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/winter-of-code"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >Winter of Code</a
          >
        </li>

        <li class="nav-item" *ngIf="account && account.role === Role.Admin">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/pnc"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >Python National Championship</a
          >
        </li>

        <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/competitions"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >Clubs & Clashes of Coders</a
          >
        </li>
        <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/coursework"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >Courses</a
          >
        </li>
        <li class="nav-item" *ngIf="account">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/code"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >Code Editor</a
          >
        </li>
        <li class="nav-item" *ngIf="account && account.role === Role.Admin">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/games"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >Games</a
          >
        </li>

        <li class="nav-item">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/games/join"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >Join Game</a
          >
        </li>
        <li *ngIf="account">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/account-settings"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >{{ account.firstName }}'s Profile</a
          >
        </li>

        <li *ngIf="account">
          <a
            *ngIf="account.role === Role.Admin"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="/admin"
            routerLinkActive="active"
            class="nav-item nav-link js-scroll-trigger"
            >Admin</a
          >
        </li>

        <li *ngIf="account">
          <a data-toggle="collapse" data-target=".navbar-collapse.show" (click)="logout()" class="nav-item nav-link js-scroll-trigger"
            >Logout</a
          >
        </li>
        <li *ngIf="!account">
          <a
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            routerLink="'/account/login'"
            routerLinkActive="active"
            (click)="login()"
            class="nav-item nav-link js-scroll-trigger"
            >Login</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<section [ngClass]="{ 'bg-light': account }">
  <!-- <div class="container" > -->
  <!-- subnav router outlet -->
  <router-outlet name="subnav"></router-outlet>

  <!-- global alert -->
  <alert></alert>

  <!-- main router outlet -->
  <router-outlet></router-outlet>

  <!-- <app-messages></app-messages> -->
  <!-- </div> -->
</section>
