import { Component, OnInit, AfterViewInit, OnChanges, Input, ViewChild, ElementRef, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-code-preview",
  templateUrl: "./code-preview.component.html",
  styleUrls: ["./code-preview.component.css"],
})
export class CodePreviewComponent implements OnInit, AfterViewInit, OnChanges {
  doc: any;
  @Input() code: string;
  @Input() codeLanguage: string;
  @Input() wrapperCode: string;
  previewTimer: any;

  @ViewChild("previewIframe") iframe: ElementRef;

  constructor() {}

  ngOnInit(): void {
    // console.log("---Inside code-preview ngOnInit---");
  }

  ngAfterViewInit() {
    // console.log("---Inside code-preview ngAfterViewInit---");
    //no need to update preview for python
    if (this.codeLanguage != "python") {
      //try updatePreviewWrapper one time so as to avoid any anyis not successful,
      //if not successful, then try again after some duration, and clear the timer when successful.
      // if (!this.updatePreviewWrapper()) {
      this.previewTimer = setInterval(() => {
        console.log("updating preview after delay");
        this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
        if (this.doc) {
          this.updatePreview();

          clearInterval(this.previewTimer);
        }
      }, 100);
      // }
    }
  }

  ngOnDestroy() {
    if (this.codeLanguage != "python") clearInterval(this.previewTimer);
  }

  private updatePreviewWrapper(): boolean {
    this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    if (this.doc) {
      this.updatePreview();
      return true;
    }
    return false;
  }

  private updatePreview() {
    this.doc.open();

    if (this.wrapperCode && this.wrapperCode != "undefined") {
      this.doc.write(this.wrapperCode);
    }
    this.insertQuestCode();
    this.doc.close();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("---Inside code-preview ngOnChanges---", changes, this.code);
    this.code = decodeURI(this.code);
    this.wrapperCode = decodeURI(this.wrapperCode);

    if (changes.code && this.iframe) {
      // console.log("---Updating preview inside code-preview ngOnChanges---");
      this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      this.doc.open();
      if (this.wrapperCode && this.wrapperCode != "undefined") {
        this.doc.write(this.wrapperCode);
      }

      this.insertQuestCode();
      this.doc.close();
    }
  }

  private insertQuestCode() {
    this.doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;

    if (this.codeLanguage == "python" || this.wrapperCode == "undefined" || this.wrapperCode == "") {
      this.doc.write(this.code);
    }
    if (this.codeLanguage == "javascript" || this.codeLanguage == "html") {
      var intervalID = setInterval(() => {
        console.log("Inside quest-code timer");
        let questCodeElement = this.doc.getElementById("quest-code");

        if (questCodeElement) {
          if (this.codeLanguage == "javascript") {
            this.insertJSCode(questCodeElement);
          }
          if (this.codeLanguage == "html") {
            questCodeElement.innerHTML = this.code;
          }
          console.log("Clearing quest-code timer");
          window.clearInterval(intervalID);
        }
      }, 100);
    }

    // let questCodeElement = this.doc.getElementById("quest-code");
    // if (!questCodeElement) {
    //   //write complete document as if it is well formed HTML
    //   // console.log("quest-code element NOT found");
    //   this.doc.write(this.code);
    // } else {
    //   //insert quest code inside a particular element
    //   // console.log("quest-code element found");
    //   if (this.codeLanguage == "javascript") {
    //     this.insertJSCode(questCodeElement);
    //   } else {
    //     //default is HTML code type
    //     questCodeElement.innerHTML = this.code;
    //   }
    // }
  }

  private insertJSCode(questCodeElement: any) {
    var s = this.doc.createElement("script");
    s.type = "text/javascript";
    try {
      s.appendChild(this.doc.createTextNode(this.code));
      questCodeElement.appendChild(s);
      // console.log("Successfully added script tag");
    } catch (e) {
      // console.log("Caught exception while adding script tag");
      s.text = this.code;
      questCodeElement.appendChild(s);
    }
  }
}
