import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UnitsRoutingModule } from "./units-routing.module";
import { UnitDetailComponent } from "./unit-detail/unit-detail.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { GamesModule } from "@app/games/games.module";
import { UnitShowComponent } from "./unit-show/unit-show.component";

@NgModule({
  declarations: [UnitDetailComponent, UnitShowComponent],
  imports: [CommonModule, UnitsRoutingModule, FormsModule, ReactiveFormsModule, GamesModule],
  exports: [UnitDetailComponent, UnitShowComponent],
})
export class UnitsModule {}
