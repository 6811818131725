import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuestDetailComponent } from "./quest-detail/quest-detail.component";
import { QuestShowComponent } from "./quest-show/quest-show.component";

const routes: Routes = [
  { path: ":id/edit", component: QuestDetailComponent },
  { path: ":id/show", component: QuestShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestsRoutingModule {}
