import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "@app/_services";
import { Course } from "../shared/course.model";
import { CourseService } from "../shared/course.service";

@Component({
  selector: "app-coursework-create",
  templateUrl: "./coursework-create.component.html",
  styleUrls: ["./coursework-create.component.css"],
})
export class CourseworkCreateComponent implements OnInit {
  constructor(private courseService: CourseService, private accountService: AccountService, private router: Router) {}

  message: String;

  ngOnInit(): void {}

  add(): void {
    let newCourse = new Course();
    this.courseService.addCourse(newCourse).subscribe((course) => {
      this.message = "Course created successfully";
      this.router.navigate([`/coursework/${course.id}/edit`]);
    });
  }
}
