<section class="text-left">
  <div class="container">
    <div class="row mt-3">
      <div class="col-12">
        <div class="text-medium text-bold">
          <button class="btn btn-link"><a [routerLink]="['/coursework']" routerLinkActive="router-link-active">All Courses</a></button>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container overflow-auto" *ngIf="course">
  <div class="row mt-5 text-center">
    <div class="col-sm-12">
      <h2 class="display-4">{{ course.name }}</h2>
      <h4>{{ course.description }}</h4>
    </div>
  </div>

  <div class="mb-5 text-center text-dark" *ngFor="let unit of units">
    <div class="row d-flex align-items-stretch no-gutters">
      <div class="card col-lg-3 rounded-0 bg-light">
        <!-- <img src="..." class="card-img-top" alt="..." /> -->

        <div class="card-body text-center">
          <h2 class="card-title">
            {{ unit.gameName }}
          </h2>

          <div class="progress mb-2" *ngIf="account && unitGameMap && unit.games">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              [ngStyle]="{ width: (unitGameMap.get(unit.id) * 100) / unit.games.length + '%' }"
            >
              {{ (unitGameMap.get(unit.id) * 100) / unit.games.length | number: "1.0-0" }}%
            </div>
          </div>

          <p class="card-text">{{ unit.description }}</p>
        </div>
        <div class="mt-auto">
          <a routerLink="/units/{{ unit.id }}/show" class="btn btn-small btn-primary col-12 m-2">More Games </a>
        </div>
        <div class="mt-auto mb-2">
          <a routerLink="/units/{{ unit.id }}/edit" class="btn btn-small btn-link col-6 m-2" *ngIf="account && account.role === Role.Admin"
            >Edit</a
          >
        </div>
      </div>
      <div class="card col-lg-3 align-items-stretch rounded-0" *ngFor="let game of unit.games | slice: 0:3">
        <!-- <img src="..." class="card-img-top" alt="..." /> -->
        <a routerLink="/games/{{ game._id }}/show">
          <div class="card-body text-center">
            <h2 class="card-title">
              {{ game.name }}
            </h2>
            <p class="card-text">{{ game.description }}</p>
          </div>
        </a>
        <div class="mt-auto mb-2">
          <!-- <span
            *ngIf="(account && account.role === Role.Admin) || (account && game.createdBy.toString() == account.id.toString())"
            class="btn btn-small btn-link col-3 m-2"
            (click)="delete(game)"
          >
            Delete
          </span> -->
          <!-- <a routerLink="/games/{{ game._id }}/show" class="btn btn-small btn-danger col-6 m-2">Play now</a> -->
          <span
            *ngIf="
              game.gameSubscription == 'FREE' ||
                (account && game.gameSubscription == 'PREMIUM' && account.accountSubscription == 'PREMIUM');
              else dontAllowHosting
            "
          >
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-small btn-danger dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Play Now
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" routerLink="/games/lobby/game/{{ game._id }}/" [queryParams]="{ peerRatingAllowed: false }"
                  >Play Alone</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/games/lobby/game/{{ game._id }}/" [queryParams]="{ peerRatingAllowed: false }"
                  >Play with friends & only you rate others</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" routerLink="/games/lobby/game/{{ game._id }}/" [queryParams]="{ peerRatingAllowed: true }"
                  >Play with friends & rate each other</a
                >
              </div>
            </div>
          </span>
          <div [ngClass]="gameScoreMap.get(game._id) > -1 ? 'btn btn-large text-info' : 'btn btn-large text-danger'" *ngIf="account">
            {{ gameScoreMap.get(game._id) > -1 ? "Your Best Score: " + gameScoreMap.get(game._id) : "Not Played" }}
          </div>
          <ng-template #dontAllowHosting>
            <span class="btn btn-small btn-black">Play</span>
          </ng-template>
          <br />
          <a routerLink="/games/{{ game._id }}/edit" class="btn btn-small btn-link col-6 m-2" *ngIf="account && account.role === Role.Admin"
            >Edit</a
          >
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row mt-5">
    <div class="col-sm-12">
      <div *ngFor="let unit of units; let i = index">
        <div id="accordion_course">
          <div class="card">
            <div class="card-header" id="heading_unit_{{ i }}">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse_unit_' + i"
                      aria-expanded="true"
                      aria-controls="collapse_unit_{{ i }}"
                    >
                      Unit #{{ i + 1 }}: {{ unit.gameName }}
                    </button>
                  </h5>
                </div>
              </div>
            </div>

            <div id="collapse_unit_{{ i }}" class="collapse" aria-labelledby="heading_unit_{{ i }}" data-parent="#accordion_course">
              <div class="card-body">
                <app-unit-show [unitId]="unit.id"></app-unit-show>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
