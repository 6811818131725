<div class="container">
  <div class="row align-items-center mt-5">
    <div class="col-sm-4"></div>
    <div class="col-sm-4 text-center"><h2>Game Code</h2></div>
    <div class="col-sm-4"></div>
  </div>
  <div class="row align-items-center">
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
      <input #gameCode class="form-control text-center" />
    </div>
    <div class="col-sm-4"></div>
  </div>
  <div class="row align-items-center mt-5 mb-5 text-center">
    <div class="col-sm-4"></div>
    <div class="col-sm-4"><div class="btn btn-danger" (click)="joinPlayer(gameCode.value); gameCode.value = ''">Join</div></div>

    <div class="col-sm-4"></div>
  </div>
</div>
