<div class="row">
  <div class="col-sm-12">
    <h1 class="text-center">Leaderboard</h1>
  </div>
</div>

<div class="row text-center">
  <div class="col-3"></div>
  <div class="col-6">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Score</th>
          <!-- <th scope="col">Code Link</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let p of playerTotalScore; let i = index" class="vertical-align-middle">
          <td>{{ p.name }}</td>
          <td>{{ p.score }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-3"></div>
</div>

<div *ngIf="accountService.isLoggedIn()">
  <div class="row text-center">
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
      <div class="btn btn-primary" (click)="sendMesssageForNextQuest()" *ngIf="amIHost() && !isLastQuestDone()">
        {{ hostNextStep }}
      </div>

      <div *ngIf="!amIHost()">
        <h1>
          <span class="badge badge-pill badge-important"> {{ playerNextStep }} </span>
        </h1>
      </div>
    </div>

    <div class="col-sm-4"></div>
  </div>
  <!-- <div class="row text-center m-3">
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
      <a href="{{ personalGameUrl }}" class="btn btn-light btn-small">Your URL</a>
    </div>

    <div class="col-sm-4"></div>
  </div> -->
  <div class="row mt-5">
    <div class="col-sm-4"></div>
    <div class="col-sm-4 text-center" *ngIf="isLastQuestDone()">
      <a routerLink="/coursework" class="btn btn-primary m-2">Game Over</a>
      <a routerLink="/games/{{ game.id }}/show" class="btn btn-danger">All Time Leaderboard</a>
    </div>
    <div class="col-sm-4"></div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-4"></div>
    <div *ngIf="amIHost()" class="col-sm-4 btn btn-small btn-light text-center">
      <a target="_blank" [routerLink]="['/games/gameInstance/controlPanel/' + gameInstance.gameCode]" routerLinkActive="router-link-active"
        >Game Control Panel</a
      >
    </div>
    <div class="col-sm-4"></div>
  </div>
</div>

<app-game-footer [(gameInstance)]="gameInstance"></app-game-footer>
