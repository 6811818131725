import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { GameInstanceService } from "../game-instance/game-instance.service";
import { GameInstance } from "../shared/game-instance.model";
import { AccountService, AlertService, GameService } from "@app/_services";
import { Player } from "../shared/player.model";
import { Observable, interval, Subscription } from "rxjs";
import { Game } from "../shared/game.model";
import { ActivatedRoute, Router } from "@angular/router";
import { GameInstanceStatus } from "../shared/game-instance-status.model";

@Component({
  selector: "app-game-leaderboard",
  templateUrl: "./game-leaderboard.component.html",
  styleUrls: ["./game-leaderboard.component.css"],
})
export class GameLeaderboardComponent implements OnInit {
  currentPlayerIndex: number;
  gameInstance: GameInstance;
  game: Game;
  players: Player[];
  gameCode: number;
  canDeactivateSynchronously: boolean = false;

  playerSpeedScore: number[] = [];
  playerRatingsReceivedScore: number[] = [];
  playerRatingsGivenScore: number[] = [];
  playerTotalScore: { name: string; score: number }[] = [];

  hostNextStep: string;
  playerNextStep: string;
  playMessage: Subscription;
  leaveMessage: Subscription;
  joinMessage: Subscription;

  personalGameUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public accountService: AccountService, //made it public to make it available to html
    private gameInstanceService: GameInstanceService,
    private gameService: GameService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.gameCode = +this.route.snapshot.paramMap.get("gameCode");
    this.gameInstanceService.getGameInstanceByGameCode(this.gameCode).subscribe((gameInstance) => {
      this.gameInstance = gameInstance;

      this.gameService.getGame(this.gameInstance.game.toString()).subscribe((game) => {
        this.game = game;

        if (this.accountService.accountValue) {
          this.personalGameUrl = this.accountService.accountUrl + "/" + game.gameFile;
        }

        if (this.isLastQuestDone()) {
          this.hostNextStep = "Game Over";
          this.playerNextStep = "Game Over";
          if (this.amIHost()) {
            this.gameInstanceService
              .endGame(this.gameInstance)
              .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));
          }
        } else {
          this.hostNextStep = "Next Quest";
          this.playerNextStep = "Waiting for host to start next quest ...";
        }
      });

      //Subsribe to messages about new players
      this.joinMessage = this.gameInstanceService.getJoinMessage().subscribe((messageObj: any) => {
        console.log(
          "Join message (leaderboard) received message: " + messageObj.accountId + " currentQuestIndex: " + messageObj.currentQuestIndex
        );
        //New player joined, get updated list of players
        // this.gameInstanceService.getGameInstanceByGameCode(this.gameInstance.gameCode).subscribe((gameInstance) => {
        //   if (!gameInstance) {
        //     console.log("Empty gameInstance received");
        //   }
        //   this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
        // });
        this.gameInstance = messageObj.gameInstance;
      });

      this.playMessage = this.gameInstanceService.getPlayMessage().subscribe((messageObj: any) => {
        console.log("getPlayMessage received message: currentQuestIndex: " + messageObj.currentQuestIndex);
        this.canDeactivateSynchronously = true;
        this.router.navigate([`/games/gamePlay/${this.gameInstance.gameCode}`]);
      });

      //Subsribe to messages about player leaving
      this.leaveMessage = this.gameInstanceService.getLeaveMessage().subscribe((messageObj: any) => {
        console.log("getLeaveMessage received message: " + messageObj.accountId + " currentQuestIndex: " + messageObj.currentQuestIndex);
        //Player left, get updated list of players
        this.gameInstance = messageObj.gameInstance ? messageObj.gameInstance : this.gameInstance;
      });

      this.players = this.gameInstance.players;
      for (let playerIndex = 0; playerIndex < this.players.length; playerIndex++) {
        this.playerSpeedScore[playerIndex] = 0;
        this.playerRatingsReceivedScore[playerIndex] = 0;
      }
      this.totalScoreForAllPlayers();
    });
  }

  ngOnDestroy() {
    this.playMessage.unsubscribe();
    this.leaveMessage.unsubscribe();
    this.joinMessage.unsubscribe();
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if game has finished
    if (this.gameInstance.status == GameInstanceStatus.FINISHED || this.canDeactivateSynchronously) {
      if (this.gameInstance.status == GameInstanceStatus.FINISHED) this.gameInstanceService.leaveGame(this.gameInstance);
      return true;
    }

    const confirmDialog$ = this.alertService.confirmDialog("Have you completed the game? If not, do you really want to quit the game?");
    confirmDialog$.subscribe((dialogReturn) => {
      console.log("return value: " + dialogReturn);
      if (dialogReturn) {
        this.gameInstanceService.leaveGame(this.gameInstance);
      }
    });
    // Otherwise ask the user with the dialog service and return its observable which resolves to true or false when the user decides
    return confirmDialog$;
  }

  private findCurrentPlayerIndex() {
    for (let i = 0; i < this.gameInstance.players.length; i++) {
      if (this.gameInstance.players[i].account == this.accountService.accountValue.id) {
        this.currentPlayerIndex = i;
      }
    }
  }

  public amIHost(): boolean {
    if (
      this.accountService.accountValue &&
      this.gameInstance &&
      this.gameInstance.hostAccount.toString() == this.accountService.accountValue.id.toString()
    ) {
      return true;
    }
    return false;
  }

  public isLastQuestDone() {
    return this.game && this.gameInstance && this.gameInstance.currentQuestIndex >= this.game.quests.length - 1;
  }

  public totalScoreForAllPlayers() {
    for (let playerIndex = 0; playerIndex < this.players.length; playerIndex++) {
      let speedScore = this.calculateSpeedScore(this.players, playerIndex);
      //if host is not playing, do not add their scores to the score list, thus avoid their score from showing up on leaderboard
      if (this.gameInstance.hostPlaying || this.players[playerIndex].account.toString() != this.gameInstance.hostAccount.toString()) {
        this.playerTotalScore.push({
          score: speedScore, //speedScore now takes into account accuracy as measured with ratingsReceivedScore (calculated on server side)
          name: this.players[playerIndex].name,
        });
      }
    }
    this.playerTotalScore.sort((a, b) => b.score - a.score);
  }

  private calculateSpeedScore(players: Player[], playerIndex: number) {
    console.log("Inside calculateSpeedScore");

    const currentPlayer = players[playerIndex];
    const score = currentPlayer.score;

    let total = 0;
    for (let q = 0; q < score.length; q++) {
      total += score[q];
    }

    this.playerSpeedScore[playerIndex] = total; //store for display

    return total;
  }

  public sendMesssageForNextQuest() {
    this.gameInstanceService.nextQuest(this.gameInstance).subscribe((gameInstance) => {
      this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
    });
  }
}
