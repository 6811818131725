import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountService } from "@app/_services";
import { ArrayService } from "@app/_services/array.service";
import { GameInstanceService } from "../game-instance/game-instance.service";
import { GameInstance } from "../shared/game-instance.model";

@Component({
  selector: "app-game-footer",
  templateUrl: "./game-footer.component.html",
  styleUrls: ["./game-footer.component.css"],
})
export class GameFooterComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() gameInstance: GameInstance;
  @Output() gameInstanceChange: EventEmitter<GameInstance> = new EventEmitter<GameInstance>();

  constructor(
    private gameInstanceService: GameInstanceService,
    private accountService: AccountService,
    private arrayService: ArrayService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log("Inside app-game-footer ---- ngOnInit ---- ");

    //Join the gameroom socket on page refresh
    // const obj = {
    //   gameInstance: {
    //     gameCode: +this.route.snapshot.paramMap.get("gameCode"),
    //   },
    //   accountId: this.accountService.accountValue.id,
    //   nickName: this.accountService.accountValue.nickName,
    // };
    // console.log("Joining game room again");
    // this.gameInstanceService.sendGameMesssage("join", obj);
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Inside app-game-footer ---- ngOnChanges ---- ");
    //Join the active game and its gameroom socket any time you receive a message that you have been de-activated, e.g. on page refresh or socket reconnection
    let currentPlayerIndex = -1;

    if (this.gameInstance && this.accountService.accountValue) {
      for (let i = 0; i < this.gameInstance.players.length; i++) {
        if (this.gameInstance.players[i].account == this.accountService.accountValue.id) {
          currentPlayerIndex = i;
          break;
        }
      }
      if (currentPlayerIndex != -1 && this.gameInstance.players[currentPlayerIndex].playerStatus == "INACTIVE") {
        let localGameInstance = {
          gameCode: this.gameInstance.gameCode,
          players: [
            {
              account: this.accountService.accountValue.id,
              name: this.accountService.accountValue.nickName,
            },
          ],
        };
        console.log("Adding player again");

        this.gameInstanceService.addPlayer(localGameInstance).subscribe((gameInstance) => {
          this.gameInstance = gameInstance;
          this.gameInstanceChange.emit(gameInstance);

          if (!this.arrayService.exists(this.gameInstance.gameCode.toString(), this.gameInstanceService.getSocketGameRooms())) {
            //Join the gameroom socket, Let other player know that you have joined
            const obj = {
              gameInstance: {
                gameCode: this.gameInstance.gameCode,
              },
              accountId: this.accountService.accountValue.id,
              nickName: this.accountService.accountValue.nickName,
            };

            console.log("Joining game room again");

            this.gameInstanceService.sendGameMesssage("join", obj);
          }
        });
      }
    }
  }

  becomeActive() {
    const obj = {
      gameInstance: {
        gameCode: this.gameInstance.gameCode,
      },
      accountId: this.accountService.accountValue.id,
      nickName: this.accountService.accountValue.nickName,
    };
    //Join the gameroom socket, Let other player know that you have joined
    this.gameInstanceService.sendGameMesssage("join", obj);
  }

  isSocketConnected(): boolean {
    return this.gameInstanceService.isSocketConnected();
  }
}
