import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BeautifyService {
  constructor(private http: HttpClient) {}

  baseUrl = `${environment.apiUrl}/beautify`;

  beautifyHTML(code: string): Observable<string> {
    const url = this.baseUrl + '/html';
    const uglyCode = {
      code: code,
    };
    return this.http.post<string>(url, uglyCode).pipe(
      catchError((err) => {
        console.log('error caught in service');
        console.error(err);
        return throwError(err); //Rethrow it back to component
      })
    );
  }
}
