import { Injectable } from "@angular/core";
import { Observable, throwError, of } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MessageService } from "@app/_services/message.service";
import { Quest } from "./quest.model";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class QuestService {
  private baseUrl = `${environment.apiUrl}/quests`; // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient, private messageService: MessageService) {}

  /** GET quests from the server */
  getQuests(): Observable<Quest[]> {
    return this.http.get<Quest[]>(this.baseUrl).pipe(
      tap((_) => this.log("fetched quests")),
      catchError(this.handleError<Quest[]>("getQuests", []))
    );
  }

  /** GET quest by id. Return `undefined` when id not found */
  getQuestNo404<Data>(id: number): Observable<Quest> {
    const url = `${this.baseUrl}/?id=${id}`;
    return this.http.get<Quest[]>(url).pipe(
      map((quests) => quests[0]), // returns a {0|1} element array
      tap((h) => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} quest id=${id}`);
      }),
      catchError(this.handleError<Quest>(`getQuest id=${id}`))
    );
  }

  /** GET quest by id. Will 404 if id not found */
  getQuest(id: string): Observable<Quest> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Quest>(url).pipe(
      tap((_) => this.log(`fetched quest id=${id}`)),
      catchError(this.handleError<Quest>(`getQuest id=${id}`))
    );
  }

  /* GET quests whose name contains search term */
  searchQuests(term: string): Observable<Quest[]> {
    if (!term.trim()) {
      // if not search term, return empty quest array.
      return of([]);
    }
    return this.http.get<Quest[]>(`${this.baseUrl}/?name=${term}`).pipe(
      tap((x) => (x.length ? this.log(`found quests matching "${term}"`) : this.log(`no quests matching "${term}"`))),
      catchError(this.handleError<Quest[]>("searchQuests", []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new quest to the server */
  addQuest(quest: Quest): Observable<Quest> {
    const url = `${this.baseUrl}/create`;
    return this.http.post<Quest>(url, quest, this.httpOptions).pipe(
      tap((newQuest: Quest) => this.log(`added quest w/ id=${newQuest.id}`)),
      catchError(this.handleError<Quest>("addQuest"))
    );
  }

  /** DELETE: delete the quest from the server */
  deleteQuest(quest: Quest | string): Observable<Quest> {
    const id = typeof quest === "string" ? quest : quest.id;
    const url = `${this.baseUrl}/${id}`;

    return this.http.delete<Quest>(url, this.httpOptions).pipe(
      tap((_) => this.log(`deleted quest id=${id}`)),
      catchError(this.handleError<Quest>("deleteQuest"))
    );
  }

  /** PUT: update the quest on the server */
  updateQuest(quest: Quest): Observable<any> {
    const id = quest.id;
    const url = `${this.baseUrl}/${id}`;
    return this.http.put(url, quest, this.httpOptions).pipe(
      tap((_) => this.log(`updated quest id=${quest.id}`)),
      catchError(this.handleError<any>("updateQuest"))
    );
  }

  removeGameFromQuest(questId: string, gameId: string): Observable<Quest> {
    const url = `${this.baseUrl}/${questId}/games/${gameId}`;

    return this.http.delete<Quest>(url).pipe(
      tap((_) => this.log(`removed game id=${gameId} from quest`)),
      catchError(this.handleError<Quest>("removeGameFromQuest"))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a QuestService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`QuestService: ${message}`);
  }
}
