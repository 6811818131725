import { Component, OnInit, Input, QueryList, ViewChildren, AfterViewInit } from "@angular/core";
import { Quest } from "../quest.model";
import { QuestService } from "../quest.service";
import { Location } from "@angular/common";
import { AceDirective, AceConfigInterface } from "ngx-ace-wrapper";
import { AccountService, AlertService } from "@app/_services";
import { CodeHelperLinks } from "@app/_helpers/code-helper-links.enum";
import { BeautifyService } from "@app/_services/beautify.service";
import "brace/mode/json";
import "brace/mode/python";
import "brace/snippets/python";
import { PythonService } from "@app/_services/python.service";
import { FileSystemService } from "@app/_services/file-system.service";

@Component({
  selector: "app-quest-detail",
  templateUrl: "./quest-detail.component.html",
  styleUrls: ["./quest-detail.component.css"],
})
export class QuestDetailComponent implements OnInit, AfterViewInit {
  @Input() questId: string;
  @Input() quest: Quest;

  //TODO - remove editor array, and use single editor in this component.
  @ViewChildren(AceDirective) editors?: QueryList<AceDirective>;
  config: AceConfigInterface = {
    mode: "html",
    theme: "twilight",
    readOnly: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,

    fontFamily: "Inconsolata, Monaco, Consolas, 'Courier New', Courier;",
    fontSize: "12pt",
    tabSize: 4,
    // enableEmmet: true,
  };
  editorType: string = "directive";
  showJSON = false;
  displayCodeTypeInEditor: number = 1;
  codeForPreview: string;
  CodeHelperLinks = CodeHelperLinks;
  codeLanguage: string;

  inputName: string;
  activeFileInEditor: string;
  previewContent: any = "";
  pythonResult: any = { result: "Your python result will show here ...", type: "info" };

  constructor(
    private questService: QuestService,
    private alertService: AlertService,
    private location: Location,
    private beautifyService: BeautifyService,
    private pythonService: PythonService,
    private fileSystemService: FileSystemService,
    private accountService: AccountService
  ) {
    this.inputName = "quest_" + this.questId;
  }

  ngOnInit(): void {
    if (this.questId) {
      this.questService.getQuest(this.questId).subscribe((quest) => {
        this.quest = quest;
        // this.setFileCodeInEditor(this.quest.files[0]);
        this.setCodeInEditor();
      });
    } else {
      // this.setFileCodeInEditor(this.quest.files[0]);
      this.setCodeInEditor();
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    //wait for editor to be created. Number 500 is arbitrary at this point
    setTimeout(() => {
      this.setCodeInEditor();
    }, 2000);
  }

  public onContentChange(event): void {
    console.log("onContentChange");
    //this.quest = JSON.parse(event.target.value);
    this.getCodeFromEditor();
  }

  private getCodeFromEditor() {
    if (this.quest && this.editors) {
      this.editors.map((e) => {
        if (this.displayCodeTypeInEditor == -1) {
          this.quest = <Quest>(<unknown>JSON.parse(e.ace().getValue()));
        }
        if (this.displayCodeTypeInEditor == 0) {
          this.quest.startingCode = this.codeForPreview = e.ace().getValue();
        }
        if (this.displayCodeTypeInEditor == 1) {
          this.quest.completeCode = this.codeForPreview = e.ace().getValue();
        }
        if (this.displayCodeTypeInEditor == 2) {
          this.quest.codeHint = this.codeForPreview = e.ace().getValue();
        }
        if (this.displayCodeTypeInEditor == 3) {
          this.quest.wrapperCode = this.codeForPreview = e.ace().getValue();
        }
      });
    }
  }

  private setCodeInEditor() {
    if (this.quest && this.editors) {
      this.editors.map((e) => {
        if (this.displayCodeTypeInEditor == -1) {
          console.log(JSON.stringify(this.quest, null, 2));

          e.ace().setValue(JSON.stringify(this.quest, null, 2));
          e.ace().setOptions({ mode: "ace/mode/json" });
        } else {
          if (this.displayCodeTypeInEditor == 0) {
            this.codeForPreview = this.quest.startingCode;
            e.ace().setValue(this.quest.startingCode);
          }
          if (this.displayCodeTypeInEditor == 1) {
            this.codeForPreview = this.quest.completeCode;
            e.ace().setValue(this.quest.completeCode);
          }
          if (this.displayCodeTypeInEditor == 2) {
            this.codeForPreview = this.quest.codeHint;
            e.ace().setValue(this.quest.codeHint);
          }
          if (this.displayCodeTypeInEditor == 3) {
            this.codeForPreview = this.quest.wrapperCode;
            e.ace().setValue(this.quest.wrapperCode);
          }
          if (this.quest.codeLanguage) {
            e.ace().setOptions({ mode: "ace/mode/" + this.quest.codeLanguage });
          }
        }
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    // const quest = JSON.parse(questStr);
    if (this.quest.id) {
      this.questService.updateQuest(this.quest).subscribe((quest) => {
        this.quest = quest;
        this.alertService.success("Update quest successful");
      });
    } else {
      this.questService.addQuest(this.quest).subscribe((quest) => {
        this.quest = quest;
        this.alertService.success("Added quest successfully");
      });
    }
  }

  onValueChange(event): void {
    this.quest = JSON.parse(event.target.value);
  }

  //Solves issue of losing focus on input fields described here - https://stackoverflow.com/questions/42322968/angular2-dynamic-input-field-lose-focus-when-input-changes/42329031
  trackByFn(index: any) {
    return index;
  }

  setDisplayCodeTypeInEditor(type: number) {
    this.displayCodeTypeInEditor = type;
    this.setCodeInEditor();
  }

  displayJSON(val: boolean) {
    this.showJSON = val;
    if (this.showJSON == true) {
      this.setDisplayCodeTypeInEditor(-1);
    }
    if (this.showJSON == false) {
      this.setDisplayCodeTypeInEditor(1); //set code type to complete code, as that button is currently active upon loading of buttons in DOM
    }
  }

  addInstruction() {
    this.quest.instructions.length++;
  }

  deleteInstruction(i: number) {
    this.quest.instructions.splice(i, 1);
  }

  addFile() {
    if (this.quest.files) {
      this.quest.files.push({ name: "", language: "", content: "" });
    } else {
      this.quest.files = [{ name: "", language: "", content: "" }];
    }
  }

  deleteFile(i: number) {
    this.quest.files.splice(i, 1);
  }

  showFileCodeInEditor(file: any) {
    this.activeFileInEditor = file.name;
    this.setFileCodeInEditor(file);
    console.log(file);
  }

  private getFileCodeFromEditor() {
    if (this.quest && this.editors) {
      this.editors.map((e) => {
        this.getObjectByValue(this.quest.files, name, this.activeFileInEditor).content = e.ace().getValue();
      });
    }
  }

  private setFileCodeInEditor(file: any) {
    if (this.quest && this.editors) {
      this.editors.map((e) => {
        e.ace().setValue(file.content);

        if (file.language) {
          e.ace().setOptions({ mode: "ace/mode/" + file.language });
        }
      });
    }
  }

  getObjectByValue = function (array, key, value) {
    return array.filter(function (object) {
      return object[key] === value;
    });
  };

  public formatHTML() {
    if (this.quest && this.editors) {
      this.editors.map((e) => {
        e.ace().getValue();
        this.beautifyService.beautifyHTML(e.ace().getValue()).subscribe((beautifiedCode) => e.ace().setValue(beautifiedCode["code"]));
      });
    }
  }

  changeAceMode(value: string) {
    if (this.quest && this.editors) {
      this.editors.map((e) => {
        if (value) {
          e.ace().setOptions({ mode: "ace/mode/" + value });
        }
      });
    }
  }

  runPython() {
    console.log("Inside runPython:");
    this.previewContent = { result: "Executing...", type: "info" };

    let IDEFileName = "temp-quest.py";
    this.fileSystemService.writeFile(this.codeForPreview, IDEFileName).subscribe((res: any) => {
      console.log(res.message);
      this.pythonService.run(this.accountService.accountValue.urlName, IDEFileName).subscribe((pythonOutput: any) => {
        console.log("printing result");
        console.log(pythonOutput);

        this.previewContent = pythonOutput;
      });
    }); //right now only one file can be written, hardcoded to editor.py
  }
}
