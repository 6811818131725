import { Component, OnInit, ViewChildren, QueryList, SimpleChanges, OnChanges, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { GameInstance } from "../shared/game-instance.model";
import { GameInstanceService } from "./game-instance.service";

/* Begin Ace Editor imports */
import { Clipboard } from "@angular/cdk/clipboard";

import "brace";
import "brace/mode/html";
import "brace/theme/twilight";
import "brace/ext/language_tools";
import "brace/snippets/html"; //If snippets stop working, bring this file locally or import it from brace directory
import "brace/snippets/javascript";
import "brace/ext/beautify";

//Ravi - TODO - emmet not working here, but can get to work it here - node_modules/ace-builds/editor.html
// have to import both - ext/emmet.js" and https://rawgithub.com/nightwing/emmet-core/master/emmet.js
// import "brace/ext/emmet";
// import "emmet";

import { AceComponent, AceDirective, AceConfigInterface } from "ngx-ace-wrapper";
/* End Ace Editor imports */

@Component({
  selector: "app-game-instance",
  templateUrl: "./game-instance.component.html",
  styleUrls: ["./game-instance.component.less"],
})
export class GameInstanceComponent implements OnInit, OnChanges, AfterViewInit {
  config: AceConfigInterface = {
    mode: "html",
    theme: "twilight",
    readOnly: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,

    fontFamily: "Inconsolata, Monaco, Consolas, 'Courier New', Courier;",
    fontSize: "12pt",
    tabSize: 4,
    // enableEmmet: true,
  };
  editorType: string = "directive";
  @ViewChildren(AceDirective) editors?: QueryList<AceDirective>;
  userCode: string[] = [""];

  gameInstance: GameInstance;
  gameCode: number;

  constructor(private route: ActivatedRoute, private gameInstanceService: GameInstanceService) {}

  ngOnInit(): void {
    this.gameCode = +this.route.snapshot.paramMap.get("gameCode");
    this.gameInstanceService
      .getGameInstanceByGameCode(this.gameCode)
      .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    console.log("Values on ngAfterViewInit():");
    if (this.editors) {
      this.editors.map((e, i) => {
        //  this.userCode[i] = this.gameInstance.players[0].code;
        e.ace().setValue(this.userCode[i]);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add 'implements OnChanges' to the class.
    if (this.editors) {
      this.editors.map((e, i) => {
        //  this.userCode[i] = this.gameInstance.players[0].code;
        e.ace().setValue(this.userCode[i]);
      });
    }
  }

  /* Ace editor helper functions */
  public onValueChange(value: string): void {
    console.log("Value change:", value);
  }

  public onContentChange(event: any): void {
    if (this.editors) {
      this.editors.map((e, i) => {
        this.userCode[i] = e.ace().getValue();
        console.log("userCode", this.userCode[i]);
        // const session = e.ace().session;
      });
    }
  }
}
