<div class="container-fluid bg-dark">
  <div class="container bg-dark">
    <div class="display-4 text-white text-center p-5">Play, learn and compete while learning to code</div>

    <section *ngIf="hoursOfCode.length > 0">
      <div class="row text-center">
        <div class="col-lg-12 text-center">
          <h2 class="text-white text-center p-3">Hours of Code</h2>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-6 mb-5 text-center text-dark align-items-stretch" *ngFor="let competition of hoursOfCode">
          <app-competition-summary [competitionId]="competition.id"></app-competition-summary>
        </div>
      </div>
      <hr />
    </section>

    <section *ngIf="clubs.length > 0">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="text-white text-center p-3">Clubs</h2>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-6 mb-5 text-center text-dark align-items-stretch" *ngFor="let competition of clubs">
          <app-competition-summary [competitionId]="competition.id"></app-competition-summary>
        </div>
      </div>
      <hr />
    </section>

    <section *ngIf="competitions.length > 0">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="text-white p-3">Competitions</h2>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-6 mb-5 text-center text-dark align-items-stretch" *ngFor="let competition of competitions">
          <app-competition-summary [competitionId]="competition.id"></app-competition-summary>
        </div>
      </div>
      <hr />
    </section>
  </div>
</div>
<div *ngIf="account && (account.role === Role.Admin || account.role === Role.Teacher)">
  <app-competition-create></app-competition-create>
</div>
