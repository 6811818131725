<div class="jumbotron">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="display-3 text-center" *ngIf="game">{{ game.name }}</div>
        <div class="display-4 text-center text-info">GameCode: {{ gameInstance.gameCode }}</div>
        <div class="text-center">
          <div class="btn btn-link" [cdkCopyToClipboard]="gameInstanceUrl" (click)="copied = !copied">
            <span *ngIf="copied && gameInstance.gameCode">Copied Invite Link</span>
            <span *ngIf="!copied && gameInstance.gameCode">Copy Invite Link</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-5">
      <div class="col text-center mt-2">
        <h1>
          <span class="badge badge-pill badge-warning"> Waiting for others to join ... </span>
        </h1>
        <div *ngFor="let player of gameInstance.players">
          <div *ngIf="player.playerStatus == 'ACTIVE'">
            <h1>
              <span class="badge badge-pill badge-success"> {{ player.name }}</span>
            </h1>
          </div>
          <div *ngIf="player.playerStatus == 'INACTIVE'">
            <h1>
              <span class="badge badge-pill badge-danger"> {{ player.name }}</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5" *ngIf="amIHost()">
      <div class="col-sm-3 text-center">
        <div class="btn btn-large btn-danger" (click)="startGame()">Start Game</div>
      </div>
    </div>
    <div class="row justify-content-center mt-5" *ngIf="amIHost()">
      <div class="col-sm-3 text-center">
        <div class="btn btn-large btn-danger" (click)="endGame()">End Game</div>
      </div>
    </div>

    <div class="row justify-content-center mt-5" *ngIf="!amIHost()">
      <div class="col-sm-3 text-center">
        <div class="btn btn-large btn-danger" (click)="leaveGame()">Leave Game</div>
      </div>
    </div>

    <div *ngIf="amIHost() && gameInstance && gameInstance.gameCode">
      <div class="row justify-content-center mt-5">
        <div class="col-sm-3 text-center">
          <label class="switch">
            <input type="checkbox" (change)="onHostPlayingChangeEvent($event)" [(ngModel)]="gameInstance.hostPlaying" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div class="row justify-content-center mt-1">
        <div class="col-sm-3 text-center text-primary text-bold">Should host be scored?</div>
      </div>
    </div>

    <div class="row justify-content-center mt-5" *ngIf="amIHost()">
      <div class="col-sm-3 text-center">
        <a
          target="_blank"
          [routerLink]="['/games/gameInstance/controlPanel/' + gameInstance.gameCode]"
          routerLinkActive="router-link-active"
          >Control Panel</a
        >
      </div>
    </div>
  </div>
</div>
