<h3 class="card-header">{{ title }}</h3>
<div class="card-body">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label>Email</label>
      <input
        type="text"
        formControlName="emailArray"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.emailArray.errors }"
        (change)="onFormControlChange($event, $event.target.value, $event.target.attributes.formcontrolname.value)"
      />
      <div *ngIf="submitted && f.emailArray.errors" class="invalid-feedback">
        <div *ngIf="f.emailArray.errors.required">Email is required</div>
        <div *ngIf="f.emailArray.errors.invalidEmailArray">
          Email number {{ f.emailArray.errors.invalidEmailArray.invalidEmailIndex + 1 }} is invalid
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Message</label>
      <textarea
        type="text"
        formControlName="message"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
        (change)="onFormControlChange($event, $event.target.value, $event.target.attributes.formcontrolname.value)"
        style="resize: vertical"
        rows="8"
      ></textarea>
      <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
        <div *ngIf="f.message.errors.required">Message is required</div>
        <div *ngIf="f.message.errors.email">Message is invalid</div>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-primary">Submit</button>
    </div>
  </form>
</div>
<!-- 
<div class="modal-dialog" role="document">
  <div class="modal-content text-medium">
    <div class="modal-header">
      <h2 class="modal-title" id="title">{{ title }}</h2>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 mb-2">
          <label>Emails: <br /></label>
          (separate by commas)
        </div>
        <div class="col-12">
          <input class="text-center" [(ngModel)]="to" name="to" (change)="onChangedTo($event)" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-2">
          <label>Message:</label>
        </div>
        <div class="col-12">
          <textarea style="resize: vertical" rows="8" [(ngModel)]="message" name="message"></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light-gray" data-dismiss="modal">Close</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="announceWinner()">Send message</button>
    </div>
  </div>
</div> -->
