import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GamesComponent } from "./games.component";
import { GameInstanceComponent } from "./game-instance/game-instance.component";
import { GameCreateComponent } from "./game-create/game-create.component";
import { GameLobbyComponent } from "./game-lobby/game-lobby.component";
import { GameDetailComponent } from "./game-detail/game-detail.component";
import { GameJoinComponent } from "./game-join/game-join.component";
import { GamePlayComponent } from "./game-play/game-play.component";
import { GameLeaderboardComponent } from "./game-leaderboard/game-leaderboard.component";
import { GameQuestComponent } from "./game-quest/game-quest.component";
import { CanDeactivateGuard } from "../_helpers/can-deactivate.guard";
import { GameLivePreviewComponent } from "./game-live-preview/game-live-preview.component";
import { AuthGuard } from "@app/_helpers/auth.guard";
import { GameInstanceDetailComponent } from "./game-instance-detail/game-instance-detail.component";
import { GameShowComponent } from "./game-show/game-show.component";
import { RegistrationGuard } from "@app/_helpers/registration.guard";

const routes: Routes = [
  { path: "", component: GamesComponent },
  { path: "create", component: GameCreateComponent, canActivate: [AuthGuard] },
  { path: "join", component: GameJoinComponent, canActivate: [AuthGuard] },
  { path: "gameQuests", component: GameQuestComponent, canActivate: [AuthGuard] },
  { path: "gameResults/:gameCode", component: GameLeaderboardComponent },
  { path: "gamePlay/:gameCode", component: GamePlayComponent, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
  { path: "gameInstance/:gameCode", component: GameInstanceComponent, canActivate: [AuthGuard] },
  { path: "gameInstance/controlPanel/:gameCode", component: GameInstanceDetailComponent },
  { path: "leaderboard/:gameCode", component: GameLeaderboardComponent, canDeactivate: [CanDeactivateGuard] },
  { path: "livePreview/:gameCode", component: GameLivePreviewComponent, canDeactivate: [CanDeactivateGuard] },
  { path: "lobby/game/:id", component: GameLobbyComponent, canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
  {
    path: "lobby/gameCode/:gameCode",
    component: GameLobbyComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard],
  },
  // { path: ":id", component: GameDetailComponent, canActivate: [AuthGuard] },
  { path: ":id/edit", component: GameDetailComponent },
  { path: ":id/show", component: GameShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GameRoutingModule {}
