import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CodeRoutingModule } from "./code-routing.module";
import { CodePreviewComponent } from "./code-preview/code-preview.component";
import { PythonResultComponent } from "./python-result/python-result.component";
import { CodeIdeComponent } from "./code-ide/code-ide.component";
import { AceConfigInterface, AceModule, ACE_CONFIG } from "ngx-ace-wrapper";
import { CodeLanguageMenuComponent } from "./code-language-menu/code-language-menu.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { CodeEditorComponent } from './code-editor/code-editor.component';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
  tabSize: 7,
};

@NgModule({
  declarations: [CodePreviewComponent, PythonResultComponent, CodeIdeComponent, CodeLanguageMenuComponent, CodeEditorComponent],
  imports: [CommonModule, CodeRoutingModule, AceModule, ClipboardModule],
  exports: [CodePreviewComponent, PythonResultComponent],
  providers: [
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG,
    },
  ],
})
export class CodeModule {}
