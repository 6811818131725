import { Injectable } from "@angular/core";
import { catchError, tap, map } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { environment } from "@environments/environment";
import { GameInstance } from "../shared/game-instance.model";
import { MessageService } from "../../_services/message.service";
import { Player } from "../shared/player.model";
import { Socket } from "ngx-socket-io";
import { AccountService } from "@app/_services";
import { ArrayService } from "@app/_services/array.service";
import { retryWithBackoff } from "@app/_helpers/retryWithBackOff";

@Injectable({
  providedIn: "root",
})
export class GameInstanceService {
  baseUrl = `${environment.apiUrl}/gameInstances`;
  socketGameRooms: string[] = [];

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private socket: Socket,
    private accountService: AccountService,
    private arrayService: ArrayService
  ) {
    this.socket.on("connect", () => {
      console.log("connected");

      this.socket.on("disconnect", () => {
        console.log("disconnected");
      });

      console.log(this.socketGameRooms);

      for (let i = 0; i < this.socketGameRooms.length; i++) {
        const gameRoom = this.socketGameRooms[i];

        let gameInstanceObj = {
          gameCode: +gameRoom,
          players: [
            {
              account: this.accountService.accountValue.id,
              name: this.accountService.accountValue.nickName,
            },
          ],
        };

        this.addPlayer(gameInstanceObj).subscribe((gameInstance) => {
          if (!gameInstance) {
            console.log("Empty gameInstance received");
          }
          if (gameInstance) {
            const obj = {
              gameInstance: {
                gameCode: gameInstance.gameCode,
              },
              accountId: this.accountService.accountValue.id,
              nickName: this.accountService.accountValue.nickName,
            };
            //Join the gameroom socket, Let other player know that you have joined
            this.sendGameMesssage("join", obj);
          }
        });
      }
    });

    this.socket.on("reconnect", () => {
      console.log("reconnected");
    });
  }

  getGameInstances(): Observable<GameInstance[]> {
    return this.http.get<GameInstance[]>(`${this.baseUrl}`).pipe(
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  /** GET GameInstance by id. Will 404 if id not found */
  getGameInstance(id: string): Observable<GameInstance> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<GameInstance>(url).pipe(
      retryWithBackoff(1000),
      tap((_) => this.log(`fetched GameInstance id=${id}`)),
      catchError(this.handleError<GameInstance>(`getGameInstance id=${id}`))
    );
  }

  /** GET all GameInstances by game. Will 404 if id not found */
  getGameInstancesByGameId(gameId: string): Observable<[GameInstance]> {
    const url = `${this.baseUrl}/game/${gameId}`;
    return this.http.get<[GameInstance]>(url).pipe(
      retryWithBackoff(1000),
      tap((_) => this.log(`fetched GameInstance gameId=${gameId}`)),
      catchError(this.handleError<[GameInstance]>(`getGameInstancesByGameId id=${gameId}`))
    );
  }

  /** GET GameInstance by gameCode. Will 404 if id not found */
  getGameInstanceByGameCode(gameCode: number): Observable<GameInstance> {
    const url = `${this.baseUrl}/gameCode/${gameCode}`;
    return this.http.get<GameInstance>(url).pipe(
      retryWithBackoff(1000),
      tap((_) => this.log(`fetched GameInstance gameCode=${gameCode}`)),
      catchError(this.handleError<GameInstance>(`getGameInstanceByGameCode id=${gameCode}`))
    );
  }

  /** GET GameInstance by gameCode. Will 404 if id not found */
  getGameInstanceByGameCodeWithGame(gameCode: number): Observable<GameInstance> {
    const url = `${this.baseUrl}/gameCodeWithGame/${gameCode}`;
    return this.http.get<GameInstance>(url).pipe(
      retryWithBackoff(1000),
      tap((_) => this.log(`fetched GameInstance gameCode=${gameCode}`)),
      catchError(this.handleError<GameInstance>(`getGameInstanceByGameCodeWithGame id=${gameCode}`))
    );
  }

  /** GET GameInstance by accountId. Will 404 if id not found */
  getGameInstancesByAccountId(accountId: string): Observable<GameInstance[]> {
    const url = `${this.baseUrl}/account/${accountId}`;
    return this.http.get<GameInstance[]>(url).pipe(
      tap((_) => this.log(`fetched GameInstance accountId=${accountId}`)),
      catchError(this.handleError<GameInstance[]>(`getGameInstancesByAccountId id=${accountId}`))
    );
  }

  //////// Save methods //////////

  /** POST: add a new GameInstance to the server */
  createGameInstance(gameInstance: GameInstance): Observable<GameInstance> {
    return this.http.post<GameInstance>(`${this.baseUrl}/create`, gameInstance).pipe(
      retryWithBackoff(1000),
      tap((newGameInstance: GameInstance) => this.log(`added gameInstance w/ id=${newGameInstance.id}`)),
      catchError(this.handleError<GameInstance>("createGameInstance"))
    );
  }

  updateGameInstance(gameInstance: GameInstance): Observable<GameInstance> {
    const id = typeof gameInstance === "number" ? gameInstance : gameInstance.id;
    const url = `${this.baseUrl}/${id}`;
    this.log(url);
    return this.http.put<GameInstance>(url, gameInstance).pipe(
      tap((_) => this.log("updated game")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  resetStartingQuest(gameInstance: GameInstance): Observable<GameInstance> {
    const id = typeof gameInstance === "number" ? gameInstance : gameInstance.id;
    const url = `${this.baseUrl}/resetStartingQuest`;
    this.log(url);
    return this.http.put<GameInstance>(url, gameInstance).pipe(
      tap((_) => this.log("updated game")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  /** DELETE: delete the fixture from the server */
  deleteGameInstance(gameInstance: GameInstance | string): Observable<any> {
    const id = typeof gameInstance === "string" ? gameInstance : gameInstance.id;
    const url = `${this.baseUrl}/${id}`;

    return this.http.delete<GameInstance>(url).pipe(
      tap((_) => this.log(`deleted gameInstance id=${id}`)),
      catchError(this.handleError<GameInstance>("deleteGameInstance"))
    );
  }

  addPlayer(gameInstance: GameInstance): Observable<GameInstance> {
    return this.http.put(`${this.baseUrl}/join`, gameInstance).pipe(
      retryWithBackoff(1000),
      tap((gameInstance: GameInstance) => this.log(`updated gameInstance gameCode=${gameInstance.gameCode}`)),
      catchError(this.handleError<any>("addPlayer"))
    );
  }

  updateUserCode(gameInstance: GameInstance, finalTime = false): Observable<GameInstance> {
    //if updating at the end of the quest then retry, otherwise abandon the update upon failure
    if (finalTime) {
      return this.http.put(`${this.baseUrl}/updateUserCode`, gameInstance).pipe(
        retryWithBackoff(1000),
        tap((gameInstance: GameInstance) => this.log(`updated gameInstance gameCode=${gameInstance.gameCode}`)),
        catchError(this.handleError<any>("updateUserCode"))
      );
    } else {
      return this.http.put(`${this.baseUrl}/updateUserCode`, gameInstance).pipe(
        tap((gameInstance: GameInstance) => this.log(`updated gameInstance gameCode=${gameInstance.gameCode}`)),
        catchError(this.handleError<any>("updateUserCode"))
      );
    }
  }

  updatePeerScore(gameInstance: GameInstance): Observable<GameInstance> {
    return this.http.put(`${this.baseUrl}/updatePeerScore`, gameInstance).pipe(
      retryWithBackoff(2000),
      tap((gameInstance: GameInstance) => this.log(`updated gameInstance gameCode=${gameInstance.gameCode}`)),
      catchError(this.handleError<any>("updatePeerScore"))
    );
  }

  startGame(gameInstance: GameInstance): Observable<GameInstance> {
    return this.http.put(`${this.baseUrl}/startGame`, gameInstance).pipe(
      retryWithBackoff(1000),
      tap((gameInstance: GameInstance) => this.log(`started gameInstance gameCode=${gameInstance.gameCode}`)),
      catchError(this.handleError<any>("startGame"))
    );
  }

  nextQuest(gameInstance: GameInstance): Observable<GameInstance> {
    return this.http.put(`${this.baseUrl}/nextQuest`, gameInstance).pipe(
      retryWithBackoff(1000),
      tap((gameInstance: GameInstance) => this.log(`received nextQuest for gameInstance gameCode=${gameInstance.gameCode}`)),
      catchError(this.handleError<any>("nextQuest"))
    );
  }

  endGame(gameInstance: GameInstance): Observable<GameInstance> {
    return this.http.put(`${this.baseUrl}/endGame`, gameInstance).pipe(
      retryWithBackoff(1000),
      tap((gameInstance: GameInstance) => this.log(`received endGame for gameInstance gameCode=${gameInstance.gameCode}`)),
      catchError(this.handleError<any>("endGame"))
    );
  }

  leaveGame(gameInstance: GameInstance) {
    let accountId = this.accountService.accountValue.id;

    const obj = {
      gameInstance: {
        gameCode: gameInstance.gameCode,
      },
      // gameCode: gameInstance.gameCode,
      accountId: this.accountService.accountValue.id,
      nickName: this.accountService.accountValue.nickName,
    };
    //Leave the gameroom socket, Let other player know that you have left
    this.sendGameMesssage("leave", obj);
  }

  public getJoinMessage() {
    return this.socket.fromEvent<any>("join").pipe(map((data) => data));
  }

  public getLeaveMessage() {
    return this.socket.fromEvent<any>("leave").pipe(map((data) => data));
  }

  public getPlayMessage() {
    return this.socket.fromEvent<any>("play").pipe(map((data) => data));
  }

  public getStartGameMessage() {
    return this.socket.fromEvent<any>("startGame").pipe(map((data) => data));
  }

  public getEndGameMessage() {
    return this.socket.fromEvent<any>("endGame").pipe(map((data) => data));
  }

  public getResultMessage() {
    return this.socket.fromEvent<any>("result").pipe(map((data) => data));
  }

  public getForceFinishMessage() {
    return this.socket.fromEvent<any>("forceFinish").pipe(map((data) => data));
  }

  //support "join",  and "leave" as message
  public sendGameMesssage(message: string, messageObj: any = {}) {
    console.log("sending message: " + message + " object ");

    this.socket.emit(message, messageObj);
    if (message == "join") {
      console.log("Adding to socketGameRooms " + messageObj.gameInstance.gameCode);

      if (!this.arrayService.exists(messageObj.gameInstance.gameCode.toString(), this.socketGameRooms)) {
        this.socketGameRooms.push(messageObj.gameInstance.gameCode.toString());
      }
    }
    if (message == "leave") {
      console.log("Removing from socketGameRooms " + messageObj.gameInstance.gameCode);

      this.socketGameRooms = this.arrayService.removeItem(messageObj.gameInstance.gameCode.toString(), this.socketGameRooms);
    }
  }

  public getSocketGameRooms(): string[] {
    return this.socketGameRooms;
  }

  public isSocketConnected(): boolean {
    return this.socket.ioSocket.connected;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a GameInstanceService message with the MessageService */
  private log(message: string) {
    // RAVI - TODO: Implement messageService from GameInstances tutorial
    this.messageService.add(`GameInstance Service: ${message}`);
  }
}
