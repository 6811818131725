<div class="container" *ngIf="fixture">
  <div class="row mt-5 text-center">
    <div class="col-sm-12">
      <h2>{{ fixture.name | uppercase }}</h2>
      <a routerLink="/fixtures/{{ fixture.id }}" routerLinkActive="router-link-active">{{ fixture.name | uppercase }}</a>
    </div>
    <div class="btn btn-small btn-success text-center col-sm-12" (click)="addFixtureToCompetition(null)">New Fixture</div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-6">
      <div class="btn-group btn-group-toggle text-white" data-toggle="buttons">
        <label class="btn btn-secondary" (click)="displayJSON(true)">
          <input type="radio" name="options" id="option1" autocomplete="off" /> JSON
        </label>
        <label class="btn btn-secondary active" (click)="displayJSON(false)">
          <input type="radio" name="options" id="option2" autocomplete="off" checked /> Form Fields
        </label>
      </div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-danger" (click)="goBack()">Back</div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-primary" (click)="save()">Save Fixture</div>
    </div>
  </div>

  <div *ngIf="showJSON">
    <div class="row mt-5">
      <div class="col-sm-12">
        <textarea rows="25" cols="10" class="form-control form-control-lg" (change)="onValueChange($event)">
                    {{ fixture | json }}
                 </textarea
        >
      </div>
    </div>
  </div>

  <div *ngIf="!showJSON">
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Fixture Name:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="fixture.name" name="fixtureName" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Fixture Description:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="fixture.description" name="fixtureDescription" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Fixture Time:</div>
      <div class="col-lg-9">
        <input
          [ngModel]="fixture.fixtureTime | date: 'EEEE, MMM d, y, h:mm a O'"
          (ngModelChange)="updateFixtureDate($event)"
          name="fixtureTime"
        />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Fixture Location:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="fixture.fixtureLocation" name="fixtureLocation" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Fixture Stage:</div>
      <div class="col-lg-9">
        <select class="form-control" [(ngModel)]="fixture.stage" name="fixtureStage" (change)="parseValue($event.target.value)">
          <option [ngValue]="enum.value" *ngFor="let enum of stage | keyvalue" [selected]="fixture.stage === enum.value">
            {{ enum.value }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Qualified Players:</div>
      <div class="col-lg-9">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let player of competition.registeredPlayers" class="vertical-align-middle">
              <td>{{ player.account.firstName }}</td>

              <td>
                <div
                  *ngIf="!isPlayerAlreadyAdded(player.account.id)"
                  class="btn btn-danger col-sm-12"
                  (click)="addPlayer(player.account.id)"
                >
                  Add
                </div>
                <div
                  *ngIf="isPlayerAlreadyAdded(player.account.id)"
                  class="btn btn-danger col-sm-12"
                  (click)="removePlayer(player.account.id)"
                >
                  Remove
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-3 text-right">Practice Game:</div>
      <div class="col-lg-9">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Game Name</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let game of availableGames" class="vertical-align-middle">
              <td>{{ game.name }}</td>

              <td>
                <div *ngIf="!fixture.practiceGame" class="btn btn-success col-sm-12" (click)="addPracticeGame(game.id)">Assign</div>
                <div
                  *ngIf="fixture.practiceGame && fixture.practiceGame != game.id"
                  class="btn btn-primary col-sm-12"
                  (click)="addPracticeGame(game.id)"
                >
                  Replace
                </div>
                <div
                  *ngIf="fixture.practiceGame && fixture.practiceGame == game.id"
                  class="btn btn-danger col-sm-12"
                  (click)="removePracticeGame(game.id)"
                >
                  Remove Game
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Competition Game:</div>
      <div class="col-lg-9">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Game Name</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let game of availableGames" class="vertical-align-middle">
              <td>{{ game.name }}</td>

              <td>
                <div *ngIf="!fixture.game" class="btn btn-success col-sm-12" (click)="addGame(game.id)">Assign</div>
                <div *ngIf="fixture.game && fixture.game != game.id" class="btn btn-primary col-sm-12" (click)="addGame(game.id)">
                  Replace
                </div>
                <div *ngIf="fixture.game && fixture.game == game.id" class="btn btn-danger col-sm-12" (click)="removeGame(game.id)">
                  Remove Game
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-12">
      <h2 class="col-sm-12 text-center">Note: Save each fixture individually</h2>
      <div *ngFor="let fixture of fixture.fixtures; let i = index">
        <div id="accordion_fixture">
          <div class="card">
            <div class="card-header" id="heading_fixture_{{ i }}">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse_fixture_' + i"
                      aria-expanded="true"
                      aria-controls="collapse_fixture_{{ i }}"
                    >
                      Fixture #{{ i + 1 }}
                    </button>
                  </h5>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveUp(i)" *ngIf="i != 0">Move Up</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveDown(i)" *ngIf="i != fixture.fixtures.length - 1">Move Down</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-danger" (click)="removeFixtureFromCompetition(i)">Remove Fixture</button>
                </div>
              </div>
            </div>

            <div id="collapse_fixture_{{ i }}" class="collapse" aria-labelledby="heading_fixture_{{ i }}" data-parent="#accordion_fixture">
              <div class="card-body">
                <app-fixture-detail [fixtureId]="fixture" [fixture]="null"></app-fixture-detail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
