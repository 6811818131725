import { Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Account, Role } from "../_models";

import { AccountService } from "@app/_services";
import { GameService } from "./shared/game.service";
import { Game } from "./shared/game.model";

@Component({
  selector: "app-game",
  templateUrl: "./games.component.html",
  styleUrls: ["./games.component.less"],
})
export class GamesComponent implements OnInit {
  games: Game[];
  message: string;
  account: Account;
  Role = Role;
  personalBg: string = "url(/assets/img/code-backgrounds/" + "1.jpg" + ")";

  constructor(private gameService: GameService, private accountService: AccountService) {}

  ngOnInit(): void {
    this.accountService.account.subscribe((x) => (this.account = x));
    this.getGames();
  }

  getGames(): void {
    this.gameService.getGames().subscribe((games) => (this.games = games));
  }

  update(game: Game): Observable<Game> {
    return this.gameService.updateGame(game).pipe(
      tap((g: Game) => this.log()),
      catchError(this.handleError<Game>("update game"))
    );
  }

  delete(game: Game): void {
    this.gameService.deleteGame(game).subscribe(
      (response) => {
        const removedGameIndex = this.games.findIndex((item) => item.id == game.id);
        this.games.splice(removedGameIndex, 1);
        //Next callback
        console.log("response received" + response);
        // this.repos = response;
      },
      (error) => {
        //Error callback
        console.log("error caught in component" + error);
        // this.errorMessage = error;
        // this.loading = false;

        //throw error;   //You can also throw the error to a global error handler
      }
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log();

      this.message = error.message;
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log() {
    // RAVI - TODO: Implement messageService from heroes tutorial
    // this.messageService.add(`HeroService: ${message}`);
  }
}
