import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CodeHelperLinks } from "@app/_helpers/code-helper-links.enum";
import { Clipboard } from "@angular/cdk/clipboard";
import { BeautifyService } from "@app/_services/beautify.service";

@Component({
  selector: "app-code-language-menu",
  templateUrl: "./code-language-menu.component.html",
  styleUrls: ["./code-language-menu.component.css"],
})
export class CodeLanguageMenuComponent implements OnInit {
  CodeHelperLinks = CodeHelperLinks;
  @Input() code: string;
  @Input() codeLanguage: string;

  @Output() action = new EventEmitter();

  constructor(private beautifyService: BeautifyService) {}

  ngOnInit(): void {}

  takeAction(action: string) {
    this.action.emit(action);
  }
}
