<section class="text-left">
  <div class="container">
    <div class="row mt-3">
      <div class="col-12">
        <div class="text-medium text-bold">
          <button class="btn btn-link ml-3 mr-3">
            <a [routerLink]="['/coursework']" routerLinkActive="router-link-active">All Courses</a>
          </button>
          <button class="btn btn-link ml-3 mr-3">/</button>
          <button class="btn btn-link ml-3 mr-3">
            <a [routerLink]="['/coursework/', courseId, 'show']" routerLinkActive="router-link-active">{{ courseName }} </a></button
          ><button class="btn btn-link ml-3 mr-3">/</button>
          <button class="btn btn-link ml-3 mr-3">
            <a [routerLink]="['/units/', unitId, 'show']" routerLinkActive="router-link-active">{{ unitName }}</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="mb-5" *ngIf="game">
  <div class="container overflow-auto">
    <div class="row mt-5 text-center d-flex justify-content-center">
      <div class="col-12">
        <h2 class="display-4">{{ game.name }}</h2>
        <h4>{{ game.description }}</h4>
        <i class="fas fa-question"></i>&nbsp;{{ game.quests.length }} Quests
      </div>
    </div>
    <div class="row flex-fill col-12 text-center text-dark d-flex align-items-stretch justify-content-center">
      <!-- <div class="row mt-2 no-gutters">
        <div
          *ngIf="game.gameSubscription == 'PREMIUM'"
          class="col-2 bg-danger text-white bold text-medium"
          style="width: 10%; position: relative; left: -20px"
        >
          $$
        </div>
        <div
          *ngIf="game.gameSubscription == 'FREE'"
          class="col-2 bg-success text-white bold text-medium"
          style="width: 10%; position: relative; left: -20px"
        >
          FREE
        </div>
        <div class="col-10"></div>
      </div> -->

      <!-- <img src="..." class="card-img-top" alt="..." /> -->
      <div class="mt-3">
        <span
          *ngIf="
            game.gameSubscription == 'FREE' || (account && game.gameSubscription == 'PREMIUM' && account.accountSubscription == 'PREMIUM');
            else dontAllowHosting
          "
        >
          <div class="btn-group">
            <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Play
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" routerLink="/games/lobby/game/{{ game.id }}/" [queryParams]="{ peerRatingAllowed: false }"
                >Play Alone</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/games/lobby/game/{{ game.id }}/" [queryParams]="{ peerRatingAllowed: false }"
                >Play with friends & only you rate others</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/games/lobby/game/{{ game.id }}/" [queryParams]="{ peerRatingAllowed: true }"
                >Play with friends & rate each other</a
              >
            </div>
          </div>
        </span>
        <ng-template #dontAllowHosting>
          <span class="btn btn-black">Play</span>
        </ng-template>

        <span *ngIf="(account && account.role === Role.Admin) || (account && game.createdBy.toString() == account.id.toString())">
          <a routerLink="/games/{{ game.id }}/edit" class="btn btn-primary m-2">Details</a>
        </span>
        <span
          *ngIf="(account && account.role === Role.Admin) || (account && game.createdBy.toString() == account.id.toString())"
          class="btn btn-danger"
          (click)="delete(game)"
        >
          Delete
        </span>
      </div>
    </div>
  </div>
</section>
<section *ngIf="accountGames && accountGames.length > 0" class="bg-dark">
  <div class="container">
    <div class="row mt-5">
      <div class="col-12 mt-5">
        <h1 class="text-center text-white">All Time Leaderboard For This Game</h1>
      </div>
    </div>
    <div class="row mb-5 p-5">
      <div class="col-lg-3"></div>
      <div class="col-lg-6">
        <table class="table table-light table-striped text-center">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Score</th>
              <!-- <th scope="col">Code Link</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of accountGames; let i = index" class="vertical-align-middle">
              <td>{{ p.account.firstName + " " + p.account.lastName }}</td>
              <td>{{ p.gameData[0].bestScore }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
<!-- <section *ngIf="quests.length > 0">
  <div class="row mt-5 p-5">
    <div class="col-12 mt-5">
      <h1 class="text-center">Game Quests</h1>
    </div>
    <div class="col-12">
      <div *ngFor="let quest of quests; let i = index">
        <div id="accordion_game">
          <div class="card">
            <div class="card-header" id="heading_quest_{{ i }}">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse_quest_' + i"
                      aria-expanded="true"
                      aria-controls="collapse_quest_{{ i }}"
                    >
                      Quest #{{ i + 1 }}: {{ quest.name }}
                    </button>
                  </h5>
                </div>
              </div>
            </div>

            <div id="collapse_quest_{{ i }}" class="collapse" aria-labelledby="heading_quest_{{ i }}" data-parent="#accordion_game">
              <div class="card-body">
                {{ quest_id }}
                <app-quest-show [questId]="quest._id" [quest]="quest"></app-quest-show>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
