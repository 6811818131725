<div class="container" *ngIf="course">
  <div class="row mt-5 text-center">
    <div class="col-sm-12">
      <h2>{{ course.name | uppercase }}</h2>
      <h4>{{ course.units.length }} - Units</h4>
    </div>
    <div class="btn btn-small btn-success text-center col-sm-12" (click)="addUnitToCourse(null)">New Unit</div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-6">
      <div class="btn-group btn-group-toggle text-white" data-toggle="buttons">
        <label class="btn btn-secondary" (click)="displayJSON(true)">
          <input type="radio" name="options" id="option1" autocomplete="off" /> JSON
        </label>
        <label class="btn btn-secondary active" (click)="displayJSON(false)">
          <input type="radio" name="options" id="option2" autocomplete="off" checked /> Form Fields
        </label>
      </div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-danger" (click)="goBack()">Back</div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-primary" (click)="save()">Save Course</div>
    </div>
  </div>

  <div *ngIf="showJSON">
    <div class="row mt-5">
      <div class="col-sm-12">
        <textarea rows="25" cols="10" class="form-control form-control-lg" (change)="onValueChange($event)">
                {{ course | json }}
             </textarea
        >
      </div>
    </div>
  </div>

  <div *ngIf="!showJSON">
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Course Name:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="course.name" name="courseName" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Course Description:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="course.description" name="courseDescription" />
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-12">
      <h2 class="col-sm-12 text-center">Note: Save each unit individually</h2>
      <div *ngFor="let unit of course.units; let i = index">
        <div id="accordion_course">
          <div class="card">
            <div class="card-header" id="heading_unit_{{ i }}">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse_unit_' + i"
                      aria-expanded="true"
                      aria-controls="collapse_unit_{{ i }}"
                    >
                      Unit #{{ i + 1 }}
                    </button>
                  </h5>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveUp(i)" *ngIf="i != 0">Move Up</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveDown(i)" *ngIf="i != course.units.length - 1">Move Down</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-danger" (click)="removeUnitFromCourse(i)">Remove Unit</button>
                </div>
              </div>
            </div>

            <div id="collapse_unit_{{ i }}" class="collapse" aria-labelledby="heading_unit_{{ i }}" data-parent="#accordion_course">
              <div class="card-body">
                <app-unit-detail [unitId]="unit" [unit]="null"></app-unit-detail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
