import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AceDirective, AceConfigInterface } from "ngx-ace-wrapper";

import { BeautifyService } from "@app/_services/beautify.service";
import { FileSystemService } from "@app/_services/file-system.service";
import { PythonService } from "@app/_services/python.service";
import { AccountService } from "@app/_services";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-code-ide",
  templateUrl: "./code-ide.component.html",
  styleUrls: ["./code-ide.component.css"],
})
export class CodeIdeComponent implements OnInit {
  // pythonResult: any = { result: "Your python result will show here ...", type: "info" };
  // userCode: string;
  code: string = "";
  codeLanguage: string = "python";
  userCodeChanged: boolean = false;
  previewContent: any = "";

  constructor(
    private beautifyService: BeautifyService,
    private fileSystemService: FileSystemService,
    private pythonService: PythonService,
    private accountService: AccountService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.codeLanguage = this.route.snapshot.queryParams["codeLanguage"] ? this.route.snapshot.queryParams["codeLanguage"] : "python";
  }

  onUserCodeChanged(code: string) {
    this.code = code;
    this.userCodeChanged = true;
  }

  takeAction(action: string) {
    switch (action) {
      case "runPython":
        this.runPython();
        break;

      case "formatHTML":
        this.formatHTML();
        break;

      default:
        break;
    }
  }

  runPython() {
    console.log("Inside runPython:");
    this.previewContent = { result: "Executing...", type: "info" };

    let IDEFileName = "editor.py";
    this.fileSystemService.writeFile(this.code, IDEFileName).subscribe((res: any) => {
      console.log(res.message);
      this.pythonService.run(this.accountService.accountValue.urlName, IDEFileName).subscribe((pythonOutput: any) => {
        console.log("printing result");
        console.log(pythonOutput);

        this.previewContent = pythonOutput;
      });
    }); //right now only one file can be written, hardcoded to editor.py
  }

  public formatHTML() {
    this.beautifyService.beautifyHTML(this.code).subscribe((beautifiedCode) => (this.code = beautifiedCode["code"]));
  }

  done() {}
}
