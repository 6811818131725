import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/_helpers/auth.guard";
import { Role } from "@app/_models/role";
import { CompetitionCreateComponent } from "./competition-create/competition-create.component";
import { CompetitionDetailComponent } from "./competition-detail/competition-detail.component";
import { CompetitionShowComponent } from "./competition-show/competition-show.component";
import { CompetitionsComponent } from "./competitions.component";

const routes: Routes = [
  { path: "", component: CompetitionsComponent },
  { path: "create", component: CompetitionCreateComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Teacher] } },
  { path: ":id/edit", component: CompetitionDetailComponent, canActivate: [AuthGuard] },
  { path: ":id/show", component: CompetitionShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompetitionsRoutingModule {}
