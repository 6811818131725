<div class="container-fluid no-gutters p-0 m-0">
  <!-- <div *ngIf="!showResults; else results"> -->

  <div class="row no-gutters m-5">
    <div class="col-sm-12" *ngIf="game && game.quests">
      <app-game-quest [quest]="game.quests[gameInstance.currentQuestIndex]"></app-game-quest>
    </div>
  </div>

  <div class="row no-gutters p-5 bg-light">
    <div class="col-sm-3" *ngIf="amIHost()">
      <!-- <div class="btn btn-small btn-success" (click)="playAudio()" *ngIf="showPlayAudioBtn">Start Audio</div>
      <div class="btn btn-small btn-danger" (click)="stopAudio()" *ngIf="!showPlayAudioBtn">Pause Audio</div> -->
    </div>
    <div class="col-sm-6">
      <div class="text-center text-danger display-4">{{ gameCountdown }}</div>
      <h2 class="text-center text-info">GameCode: {{ gameCode }}</h2>
      <!-- <h3>Time Elapsed since start: {{ timeElapsedSinceQuestStarted() }}</h3>
        <h3>Current Quest start time: {{ gameInstance.currentQuestStartTime }}</h3>
        <h3>Quest time limit: {{ game.quests[gameInstance.currentQuestIndex].timeLimit }}</h3> -->
    </div>
    <div class="col-sm-3"></div>
  </div>

  <!-- HTML, Javascript Editor and Preview  -->
  <div
    class="row no-gutters"
    *ngIf="gameInstance && gameInstance.players[currentPlayerIndex] && getCurrentQuestCodeLanguage() != 'python'"
    style="height: 1050px"
  >
    <div class="col-sm-8">
      <app-game-editor
        [code]="gameInstance.players[currentPlayerIndex].code[gameInstance.currentQuestIndex]"
        [codeLanguage]="game.quests[gameInstance.currentQuestIndex].codeLanguage"
        (userCodeChanged)="onUserCodeChanged($event)"
        (playerDone)="playerDoneWithCurrentQuest()"
        (runPython)="runPython()"
      ></app-game-editor>
    </div>
    <div class="col-sm-4">
      <div class="col-sm-12">
        <div class="row bg-dark">
          <div class="btn btn-small btn-dark text-center text-white col-sm-12">
            {{ gameInstance.players[currentPlayerIndex].name }}'s Preview
          </div>
        </div>
      </div>

      <div class="col-sm-12 p-0" style="height: 1000px">
        <app-code-preview
          [wrapperCode]="game.quests[gameInstance.currentQuestIndex].wrapperCode"
          [code]="gameInstance.players[currentPlayerIndex].code[gameInstance.currentQuestIndex]"
          [codeLanguage]="game.quests[gameInstance.currentQuestIndex].codeLanguage"
        ></app-code-preview>
      </div>
    </div>
  </div>

  <!-- Python Editor and Result  -->
  <div
    class="row no-gutters"
    *ngIf="gameInstance && gameInstance.players[currentPlayerIndex] && getCurrentQuestCodeLanguage() == 'python'"
    style="height: 1050px"
  >
    <div class="col-sm-8">
      <app-game-editor
        [code]="gameInstance.players[currentPlayerIndex].code[gameInstance.currentQuestIndex]"
        [codeLanguage]="game.quests[gameInstance.currentQuestIndex].codeLanguage"
        (userCodeChanged)="onUserCodeChanged($event)"
        (playerDone)="playerDoneWithCurrentQuest()"
        (runPython)="runPython()"
      ></app-game-editor>
    </div>
    <div class="col-sm-4">
      <div class="col-sm-12">
        <div class="row bg-dark">
          <div class="btn btn-small btn-dark text-center text-white col-sm-12">
            {{ gameInstance.players[currentPlayerIndex].name }}'s Python Result
          </div>
        </div>
      </div>

      <div class="col-sm-12 p-0" style="height: 1000px">
        <app-python-result [pythonResult]="pythonResult"></app-python-result>
      </div>
    </div>
  </div>

  <app-game-footer [(gameInstance)]="gameInstance"></app-game-footer>
</div>
