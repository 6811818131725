import { Quest } from "@app/quests/quest.model";
import { Game } from "@app/games/shared/game.model";
import { Course } from "@app/coursework/shared/course.model";

export class Unit {
  id?: string;
  gameName: string;
  courseName: string;
  description: string;
  games?: (string | Game)[];
  courses?: (string | Course)[];

  constructor() {
    this.gameName = "New Unit Game Name";
    this.courseName = "New Unit Course Name";
    this.description = "Unit description";
    this.games = [];
    this.courses = [];
  }
}
