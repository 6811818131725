<div class="card col-12" *ngIf="competition">
  <div class="card-body text-center">
    <div *ngIf="competition.type == competitionType.HOUROFCODE">
      <i class="fas fa-hourglass-half fa-3x mb-2"></i>
    </div>
    <div *ngIf="competition.type == competitionType.CLUB">
      <i class="fas fa-user-friends fa-3x mb-2"></i>
    </div>
    <div *ngIf="competition.type == competitionType.COMPETITION">
      <i class="fas fa-trophy fa-3x mb-2"></i>
    </div>

    <h2 class="card-title">{{ competition.name }}</h2>
    <p class="card-text">{{ competition.description }}</p>

    <!-- assumes that Hour of Code is held once  -->
    <h4 class="text-info" *ngIf="competition.fixtures && competition.type == competitionType.HOUROFCODE">
      <!-- {{ competition.fixtures[0].fixtureTime | date: "EEEE, MMM d, y h:mm a" }} PT -->
      {{ competition.time | date: "EEEE, MMM d, y h:mm a" }} PT
    </h4>

    <!-- assumes that clubs are held on the same day every week -->
    <h4 class="text-info" *ngIf="competition.fixtures && competition.type == competitionType.CLUB">
      <!-- Starting {{ competition.time | date: "MMM d, y" }}, every week, {{ competition.time | date: "EEEE" }}s @
      {{ competition.time | date: "h:mm a" }} PT -->
      Starting {{ competition.time | date: "EEEE, MMM d, y h:mm a" }} PT
      <!-- {{ competition.fixtures[0].fixtureTime | date: "EEEE" }} @
      {{ competition.fixtures[0].fixtureTime | date: "h:mm a" }} from {{ competition.fixtures[0].fixtureTime | date: "MMM d, y " }} to
      {{ competition.fixtures[competition.fixtures.length - 1].fixtureTime | date: "MMM d, y " }} PT -->
    </h4>

    <!-- assumes that Competition is held once  -->
    <h4 class="text-info" *ngIf="competition.fixtures && competition.type == competitionType.COMPETITION">
      {{ competition.time | date: "EEEE, MMM d, y h:mm a" }} PT
    </h4>

    <span *ngIf="true">
      <a [routerLink]="['/competitions', competition.id, 'show']" class="btn btn-success col m-2">Learn more</a>
    </span>

    <span
      *ngIf="
        (account && account.role === Role.Admin) ||
        (account && competition.createdBy == account.id) ||
        (account && competition.host == account.id)
      "
    >
      <a [routerLink]="['.', competition.id, 'edit']" class="btn btn-primary col m-2">Details</a>
    </span>
    <span
      *ngIf="
        (account && account.role === Role.Admin) ||
        (account && competition.createdBy.toString() == account.id.toString()) ||
        (account && competition.host == account.id)
      "
      class="btn btn-danger col m-2"
      (click)="delete(competition)"
    >
      Delete
    </span>

    <span
      *ngIf="
        (account && account.role === Role.Admin) ||
        (account && competition.createdBy.toString() == account.id.toString()) ||
        (account && competition.host == account.id)
      "
    >
      <a href="/competitions/create?copy={{ competition.id }}" class="btn btn-info col m-2">Copy</a>
    </span>
  </div>
</div>
