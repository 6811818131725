<div class="container" *ngIf="unit">
  <div class="row mt-5 text-center">
    <div class="col-sm-12">
      <h2>{{ unit.gameName | uppercase }}</h2>
      <h4>{{ unit.games.length }} - Games</h4>
      <a routerLink="/units/{{ unit.id }}" routerLinkActive="router-link-active">{{ unit.gameName | uppercase }}</a>
    </div>
    <div class="btn btn-small btn-success text-center col-sm-12" (click)="addGameToUnit(null)">New Game</div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-6">
      <div class="btn-group btn-group-toggle text-white" data-toggle="buttons">
        <label class="btn btn-secondary" (click)="displayJSON(true)">
          <input type="radio" name="options" id="option1" autocomplete="off" /> JSON
        </label>
        <label class="btn btn-secondary active" (click)="displayJSON(false)">
          <input type="radio" name="options" id="option2" autocomplete="off" checked /> Form Fields
        </label>
      </div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-danger" (click)="goBack()">Back</div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-primary" (click)="save()">Save Unit</div>
    </div>
  </div>

  <div *ngIf="showJSON">
    <div class="row mt-5">
      <div class="col-sm-12">
        <textarea rows="25" cols="10" class="form-control form-control-lg" (change)="onValueChange($event)">
                {{ unit | json }}
             </textarea
        >
      </div>
    </div>
  </div>

  <div *ngIf="!showJSON">
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Unit Name:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="unit.gameName" name="unitName" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Unit Description:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="unit.description" name="unitDescription" />
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-12">
      <h2 class="col-sm-12 text-center">Note: Save each game individually</h2>
      <div *ngFor="let game of unit.games; let i = index">
        <div id="accordion_unit">
          <div class="card">
            <div class="card-header" id="heading_game_{{ i }}">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse_game_' + i"
                      aria-expanded="true"
                      aria-controls="collapse_game_{{ i }}"
                    >
                      Game #{{ i + 1 }}
                    </button>
                  </h5>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveUp(i)" *ngIf="i != 0">Move Up</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveDown(i)" *ngIf="i != unit.games.length - 1">Move Down</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-danger" (click)="removeGameFromUnit(i)">Remove Game</button>
                </div>
              </div>
            </div>

            <div id="collapse_game_{{ i }}" class="collapse" aria-labelledby="heading_game_{{ i }}" data-parent="#accordion_unit">
              <div class="card-body">
                <app-game-detail [gameId]="game" [game]="null"></app-game-detail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
