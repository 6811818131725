import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CourseService } from "@app/coursework/shared/course.service";
import { Quest } from "@app/quests/quest.model";
import { UnitService } from "@app/units/shared/unit.service";
import { Account, Game, Role } from "@app/_models";
import { AccountGame } from "@app/_models/account-game";
import { AccountService, GameService } from "@app/_services";
import { AccountGameService } from "@app/_services/account-game.service";
import { GameInstanceService } from "../game-instance/game-instance.service";
import { GameInstance } from "../shared/game-instance.model";
import { Player } from "../shared/player.model";

@Component({
  selector: "app-game-show",
  templateUrl: "./game-show.component.html",
  styleUrls: ["./game-show.component.css"],
})
export class GameShowComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private gameService: GameService,
    private unitService: UnitService,
    private courseService: CourseService,
    private accountGameService: AccountGameService,
    private gameInstanceService: GameInstanceService
  ) {}
  game: Game;
  gameInstances: GameInstance[] = [];
  players: Player[] = [];
  playerSpeedScore: number[] = [];
  playerRatingsReceivedScore: number[] = [];
  playerTotalScore: { account: string; name: string; score: number }[] = [];

  quests: Quest[] = [];

  @Input() gameId: string;

  Role = Role;
  account: Account;
  unitName: string;
  unitId: string;
  courseName: string;
  courseId: string;
  accountGames: AccountGame[] = [];

  ngOnInit(): void {
    this.accountService.account.subscribe((x) => (this.account = x));

    this.getGameWithQuests();
    this.getAccountGameData();
  }

  getGameWithQuests(): void {
    const id = this.gameId ? this.gameId : this.route.snapshot.paramMap.get("id");

    this.gameService.getGameWithQuests(id).subscribe((game) => {
      this.game = game;

      this.quests = <Quest[]>(<unknown>this.game.quests);

      this.getBreadcrumbs();
    });
  }

  getAccountGameData() {
    const id = this.gameId ? this.gameId : this.route.snapshot.paramMap.get("id");
    this.accountGameService.getByGameId(id).subscribe((accountGames) => {
      this.accountGames = accountGames;

      this.accountGames.sort((a, b) => b.gameData[0].bestScore - a.gameData[0].bestScore);
    });
  }

  delete(game: Game): void {
    this.gameService.deleteGame(game).subscribe(
      (response) => {
        this.game = null;
        console.log("response received" + response);
      },
      (error) => {
        //Error callback
        console.log("error caught in component" + error);
      }
    );
  }

  getBreadcrumbs(): void {
    this.unitService.getUnit(<string>this.game.units[0]).subscribe((unit) => {
      this.courseService.getCourse(<string>unit.courses[0]).subscribe((course) => {
        this.unitName = unit.gameName;
        this.unitId = unit.id;
        this.courseName = course.name;
        this.courseId = course.id;
      });
    });
  }
}
