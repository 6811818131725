<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <h3>GameCode: {{ gameInstance.gameCode }}</h3>
    </div>

    <div class="col-sm-12">
      <h3>Current players:</h3>
      <div *ngFor="let player of gameInstance.players">
        <div *ngIf="player.accountId">
          ID - {{ player.accountId }} - {{ player.code }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 mt-5">
      <h4 class="mb-5 font-weight-bold text-center bg-info">Code Editor</h4>

      <div
        class="ace w-100"
        [ace]="config"
        (valueChange)="onValueChange($event)"
        (change)="onContentChange($event)"
        style="height: 500px"
      ></div>
    </div>
  </div>
</div>
