import { Unit } from "../../units/shared/unit.model";

export class Course {
  id?: string;
  name: string;
  description: string;
  units?: (string | Unit)[];
}

export class CoursePopulated {
  id?: string;
  name: string;
  description: string;
  units?: Unit[];
}
