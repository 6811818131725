import { Component, Input, OnInit } from "@angular/core";
import { Fixture } from "../shared/fixture.model";
import { FixtureService } from "../shared/fixture.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { AccountService, AlertService, GameService } from "@app/_services";
import { Stage } from "../shared/stage.enum";
import { Competition } from "../shared/competition.model";
import { ArrayService } from "@app/_services/array.service";
import { GameInstanceService } from "@app/games/game-instance/game-instance.service";
import { Game } from "@app/_models";
import { GameInstance } from "@app/games/shared/game-instance.model";

@Component({
  selector: "app-fixture-detail",
  templateUrl: "./fixture-detail.component.html",
  styleUrls: ["./fixture-detail.component.css"],
})
export class FixtureDetailComponent implements OnInit {
  @Input() fixtureId: string;
  @Input() competition: Competition;

  populatedGameInstance: GameInstance;
  fixture: Fixture;
  showJSON: boolean = false;
  stage = Stage;
  availableGames: Game[];

  constructor(
    private route: ActivatedRoute,
    private arrayService: ArrayService,
    private alertService: AlertService,
    private location: Location,
    private fixtureService: FixtureService,
    private gameInstanceService: GameInstanceService,
    private accountService: AccountService,
    private gameService: GameService
  ) {}

  ngOnInit(): void {
    this.fixtureService.getFixture(this.fixtureId).subscribe((fixture) => {
      this.fixture = fixture;
      this.getPopulatedGameInstance();
    });
    this.getAvailableGames();
  }

  updateFixtureDate(value): void {
    this.fixture.fixtureTime = new Date(value);
  }
  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.fixtureService.updateFixture(this.fixture).subscribe((fixture) => {
      this.fixture = fixture;
      this.alertService.success("Update fixture successful");
    });
  }

  onValueChange(event): void {
    this.fixture = JSON.parse(event.target.value);
  }

  displayJSON(val: boolean) {
    this.showJSON = val;
  }

  addPlayer(accountId: string) {
    this.fixture.qualifiedPlayers.push(accountId);
    this.fixtureService.updateFixture(this.fixture).subscribe((fixture) => (this.fixture = fixture));
  }

  removePlayer(accountId: string) {
    let index = 0;
    for (const player of this.fixture.qualifiedPlayers) {
      if (player == accountId) {
        this.fixture.qualifiedPlayers.splice(index, 1);
        this.fixtureService.updateFixture(this.fixture).subscribe((fixture) => (this.fixture = fixture));
        return;
      }
      index++;
    }
  }

  isPlayerAlreadyAdded(accountId: string) {
    return this.arrayService.exists(accountId, this.fixture.qualifiedPlayers);
  }

  addGame(gameId: string) {
    //Remove existing game and gameInstance if they exist
    if (this.fixture.game) {
      this.removeGame();
    }
    this.fixture.game = gameId;
    let gameInstance = {
      name: "GI" + gameId,
      game: gameId,
      players: [
        {
          account: this.accountService.accountValue.id,
          name: this.accountService.accountValue.nickName,
        },
      ],
    };
    this.gameInstanceService.createGameInstance(gameInstance).subscribe((gameInstance) => {
      this.fixture.gameInstance = gameInstance.id;
      this.fixtureService.updateFixture(this.fixture).subscribe((fixture) => (this.fixture = fixture));
      this.getPopulatedGameInstance();
    });
  }

  addPracticeGame(gameId: string) {
    this.fixture.practiceGame = gameId;
    this.fixtureService.updateFixture(this.fixture).subscribe((fixture) => (this.fixture = fixture));
  }

  removeGame() {
    this.fixture.game = null;

    this.gameInstanceService.deleteGameInstance(this.fixture.gameInstance).subscribe((message) => console.log(message.message));
    this.fixture.gameInstance = null;

    this.fixtureService.updateFixture(this.fixture).subscribe((fixture) => (this.fixture = fixture));
  }

  removePracticeGame() {
    this.fixture.practiceGame = null;

    this.fixtureService.updateFixture(this.fixture).subscribe((fixture) => (this.fixture = fixture));
  }

  getAvailableGames() {
    this.gameService.getGames().subscribe((games) => (this.availableGames = games));
  }

  getPopulatedGameInstance() {
    if (this.fixture.gameInstance) {
      this.gameInstanceService
        .getGameInstance(this.fixture.gameInstance.toString())
        .subscribe((gameInstance) => (this.populatedGameInstance = gameInstance));
    }
  }
}
