<div class="container-fluid no-gutters p-0 m-0">
  <div class="row">
    <div class="col-sm-12 bg-dark text-right">
      <div class="btn btn-dark btn-small" [cdkCopyToClipboard]="CodeHelperLinks.bootstrap" *ngIf="codeLanguage == 'html'">
        Copy Bootstrap Link
      </div>
      <div class="btn btn-dark btn-small" [cdkCopyToClipboard]="CodeHelperLinks.jQuery" *ngIf="codeLanguage == 'html'">
        Copy jQuery Link
      </div>
      <div class="btn btn-dark btn-small" [cdkCopyToClipboard]="CodeHelperLinks.canvasHelper" *ngIf="codeLanguage == 'html'">
        Copy Canvas Helper Link
      </div>
      <div class="btn btn-success btn-small" (click)="takeAction('formatHTML')" *ngIf="codeLanguage == 'html'">Beautify</div>

      <div class="btn btn-success btn-small mr-2" (click)="takeAction('runPython')" *ngIf="codeLanguage == 'python'">Run</div>

      <div class="btn btn-primary btn-small mr-2" (click)="takeAction('done')">Done</div>
    </div>
  </div>
</div>
