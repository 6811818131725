import { Injectable } from "@angular/core";
import { Player } from "./player.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable, throwError, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { MessageService } from "@app/_services/message.service";

@Injectable({
  providedIn: "root",
})
export class PlayerService {
  constructor(private http: HttpClient, private messageService: MessageService) {}

  baseUrl = `${environment.apiUrl}/players`;

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a GameService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`GameService: ${message}`);
  }
}
