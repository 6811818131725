import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AceConfigInterface, AceDirective } from "ngx-ace-wrapper";

import "brace";
import "brace/mode/html";
import "brace/mode/javascript";
import "brace/mode/python";
import "brace/mode/json";
import "brace/theme/twilight";
import "brace/ext/language_tools";
import "brace/snippets/html"; //If snippets stop working, bring this file locally or import it from brace directory
import "brace/snippets/javascript";
import "brace/snippets/json";
import "brace/snippets/python";

@Component({
  selector: "app-code-editor",
  templateUrl: "./code-editor.component.html",
  styleUrls: ["./code-editor.component.css"],
})
export class CodeEditorComponent implements OnInit, AfterViewInit, OnChanges {
  config: AceConfigInterface = {
    mode: "html",
    theme: "twilight",
    readOnly: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    fontFamily: "Inconsolata, Monaco, Consolas, 'Courier New', Courier;",
    fontSize: "12pt",
    tabSize: 4,
    showLineNumbers: false,
  };
  // editorType: string = "directive";
  @Input() code: string;
  @Input() codeLanguage: string;
  @ViewChild(AceDirective) editor: AceDirective;
  editorTimer: any;

  @Output() userCodeChanged = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    //wait for editor to be created. Number 1000 is arbitrary at this point
    this.editorTimer = setInterval(() => {
      if (this.editor) {
        // this.userCode = this.code;

        // encodeURI is now used beefore sending the code to server from client, as otheerwise tabs were stripped out of python code
        if (this.code) {
          this.editor.ace().setValue(decodeURI(this.code));
        }

        if (this.codeLanguage) {
          this.editor.ace().setOptions({
            mode: "ace/mode/" + this.codeLanguage,
          });
        }
        clearInterval(this.editorTimer);
      } else {
        console.log("could not initialize editor's values, will be set to defaults");
      }
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  /* Ace editor helper functions */
  public onValueChange(value: string): void {
    console.log("Value change:", value);
  }

  public onContentChange(event: any): void {
    if (this.editor) {
      this.emitUserCode();
    }
  }

  private emitUserCode() {
    // this.userCode = this.editor.ace().getValue();
    // encodeURI is now used before sending the code to server from client, as otherwise tabs were stripped out of python code
    this.userCodeChanged.emit(this.editor.ace().getValue());
  }
}
