<div class="col-sm-12 m-0 p-0" style="height: 100%" *ngIf="codeLanguage == 'html' || codeLanguage == 'javascript'">
  <iframe #previewIframe frameborder="1" style="height: 100%; width: 100%"></iframe>
</div>

<div class="col-sm-12 m-0" style="height: 100%; width: 100%" *ngIf="codeLanguage == 'python'">
  <pre class="p-2 text-danger"
    >{{ code }}
    </pre
  >
</div>
