<div class="container">
  <div class="row d-flex justify-content-center">
    <div class="col-lg-6 mb-5 text-center text-dark d-flex align-items-stretch" *ngFor="let unit of units">
      <div class="card col-12 m-5">
        <!-- <img src="..." class="card-img-top" alt="..." /> -->
        <div class="card-body text-center">
          <h2 class="card-title">{{ unit.gameName }} | {{ unit.techName }}</h2>
          <p class="card-text">{{ unit.description }}</p>
          <a routerLink="/coursework/units/{{ unit.id }}" class="btn btn-primary col-3 m-2">Practice</a>
          <a routerLink="/games" class="btn btn-danger col-3 m-2">Play</a>
        </div>
      </div>
    </div>
  </div>
</div>
