import { AccountService } from "@app/_services";

export function appInitializer(accountService: AccountService) {
  //TODO - Don't refresh token for all pages, as some pages need to be available outside of authenticated areas
  return () =>
    new Promise((resolve) => {
      // attempt to refresh token on app start up to auto authenticate
      accountService.refreshToken().subscribe().add(resolve);
    });
}
