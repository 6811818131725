import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CompetitionService } from "@app/competitions/shared/competition.service";
import { GameInstanceService } from "@app/games/game-instance/game-instance.service";
import { AccountService } from "@app/_services/account.service";
import { MessageService } from "@app/_services/message.service";
import { Competition } from "../shared/competition.model";
import { FixtureService } from "../shared/fixture.service";

@Component({
  selector: "app-competition-create",
  templateUrl: "./competition-create.component.html",
  styleUrls: ["./competition-create.component.css"],
})
export class CompetitionCreateComponent implements OnInit {
  constructor(
    private competitionService: CompetitionService,
    private gameInstanceService: GameInstanceService,
    private fixtureService: FixtureService,
    private messageService: MessageService,
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  message: string;

  ngOnInit(): void {
    if (this.route.snapshot.queryParams["copy"]) {
      this.copy(this.route.snapshot.queryParams["copy"]);
    }
  }

  add(): void {
    let newCompetition = new Competition();
    newCompetition.createdBy = this.accountService.accountValue.id;
    this.competitionService.addCompetition(newCompetition).subscribe((competition) => {
      this.message = "Competition created successfully";
      this.router.navigate([`/competitions/${competition.id}/edit`]);
    });
  }

  //copy competition, its fixtures, and create game instances of fixtures' games
  copy(competitionId: string) {
    this.competitionService.getCompetition(competitionId).subscribe((competition) => {
      delete competition.id;
      competition.registeredPlayers = [];
      competition.host = this.accountService.accountValue.id;

      let oldFixtures = competition.fixtures;
      competition.fixtures = [];

      this.competitionService.addCompetition(competition).subscribe((c) => {
        this.message = "Competition created successfully";

        for (let index = 0; index < oldFixtures.length; index++) {
          let fixture = oldFixtures[index];
          this.fixtureService.getFixture(fixture.toString()).subscribe((competitionFixture) => {
            delete competitionFixture.id;
            this.competitionService.addFixture(c.id, competitionFixture).subscribe((f) => {
              if (f.game) {
                let gameInstance = {
                  name: "GI" + f.game,
                  game: f.game,
                  players: [
                    {
                      account: this.accountService.accountValue.id,
                      name: this.accountService.accountValue.nickName,
                    },
                  ],
                };
                this.gameInstanceService.createGameInstance(gameInstance).subscribe((gameInstance) => {
                  f.gameInstance = gameInstance.id;
                  this.fixtureService.updateFixture(f).subscribe((updatedFixture) => this.message + "Added fixture and gameInstance");
                });
              }

              this.router.navigate([`/competitions/${c.id}/edit`]);
            });
          });
        }
      });
    });
  }
}
