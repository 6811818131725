import { Component, Input, OnInit } from "@angular/core";
import { AccountService, AlertService, GameService } from "@app/_services";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { ArrayService } from "@app/_services/array.service";
import { Competition } from "../shared/competition.model";
import { Fixture } from "../shared/fixture.model";
import { Account, Game, Role } from "@app/_models";
import { CompetitionService } from "../shared/competition.service";
import { FixtureService } from "../shared/fixture.service";
import { PriceDuration } from "@app/_models/priceDuration";

@Component({
  selector: "app-competition-detail",
  templateUrl: "./competition-detail.component.html",
  styleUrls: ["./competition-detail.component.css"],
})
export class CompetitionDetailComponent implements OnInit {
  account: Account;
  Role = Role;
  competition: Competition;
  @Input() competitionId: string;
  showJSON: boolean = false;
  teacherAccounts: Account[];
  PriceDuration = PriceDuration;

  constructor(
    private route: ActivatedRoute,
    private competitionService: CompetitionService,
    private fixtureService: FixtureService,
    private location: Location,
    private accountService: AccountService,
    private arrayService: ArrayService,
    private alertService: AlertService
  ) {
    this.accountService.account.subscribe((x) => (this.account = x));
  }

  ngOnInit(): void {
    this.getCompetition();
    this.getAccountsByRole();
  }

  getCompetition(): void {
    const id = this.competitionId ? this.competitionId : this.route.snapshot.paramMap.get("id");

    if (id) {
      this.competitionService.getCompetition(id).subscribe((competition) => {
        this.competition = competition;
      });
    }
  }

  getAccountsByRole(): void {
    this.accountService.getAllByRole(["Teacher"]).subscribe((teacherAccounts) => {
      this.teacherAccounts = teacherAccounts;
    });
  }

  addFixtureToCompetition(): void {
    let newFixture = new Fixture();

    this.competitionService.addFixture(this.competition.id, newFixture).subscribe((fixture) => {
      this.getCompetition(); //get the competition object again so that you have the new fixture
    });
  }

  removeFixtureFromCompetition(i: number) {
    this.competitionService.removeFixture(this.competition.id, this.competition.fixtures[i].toString()).subscribe(() => {
      // const removedFixtureIndex = this.competitions.findIndex((item) => item.id == competition.id);
      this.competition.fixtures.splice(i, 1);
    });
    // this.gameService.removeCompetitionFromGame(this.competition.games[i].toString(), this.competition.id).subscribe((game) => {});
  }

  updateCompetitionDate(value): void {
    this.competition.time = new Date(value);
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.competitionService.updateCompetition(this.competition).subscribe((competition) => {
      this.competition = competition;
      this.alertService.success("Update competition successful");
    });
  }

  onValueChange(event): void {
    this.competition = JSON.parse(event.target.value);
  }

  displayJSON(val: boolean) {
    this.showJSON = val;
  }

  moveUp(i: number) {
    this.competition.fixtures = this.arrayService.moveUp(i, this.competition.fixtures);
  }

  moveDown(i: number) {
    this.competition.fixtures = this.arrayService.moveDown(i, this.competition.fixtures);
  }

  addPriceOption(duration: PriceDuration): void {
    this.competition.price.push({ value: 0, duration: duration, comments: "" });
  }

  removePriceOption(index: number): void {
    // var index = this.competition.price.indexOf(price);
    if (index !== -1) {
      this.competition.price.splice(index, 1);
    }
  }
}
