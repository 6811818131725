import { GameInstance } from "@app/games/shared/game-instance.model";
import { Account, Game } from "@app/_models";
import { Stage } from "./stage.enum";
import { PaymentStatus } from "./paymentStatus.enum";
import { Fixture } from "./fixture.model";
import { PriceDuration } from "@app/_models/priceDuration";

export class Competition {
  id?: string;
  name: string;
  description: string;
  type: string;
  time: Date;
  registrationRequired: boolean;
  location: string;
  host: string | Account;
  hidden: boolean;
  //players: (string | Account)[];
  registeredPlayers: {
    account: string | Account;
    highestQualificationStage: Stage;
    paymentStatus: PaymentStatus;
    totalScore: number;
    referralCode: string;
    discount: number;
  }[];
  // games?: (string | Game)[];
  fixtures: (Fixture | string)[];
  price: [{ value: number; duration?: PriceDuration; comments?: string }];
  createdBy?: string | Account;

  constructor(id?: string) {
    if (id) {
      this.id = id;
    } else {
      this.name = "New competition";
      this.description = "Competition description";
      //this.players = [];
      // this.games = [];
      this.price = [{ value: 10, duration: PriceDuration.ONETIME }];
    }
  }
}
