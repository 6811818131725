import { Account } from "../../_models/account";
import { QuestStatus } from "../../quests/questStatus.model";
import { PlayerStatus } from "./playerStatus.model";

export class Player {
  _id?: string;
  account?: Account | string;
  name?: string;
  playerStatus?: PlayerStatus;
  code?: string[];
  score?: number[];
  ratingsReceivedScore?: number[];
  peerRating?: number[][]; //two dimensional array - 1st - questIndex, second peer scores'
  questStatus?: QuestStatus[];
  questDuration?: number[];

  constructor(
    _id: string,
    account?: Account | string,
    name?: string,
    code?: string[],
    score?: number[],
    ratingsReceivedScore?: number[],
    questStatus?: QuestStatus[],
    questDuration?: number[]
  ) {
    this._id = _id;
    if (account instanceof Account) this.account = new Account(account);
    else this.account = account;
    this.name = name;
    this.code = code;
    this.score = score;
    this.ratingsReceivedScore = ratingsReceivedScore;
    this.questStatus = questStatus;
    this.questDuration = questDuration;
  }
}
