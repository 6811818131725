import { Component, OnInit, OnDestroy } from "@angular/core";
import { Game } from "../shared/game.model";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, RouterStateSnapshot } from "@angular/router";
import { GameService } from "../shared/game.service";
import { GameInstance } from "../shared/game-instance.model";
import { AccountService, AlertService, ChatService } from "@app/_services";
import { GameInstanceService } from "../game-instance/game-instance.service";
import { Router } from "@angular/router";
import { Player } from "../shared/player.model";
import { Observable, Subscription } from "rxjs";
import { GameInstanceStatus } from "../shared/game-instance-status.model";
import { environment } from "@environments/environment";
import { AccountGameService } from "@app/_services/account-game.service";
import { GameData } from "@app/_models/gameData";

@Component({
  selector: "app-game-lobby",
  templateUrl: "./game-lobby.component.html",
  styleUrls: ["./game-lobby.component.css"],
})
export class GameLobbyComponent implements OnInit, OnDestroy {
  endGameMessage: Subscription;
  game: Game;
  gameInstance: GameInstance;
  joinMessage: Subscription;
  leaveMessage: Subscription;
  players: Player[] = [];
  playMessage: Subscription;
  canDeactivateSynchronously: boolean = false;
  gameInstanceUrl: string;
  copied = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private gameService: GameService,
    private accountService: AccountService,
    private gameInstanceService: GameInstanceService,
    private alertService: AlertService,
    private accountGameService: AccountGameService
  ) {}

  ngOnInit(): void {
    console.log("Initialize game-lobby component");
    const gameId = this.route.snapshot.paramMap.get("id");
    const gameCode = +this.route.snapshot.paramMap.get("gameCode");

    if (gameCode) {
      this.gameInstanceUrl = `${environment.baseUrl}/games/lobby/gameCode/${gameCode}`;
      //Joining an existing game instance that someone is hosting
      this.gameInstance = {
        gameCode: +gameCode,
        players: [
          {
            account: this.accountService.accountValue.id,
            name: this.accountService.accountValue.nickName,
          },
        ],
      };

      this.gameInstanceService.addPlayer(this.gameInstance).subscribe((gameInstance) => {
        if (!gameInstance) {
          console.log("Empty gameInstance received");
        }
        this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
        const obj = {
          gameInstance: {
            gameCode: this.gameInstance.gameCode,
          },
          accountId: this.accountService.accountValue.id,
          nickName: this.accountService.accountValue.nickName,
        };
        //Join the gameroom socket, Let other player know that you have joined
        this.gameInstanceService.sendGameMesssage("join", obj);

        // Get game information for display (TODO - May be able to remove this call by using the game object inside gameInstance)
        this.gameService.getGame(gameInstance.game.toString()).subscribe((game) => {
          this.game = game;
          //note that the user started to play this game
          this.recordGameData();
        });

        if (this.gameInstance.status == GameInstanceStatus.QUEST_IN_PROGRESS) {
          //Jump straight to the game without waiting for other players or start signal
          this.canDeactivateSynchronously = true;
          this.router.navigate([`/games/gamePlay/${this.gameInstance.gameCode}`]);
          return;
        }
        if (
          this.gameInstance.status == GameInstanceStatus.QUEST_AT_LEADERBOARD ||
          this.gameInstance.status == GameInstanceStatus.FINISHED
        ) {
          //Jump straight to the game without waiting for other players or start signal
          this.canDeactivateSynchronously = true;
          this.router.navigate([`/games/leaderboard/${this.gameInstance.gameCode}`]);
          return;
        }
      });
    } else {
      if (gameId) {
        //Hosting a new game
        this.gameInstance = {
          name: "GI" + gameId,
          game: gameId,
          hostAccount: this.accountService.accountValue.id,
          hostPlaying: this.route.snapshot.queryParams["hostPlaying"] || "true",
          peerRatingAllowed: this.route.snapshot.queryParams["peerRatingAllowed"] || "false",
          players: [
            {
              account: this.accountService.accountValue.id,
              name: this.accountService.accountValue.nickName,
            },
          ],
        };

        //addGameInstance does not return complete gameInstance object with gameCode, so fetch it again inside subscribe
        this.gameInstanceService.createGameInstance(this.gameInstance).subscribe((gameInstance) => {
          this.gameInstanceService.getGameInstance(gameInstance.id).subscribe((gameInstance) => {
            if (!gameInstance) {
              console.log("Empty gameInstance received");
            }
            this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
            this.gameInstanceUrl = `${environment.baseUrl}/games/lobby/gameCode/${this.gameInstance.gameCode}`;
            const obj = {
              gameInstance: {
                gameCode: this.gameInstance.gameCode,
              },
              accountId: this.accountService.accountValue.id,
              nickName: this.accountService.accountValue.nickName,
            };
            //Join the gameroom socket, Let other player know that you have joined
            this.gameInstanceService.sendGameMesssage("join", obj);
            // this.sendMessage("join");
          });
        });

        //Get game information for display (TODO - May be able to remove this call by using the game object inside gameInstance)
        this.gameService.getGame(gameId).subscribe((game) => {
          this.game = game;
          //note that the user started to play this game
          this.recordGameData();
        });
      } else {
        //Came to dummy lobby
      }
    }

    {
      //Subsribe to messages about new players
      this.joinMessage = this.gameInstanceService.getJoinMessage().subscribe((messageObj: any) => {
        console.log(
          "Join message (lobby) received message: " + messageObj.accountId + " currentQuestIndex: " + messageObj.currentQuestIndex
        );
        //New player joined, get updated list of players
        // this.gameInstanceService.getGameInstanceByGameCode(this.gameInstance.gameCode).subscribe((gameInstance) => {
        //   if (!gameInstance) {
        //     console.log("Empty gameInstance received");
        //   }
        //   this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
        //   // this.getAllPlayers(this.gameInstance.players);
        // });
        this.gameInstance = messageObj.gameInstance;
      });

      //Subsribe to messages about player leaving
      this.leaveMessage = this.gameInstanceService.getLeaveMessage().subscribe((messageObj: any) => {
        console.log("getLeaveMessage received message: " + messageObj.accountId + " currentQuestIndex: " + messageObj.currentQuestIndex);
        //Player left, get updated list of players
        this.gameInstance = messageObj.gameInstance ? messageObj.gameInstance : this.gameInstance;
        // this.gameInstanceService.getGameInstanceByGameCode(this.gameInstance.gameCode).subscribe((gameInstance) => {
        //   if (!gameInstance) {
        //     console.log("Empty gameInstance received");
        //   } else {
        //     console.log(gameInstance);
        //   }
        //   this.gameInstance = gameInstance ? gameInstance : this.gameInstance;

        //   // this.getAllPlayers(this.gameInstance.players);
        // });
      });

      //Subsribe to start message
      this.playMessage = this.gameInstanceService.getPlayMessage().subscribe((messageObj: any) => {
        console.log("getStartMessage received message: currentQuestIndex: " + messageObj.currentQuestIndex);
        this.canDeactivateSynchronously = true;
        //Play signal received. Move to play screen with first or next quest
        //TODO: Look for ways to optimize this route
        this.router.navigate([`/games/gamePlay/${this.gameInstance.gameCode}`]);
      });

      //Subsribe to end message
      this.endGameMessage = this.gameInstanceService.getEndGameMessage().subscribe((messageObj: any) => {
        console.log("getEndGameMessage received message: currentQuestIndex: " + messageObj.currentQuestIndex);
        this.canDeactivateSynchronously = true;
        //TODO: Look for ways to optimize this route
        this.router.navigate([`/games`]);
      });
    }
  }

  ngOnDestroy() {
    console.log("Unsubscribing to the socket io messages");
    this.playMessage.unsubscribe();
    this.joinMessage.unsubscribe();
    this.leaveMessage.unsubscribe();
    this.endGameMessage.unsubscribe();
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if game has finished
    if (this.canDeactivateSynchronously) {
      return true;
    }

    const confirmDialog$ = this.alertService.confirmDialog("Do you really want to quit the game?");
    confirmDialog$.subscribe((dialogReturn) => {
      console.log("return value: " + dialogReturn);
      if (dialogReturn) {
        this.gameInstanceService.leaveGame(this.gameInstance);
        // .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));
      }
    });
    // Otherwise ask the user with the dialog service and return its observable which resolves to true or false when the user decides
    return confirmDialog$;
  }

  public startGame(): void {
    this.gameInstanceService
      .startGame(this.gameInstance)
      .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));
  }

  public endGame(): void {
    this.gameInstanceService
      .endGame(this.gameInstance)
      .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));
  }

  /**
   * controlPanel
   */
  public controlPanel() {
    this.router.navigate(["/games/gameInstance/controlPanel/" + this.gameInstance.gameCode]);
  }

  public leaveGame(): void {
    // this.gameInstanceService.leaveGame(this.gameInstance);
    // .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));

    this.router.navigate([`/games`]);
  }

  public amIHost(): boolean {
    if (this.gameInstance.hostAccount) {
      if (this.gameInstance.hostAccount.toString() == this.accountService.accountValue.id.toString()) {
        return true;
      }
    }

    return false;
  }

  private getAllPlayers(players: Array<Player>) {
    this.players = [];
    for (let index = 0; index < players.length; index++) {
      const element = this.gameInstance.players[index].account;
      const player: Player = new Player(this.gameInstance.players[index]._id, element, this.accountService.accountValue.firstName);
      this.accountService.getById(player.account.toString()).subscribe((account) => {
        player.account = account;
        this.players.push(player);
      });
    }
  }

  onHostPlayingChangeEvent(e: any) {
    console.log("Host playing " + this.gameInstance.hostPlaying);
    let localGameInstance: GameInstance = { id: this.gameInstance.id, hostPlaying: e.target.checked };
    this.gameInstanceService.updateGameInstance(localGameInstance).subscribe((gameInstance) => {
      this.gameInstance = gameInstance;
      this.alertService.success("Update gameInstance successful");
    });
  }

  private recordGameData() {
    let gameData: GameData = {
      game: this.game.id,
      unit: <string>this.game.units[0],
      percentFinished: 0,
      bestScore: 0,
    };
    this.accountGameService.updateGameData(this.accountService.accountValue.id, gameData).subscribe((message) => console.log(message));
  }
}
