import { Component, OnInit, Input } from "@angular/core";
import { Game } from "@app/_models";
import { Goal } from "@app/_models/goal";
import { GoalService } from "@app/goals/shared/goal.service";
import { FormControl } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { GameService } from "../shared/game.service";
import { GameEditorComponent } from "../game-editor/game-editor.component";
import { Quest } from "../../quests/quest.model";
import { MessageService } from "@app/_services/message.service";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { AccountService } from "@app/_services";

@Component({
  selector: "app-game-create",
  templateUrl: "./game-create.component.html",
  styleUrls: ["./game-create.component.css"],
})
export class GameCreateComponent implements OnInit {
  constructor(
    private gameService: GameService,
    private messageService: MessageService,
    private accountService: AccountService,
    private router: Router
  ) {}

  message: string;

  ngOnInit(): void {}

  add(): void {
    let newGame = new Game();
    newGame.createdBy = this.accountService.accountValue.id;
    this.gameService.addGame(newGame).subscribe((game) => {
      this.message = "Game created successfully";
      this.router.navigate([`/games/${game.id}/edit`]);
    });
  }
}
