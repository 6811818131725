import { Observable, of, throwError } from "rxjs";
import { delay, mergeMap, retryWhen } from "rxjs/operators";

const getErrorMessage = (maxRetry: number) => `Tried reload ${maxRetry} times, giving up`;

const DEFAULT_MAX_TRIES = 5;
const DEFAULT_BACKOFF = 3000;

export function retryWithBackoff(delayMs: number, maxRetry = DEFAULT_MAX_TRIES, backoffMs = DEFAULT_BACKOFF) {
  let retries = maxRetry;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          mergeMap((error) => {
            console.log("remaining retries: " + retries);
            if (retries-- > 0) {
              const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
              return of(error).pipe(delay(backoffTime));
            }
            return throwError(getErrorMessage(maxRetry));
          })
        )
      )
    );
}
