import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable, of, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Fixture } from "./fixture.model";
import { tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FixtureService {
  baseUrl = `${environment.apiUrl}/fixtures`;

  constructor(private http: HttpClient) {}

  getFixtures(): Observable<Fixture[]> {
    return this.http.get<Fixture[]>(this.baseUrl).pipe(
      tap((_) => this.log("fetched fixtures")),
      catchError(this.handleError<Fixture[]>("getFixtures", []))
    );
  }

  getFixture(id: string): Observable<Fixture> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Fixture>(url).pipe(
      tap((_) => this.log("fetched goal with id: `${id}`")),
      catchError(this.handleError<Fixture>("getFixture"))
    );
  }

  // competition.service addFixture is used instead of this
  // addFixture(fixture: Fixture): Observable<Fixture> {
  //   const url = `${this.baseUrl}/create`;
  //   return this.http.post<Fixture>(url, fixture).pipe(
  //     tap((newFixture: Fixture) => this.log(`added fixture w/ id=${newFixture.id}`)),
  //     catchError(this.handleError<Fixture>("addFixture"))
  //   );
  // }

  // competition.service removeFixture is used instead of this
  // deleteFixture(fixture: Fixture | string): Observable<Fixture> {
  //   const id = typeof fixture === "string" ? fixture : fixture.id;
  //   const url = `${this.baseUrl}/${id}`;

  //   return this.http.delete<Fixture>(url).pipe(
  //     tap((_) => this.log(`deleted fixture id=${id}`)),
  //     catchError(this.handleError<Fixture>("deleteFixture"))
  //   );
  // }

  updateFixture(fixture: Fixture): Observable<Fixture> {
    const id = typeof fixture === "string" ? fixture : fixture.id;
    const url = `${this.baseUrl}/${id}`;
    this.log(url);
    return this.http.put<Fixture>(url, fixture).pipe(
      tap((_) => this.log("updated fixture")),
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);

        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  removeGameFromFixture(fixtureId: string, gameId: string): Observable<Fixture> {
    const url = `${this.baseUrl}/${fixtureId}/games/${gameId}`;

    return this.http.delete<Fixture>(url).pipe(
      tap((_) => this.log(`removed game id=${gameId} from fixture`)),
      catchError(this.handleError<Fixture>("removeGameFromFixture"))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    // RAVI - TODO: Implement messageService from heroes tutorial
    // this.messageService.add(`HeroService: ${message}`);
  }
}
