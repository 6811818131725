import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ArrayService {
  constructor() {}

  moveUp(i: number, arr: Array<any>): Array<any> {
    arr.splice(i - 1, 0, arr.splice(i, 1)[0].toString());

    return arr;
  }

  moveDown(i: number, arr: Array<any>): Array<any> {
    arr.splice(i + 1, 0, arr.splice(i, 1)[0].toString());

    return arr;
  }

  removeItem(item: any, arr: Array<any>): Array<any> {
    var index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  exists(item: any, arr: Array<any>): boolean {
    var index = arr.indexOf(item);
    if (index !== -1) {
      return true;
    }
    return false;
  }
}
