import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { QuestStatus } from "@app/quests/questStatus.model";
import { Alert } from "@app/_models";
import { AccountService, AlertService, GameService } from "@app/_services";
import { Observable, Subscription } from "rxjs";
import { GameInstanceService } from "../game-instance/game-instance.service";
import { GameInstanceStatus } from "../shared/game-instance-status.model";
import { GameInstance } from "../shared/game-instance.model";
import { Game } from "../shared/game.model";
import { Player } from "../shared/player.model";

@Component({
  selector: "app-game-live-preview",
  templateUrl: "./game-live-preview.component.html",
  styleUrls: ["./game-live-preview.component.css"],
})
export class GameLivePreviewComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private gameInstanceService: GameInstanceService,
    private gameService: GameService,
    private accountService: AccountService,
    private alertService: AlertService
  ) {}

  account = this.accountService.accountValue;
  currentPlayerIndex: number;
  gameInstance: GameInstance;
  game: Game;
  gameCode: number;
  playerRatingStatus: boolean[] = [];
  questCompleteStatus: boolean[] = [];
  resultMessage: Subscription;
  leaveMessage: Subscription;
  joinMessage: Subscription;
  reviewedSelfCode: boolean = false;

  ngOnInit(): void {
    this.gameCode = +this.route.snapshot.paramMap.get("gameCode");

    this.gameInstanceService.getGameInstanceByGameCode(this.gameCode).subscribe((gameInstance) => {
      this.gameInstance = gameInstance;

      this.gameService.getGameWithQuests(this.gameInstance.game.toString()).subscribe((game) => {
        this.game = game;
      });

      this.findCurrentPlayerIndex();

      this.reviewedSelfCode = this.isQuestCompleteForPlayer(this.gameInstance.players[this.currentPlayerIndex]); //this.isAnswerReviewedByPlayer(this.gameInstance.players[this.currentPlayerIndex]); //
      if (!this.reviewedSelfCode) {
        if (this.amIHost() && !this.gameInstance.hostPlaying) {
          //Host, who is not playing has no code to review
          this.setAnswerReviewed();
        }
      }
      //if I am not host, and host is not playing, then I give the host dummy rating. This is required so that all ratings are done and quest can move to leaderboard
      if (!this.amIHost() && !this.gameInstance.hostPlaying) {
        this.peerScore(gameInstance.hostAccount, 1);
      }

      //Handle the case when the page is reloaded. In this case, update the questCompleteStatus and playerRatingStatus, and navigate to leaderboard if needed
      this.setPlayerRatingStatus();
      this.setQuestCompleteStatusForAllPlayers();
      // TODO - Do we really need to check if isQuestCompleteForAllPlayers? Aren't player ratings  done only after quest is complete for that player
      if (this.areAllPlayerRatingsDone() && this.isQuestCompleteForAllPlayers()) {
        this.router.navigate([`/games/leaderboard/${this.gameInstance.gameCode}`]);
      }

      //Subsribe to messages about new players
      this.joinMessage = this.gameInstanceService.getJoinMessage().subscribe((messageObj: any) => {
        console.log(
          "Join message (livePreview) received message: " + messageObj.accountId + " currentQuestIndex: " + messageObj.currentQuestIndex
        );

        this.gameInstance = messageObj.gameInstance;
      });

      this.leaveMessage = this.gameInstanceService.getLeaveMessage().subscribe((messageObj: any) => {
        console.log("getLeaveMessage received message: ", messageObj);

        this.gameInstance = messageObj.gameInstance; //this will over-write current player's data

        this.setPlayerRatingStatus();
        this.setQuestCompleteStatusForAllPlayers();
        //TODO -  Do we really need to check if isQuestCompleteForAllPlayers? Aren't player ratings  done only after quest is complete for that player
        if (this.areAllPlayerRatingsDone() && this.isQuestCompleteForAllPlayers()) {
          this.router.navigate([`/games/leaderboard/${this.gameInstance.gameCode}`]);
        }
      });

      this.resultMessage = this.gameInstanceService.getResultMessage().subscribe((messageObj: any) => {
        console.log("getResultMessage received message: ", messageObj);

        this.gameInstance.players = messageObj.players; //this will over-write current player's data

        this.setPlayerRatingStatus(); //the player has to confirm if it's ratings have been provided by the host as part of forceFinish
        this.setQuestCompleteStatusForAllPlayers();
        //TODO -  Do we really need to check if isQuestCompleteForAllPlayers? Aren't player ratings  done only after quest is complete for that player
        if (this.areAllPlayerRatingsDone() && this.isQuestCompleteForAllPlayers()) {
          this.router.navigate([`/games/leaderboard/${this.gameInstance.gameCode}`]);
        }
      });

      if (this.route.snapshot.queryParams["forceFinish"] == "true") {
        for (let i = 0; i < this.gameInstance.players.length; i++) {
          if (i != this.currentPlayerIndex) {
            this.peerScore(this.gameInstance.players[i].account, 3);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.resultMessage.unsubscribe();
    this.leaveMessage.unsubscribe();
    this.joinMessage.unsubscribe();
  }
  private findCurrentPlayerIndex() {
    for (let i = 0; i < this.gameInstance.players.length; i++) {
      if (this.accountService.accountValue && this.gameInstance.players[i].account == this.accountService.accountValue.id) {
        this.currentPlayerIndex = i;
      }
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if game has finished
    if (this.areAllPlayerRatingsDone() && this.isQuestCompleteForAllPlayers()) {
      return true;
    }

    const confirmDialog$ = this.alertService.confirmDialog("Have you completed the game? If not, do you really want to quit the game?");
    confirmDialog$.subscribe((dialogReturn) => {
      console.log("return value: " + dialogReturn);
      if (dialogReturn) {
        this.gameInstanceService.leaveGame(this.gameInstance);
        // .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));
      }
    });
    // Otherwise ask the user with the dialog service and return its observable which resolves to true or false when the user decides
    return confirmDialog$;
  }

  private setPlayerRatingStatus() {
    for (let index = 0; index < this.gameInstance.players.length; index++) {
      this.playerRatingStatus[index] = false;
      if (index == this.currentPlayerIndex) {
        this.playerRatingStatus[index] = true; //don't need to rate yourself
      } else {
        //check if the player already rated
        if (this.gameInstance.players[index].peerRating[this.gameInstance.currentQuestIndex][this.currentPlayerIndex] != -1) {
          this.playerRatingStatus[index] = true;
        }
      }
    }
  }

  peerScore(playerId, rating, scoringPlayerIndex = this.currentPlayerIndex, setPlayerRatingStatus = true) {
    let scoredPlayerIndex: number;
    console.log("calling peerscore");

    for (let i = 0; i < this.gameInstance.players.length; i++) {
      if (this.gameInstance.players[i].account == playerId) {
        scoredPlayerIndex = i;
        if (setPlayerRatingStatus) {
          //true means that this function is called as part of forceFinish by host (and not the player themself), and we should not set playerRatingStatus for host here as it is doing this work on behalf of another player
          this.playerRatingStatus[i] = true;
        }
      }
    }

    this.gameInstance.players[scoredPlayerIndex].peerRating[this.gameInstance.currentQuestIndex][scoringPlayerIndex] = rating;

    const updatedGameInstance = {
      gameCode: +this.gameCode,
      currentQuestIndex: this.gameInstance.currentQuestIndex,
      currentQuestTimeLimit: this.gameInstance.currentQuestTimeLimit,
      players: [this.gameInstance.players[scoredPlayerIndex]],
      tempScoringPlayerIndex: scoringPlayerIndex,
    };

    //Big difference between updatePeerScore and updateUserCode
    //updateUserCode sends current player informtaion
    //updatePeerScore sends scored player information
    this.gameInstanceService
      .updatePeerScore(updatedGameInstance)
      .subscribe((gameInstance) => (this.gameInstance = gameInstance ? gameInstance : this.gameInstance));
  }

  //this function is replicated on server side as well, so any changes here should be reflected there as well
  private areAllPlayerRatingsDone(): boolean {
    console.log(this.gameInstance.players);

    //Quest complete only after the player has rated everyone
    for (let i = 0; i < this.gameInstance.players.length; i++) {
      if (!this.arePlayerRatingsDone(this.gameInstance.players[i])) return false;
    }
    // console.log("areAllPlayerRatingsDone called returning TRUE");
    return true;
  }

  private arePlayerRatingsDone(player: Player): boolean {
    for (let j = 0; j < this.gameInstance.players.length; j++) {
      if (
        player != this.gameInstance.players[j] && //can't rate myself
        /*this.gameInstance.players[j].playerStatus == "ACTIVE" &&*/ //Don't limit it to ACTIVE players only now, as host can force finish INACTIVE players
        player.peerRating[this.gameInstance.currentQuestIndex][j] == -1 //if rating value i== -1, means the player has not rated
      ) {
        console.log(
          "areAllPlayerRatingsDone returning false for: rating player = " +
            this.gameInstance.players[j].name +
            " rated player= " +
            player.name
        );
        return false;
      }
    }
    return true;
  }

  private isQuestCompleteForAllPlayers(): boolean {
    // console.log("isQuestCompleteForAllPlayers called for quest# - " + this.gameInstance.currentQuestIndex);
    for (let i = 0; i < this.gameInstance.players.length; i++) {
      if (!this.isQuestCompleteForPlayer(this.gameInstance.players[i])) return false;
    }
    return true;
  }

  private setQuestCompleteStatusForAllPlayers() {
    for (let i = 0; i < this.gameInstance.players.length; i++) {
      this.questCompleteStatus[i] = this.isQuestCompleteForPlayer(this.gameInstance.players[i]);
    }
  }

  private isQuestCompleteForPlayer(player: Player): boolean {
    const playerStatus = player.playerStatus;
    const questStatus = player.questStatus[this.gameInstance.currentQuestIndex];
    //Don't limit it to ACTIVE players only now, as host can force finish INACTIVE players
    if (/*playerStatus == "ACTIVE" &&*/ questStatus.toString().valueOf() != QuestStatus.ANSWERREVIEWED.valueOf()) {
      console.log("isQuestCompleteForAllPlayers returning FALSE for quest# - " + this.gameInstance.currentQuestIndex);
      return false;
    }
    console.log("isQuestCompleteForAllPlayers returning TRUE for quest# - " + this.gameInstance.currentQuestIndex);
    return true;
  }

  forceFinish(playerId) {
    let forcedPlayerIndex: number;
    console.log("calling forceFinish");

    for (let i = 0; i < this.gameInstance.players.length; i++) {
      if (this.gameInstance.players[i].account == playerId) {
        forcedPlayerIndex = i;
      }
    }

    //Begin - Update questStatus and questDuration for the player being forced
    this.gameInstance.players[forcedPlayerIndex].questStatus[this.gameInstance.currentQuestIndex] = QuestStatus.ANSWERREVIEWED;
    this.gameInstance.players[forcedPlayerIndex].questDuration[this.gameInstance.currentQuestIndex] = this.timeElapsedSinceQuestStarted();
    const updatedGameInstance = {
      gameCode: +this.gameCode,
      currentQuestIndex: this.gameInstance.currentQuestIndex,
      currentQuestTimeLimit: this.gameInstance.currentQuestTimeLimit,
      players: [this.gameInstance.players[forcedPlayerIndex]],
    };

    this.gameInstanceService.updateUserCode(updatedGameInstance, true).subscribe((gameInstance) => {
      this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
      const obj = {
        gameInstance: this.gameInstance,
        accountId: playerId,
      };
      //End - Update questStatus and questDuration for the player being forced

      //Begin - Score other players on forced player's behalf, if peerRating is allowed
      if (this.gameInstance.peerRatingAllowed) {
        for (let i = 0; i < this.gameInstance.players.length; i++) {
          if (this.gameInstance.players[i].account != playerId) {
            this.peerScore(this.gameInstance.players[i].account, 3, forcedPlayerIndex, false);
          }
        }
      }
      //End - Score other players on forced player's behalf, if peerRating is allowed

      this.gameInstanceService.sendGameMesssage("forceFinish", obj);
    });
  }

  private timeElapsedSinceQuestStarted(): number {
    return this.gameInstance ? Math.floor((Date.now() - this.gameInstance.currentQuestStartTime) / 1000) : 0;
  }

  public amIHost(): boolean {
    if (this.gameInstance && this.gameInstance.hostAccount.toString() == this.accountService.accountValue.id.toString()) {
      return true;
    }
    return false;
  }

  public hasPlayerRatedAllPlayers(forcedPlayerIndex: number) {
    for (let j = 0; j < this.gameInstance.players.length; j++) {
      if (j != forcedPlayerIndex && this.gameInstance.players[j].peerRating[this.gameInstance.currentQuestIndex][forcedPlayerIndex] == -1)
        return false;
    }

    return true;
  }

  setAnswerReviewed(): void {
    this.gameInstance.players[this.currentPlayerIndex].questStatus[this.gameInstance.currentQuestIndex] = QuestStatus.ANSWERREVIEWED;
    const updatedGameInstance = {
      gameCode: +this.gameCode,
      currentQuestIndex: this.gameInstance.currentQuestIndex,
      currentQuestTimeLimit: this.gameInstance.currentQuestTimeLimit,
      players: [this.gameInstance.players[this.currentPlayerIndex]],
    };

    this.gameInstanceService.updateUserCode(updatedGameInstance, true).subscribe((gameInstance) => {
      this.gameInstance = gameInstance ? gameInstance : this.gameInstance;
      this.reviewedSelfCode = this.isQuestCompleteForPlayer(this.gameInstance.players[this.currentPlayerIndex]);
    });
  }
}
