<div *ngIf="gameInstance">
  <div class="row text-center" *ngFor="let player of gameInstance.players">
    <div *ngIf="player.playerStatus == 'ACTIVE'" class="col-12">
      <h1>
        <span class="badge badge-pill badge-success"> {{ player.name }} </span>
      </h1>
    </div>
    <div *ngIf="player.playerStatus == 'INACTIVE'" class="col-12">
      <h1>
        <span class="badge badge-pill badge-danger" (click)="becomeActive()"> {{ player.name }} </span>
      </h1>
    </div>
  </div>
  <!-- <h2 class="text-secondary text-center">Am I Connected? - {{ isSocketConnected() }}</h2> -->
</div>
