export enum CodeHelperLinks {
  bootstrap = '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">',
  canvasHelper = '<script src="http://www.childrenscodingclub.com/assets/helpers/v2/canvas-helper.js" type="text/javascript" charset="utf-8"></script>',
  jQuery = '<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>',
  htmlWrapperCode = `<!DOCTYPE html>
  <html>
  
  <head>
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
      <title></title>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
      <script src="https://www.childrenscodingclub.com/assets/helpers/v2/canvas-helper.js" type="text/javascript" charset="utf-8"></script>
  </head>
  
  <body class="bg-danger">
      <!-- ALERT Add any HTML assignment code here-->
      
      <!-- DO NOT REMOVE Player's code will go in this quest-code div. -->
      <div id="quest-code">
  
      </div>
  </body>
  
  </html>`,
  jsWrapperCode = `<!DOCTYPE html>
  <html>
  
  <head>
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
      <title></title>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
      <script src="https://www.childrenscodingclub.com/assets/helpers/v2/canvas-helper.js" type="text/javascript" charset="utf-8"></script>
  </head>
  
  <body class="bg-danger">
      <!-- ALERT Add javascript assignment code in this script tag-->
      <script type="text/javascript">
          
      </script>
      <!-- DO NOT REMOVE Player's code will go in this quest-code div. -->
      <div id="quest-code">
  
      </div>
  </body>
  
  </html>`,
}
