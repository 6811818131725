<div class="container" *ngIf="competition && account && (account.role == 'Teacher' || account.role == 'Admin')">
  <div class="row mt-5 text-center">
    <div class="col-sm-12">
      <h2>{{ competition.name | uppercase }}</h2>
      <h4>{{ competition.fixtures.length }} - Fixtures</h4>
      <a routerLink="/competitions/{{ competition.id }}" routerLinkActive="router-link-active">{{ competition.name | uppercase }}</a>
    </div>
    <div class="btn btn-small btn-success text-center col-sm-12" (click)="addFixtureToCompetition(null)">New Fixture</div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-6">
      <div class="btn-group btn-group-toggle text-white" data-toggle="buttons">
        <label class="btn btn-secondary" (click)="displayJSON(true)">
          <input type="radio" name="options" id="option1" autocomplete="off" /> JSON
        </label>
        <label class="btn btn-secondary active" (click)="displayJSON(false)">
          <input type="radio" name="options" id="option2" autocomplete="off" checked /> Form Fields
        </label>
      </div>
    </div>

    <div class="col-sm-3 text-right">
      <div class="btn btn-danger" (click)="goBack()">Back</div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-primary" (click)="save()">Save Competition</div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-10 text-right p-2">Visibility:</div>
    <div class="col-sm-2 text-center pl-2">
      <select class="form-control" [(ngModel)]="competition.hidden" name="competitionHidden" class="pl-2">
        <option value="true">Hidden</option>
        <option value="false">Visible</option>
      </select>
    </div>
  </div>
  <div *ngIf="showJSON">
    <div class="row mt-5">
      <div class="col-sm-12">
        <textarea rows="25" cols="10" class="form-control form-control-lg" (change)="onValueChange($event)">
                  {{ competition | json }}
               </textarea
        >
      </div>
    </div>
  </div>

  <div *ngIf="!showJSON">
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Competition Name:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="competition.name" name="competitionName" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Competition Description:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="competition.description" name="competitionDescription" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Type:</div>
      <div class="col-lg-9">
        <!-- <input [(ngModel)]="game.gameSubscription" name="gameSubscription" /> -->
        <select class="form-control" [(ngModel)]="competition.type" name="competitionType">
          <option value="Hour of Code">Hour of Code</option>
          <option value="Club">Club</option>
          <option value="Competition">Competition</option>
        </select>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-3 text-right">Competition Date and Time:</div>
      <div class="col-lg-9">
        <input
          [ngModel]="competition.time | date: 'EEEE, MMM d, y, h:mm a O'"
          (ngModelChange)="updateCompetitionDate($event)"
          name="fixtureTime"
        />
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-3 text-right">Registration Required?:</div>
    <div class="col-lg-9">
      <!-- <input [(ngModel)]="game.gameSubscription" name="gameSubscription" /> -->
      <select class="form-control" [(ngModel)]="competition.registrationRequired" name="competitionRegistrationRequired">
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-3 text-right">Host:</div>
    <div class="col-lg-9">
      <!-- <input [(ngModel)]="game.gameSubscription" name="gameSubscription" /> -->
      <select class="form-control" [(ngModel)]="competition.host" name="competitionHost">
        <option [ngValue]="account.id" *ngFor="let account of teacherAccounts">{{ account.firstName }} {{ account.lastName }}</option>
      </select>
    </div>
  </div>
  <div class="row mt-5" *ngFor="let price of competition.price; let i = index">
    <div class="col-lg-3 text-left">Competition Price:</div>
    <div class="col-lg-3 text-left">Duration:</div>
    <div class="col-lg-3 text-left">Comments:</div>
    <div class="col-lg-3 text-left"></div>
    <div class="col-lg-3">
      <input [(ngModel)]="price.value" name="competitionPrice_{{ i }}" />
    </div>
    <div class="col-lg-3">
      <!-- <input [(ngModel)]="competition.price[0].duration" name="competitionDuration" /> -->
      <select class="form-control" [(ngModel)]="price.duration" name="competitionDuration_{{ i }}">
        <option [ngValue]="enum.value" *ngFor="let enum of PriceDuration | keyvalue" [selected]="price.duration === enum.value">
          {{ enum.value }}
        </option>
      </select>
    </div>
    <div class="col-lg-3">
      <input [(ngModel)]="price.comments" name="competitionComments_{{ i }}" />
    </div>
    <div class="col-lg-3">
      <div class="btn btn-danger btn-block mx-auto" (click)="removePriceOption(i)">X</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 text-right mx-auto" *ngFor="let enum of PriceDuration | keyvalue">
      <div class="btn btn-small btn-primary" (click)="addPriceOption(enum.value)">Add {{ enum.value }} Price Option</div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-12">
      <h2 class="col-sm-12 text-center">Note: Save each fixture individually</h2>
      <div *ngFor="let fixture of competition.fixtures; let i = index">
        <div id="accordion_competition">
          <div class="card">
            <div class="card-header" id="heading_fixture_{{ i }}">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse_fixture_' + i"
                      aria-expanded="true"
                      aria-controls="collapse_fixture_{{ i }}"
                    >
                      Fixture #{{ i + 1 }}
                    </button>
                  </h5>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveUp(i)" *ngIf="i != 0">Move Up</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveDown(i)" *ngIf="i != competition.fixtures.length - 1">Move Down</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-danger" (click)="removeFixtureFromCompetition(i)">Remove Fixture</button>
                </div>
              </div>
            </div>

            <div
              id="collapse_fixture_{{ i }}"
              class="collapse"
              aria-labelledby="heading_fixture_{{ i }}"
              data-parent="#accordion_competition"
            >
              <div class="card-body">
                <app-fixture-detail [fixtureId]="fixture" [competition]="competition"></app-fixture-detail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
