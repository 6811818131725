import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";

import { catchError } from "rxjs/operators";
import { environment } from "@environments/environment";
import { AccountService } from "./account.service";

@Injectable({
  providedIn: "root",
})
export class PythonService {
  constructor(private http: HttpClient, private accountService: AccountService) {}

  baseUrl = `${environment.apiUrl}/python`;

  run(urlName: string, gameFile: string): Observable<string> {
    const url = this.baseUrl + "/run?urlName=" + urlName + "&gameFile=" + gameFile;

    return this.http.get<string>(url).pipe(
      catchError((err) => {
        console.log("error caught in service");
        console.error(err);
        return throwError(err); //Rethrow it back to component
      })
    );
  }
}
