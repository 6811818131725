import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./app-home";
import { AuthGuard } from "./_helpers";
import { Role } from "./_models";
import { HomeModule } from "./home/home.module";
import { CompetitionsModule } from "./competitions/competitions.module";

const accountModule = () => import("./account/account.module").then((x) => x.AccountModule);
const adminModule = () => import("./admin/admin.module").then((x) => x.AdminModule);
const accountSettingsModule = () => import("./account-settings/account-settings.module").then((x) => x.AccountSettingsModule);
const profileModule = () => import("./profile/profile.module").then((x) => x.ProfileModule);
const questsModule = () => import("./quests/quests.module").then((x) => x.QuestsModule);
const gamesModule = () => import("./games/games.module").then((x) => x.GamesModule);
const unitsModule = () => import("./units/units.module").then((x) => x.UnitsModule);
const courseworkModule = () => import("./coursework/coursework.module").then((x) => x.CourseworkModule);
const homeModule = () => import("./home/home.module").then((x) => x.HomeModule);
const competitionsModule = () => import("./competitions/competitions.module").then((x) => x.CompetitionsModule);
const codeModule = () => import("./code/code.module").then((x) => x.CodeModule);

const routes: Routes = [
  // { path: "", component: HomeComponent, canActivate: [AuthGuard] },
  { path: "", loadChildren: homeModule },
  { path: "account", loadChildren: accountModule },
  { path: "account-settings", loadChildren: accountSettingsModule },
  { path: "admin", loadChildren: adminModule, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  { path: "profile", loadChildren: profileModule },
  { path: "quests", loadChildren: questsModule, canActivate: [AuthGuard] },
  { path: "games", loadChildren: gamesModule },
  { path: "units", loadChildren: unitsModule },
  { path: "coursework", loadChildren: courseworkModule },
  { path: "competitions", loadChildren: competitionsModule },
  { path: "code", loadChildren: codeModule },
  // otherwise redirect to home
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy", anchorScrolling: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
