<div class="container-fluid no-gutters p-0 m-0 text-white bg-dark">
  <div class="row text-center pt-2 pb-3">
    <div class="col-6 text-right">
      <button class="btn" [ngClass]="{ 'btn-link': codeLanguage == 'python' }" (click)="codeLanguage = 'python'">Python Editor</button>
    </div>
    <div class="col-6 text-left">
      <button class="btn" [ngClass]="{ 'btn-link': codeLanguage == 'html' }" (click)="codeLanguage = 'html'">HTML Editor</button>
    </div>
  </div>
</div>
<div class="container-fluid no-gutters p-0 m-0">
  <div class="row no-gutters">
    <div class="col-sm-8">
      <app-code-language-menu [code]="code" [codeLanguage]="codeLanguage" (action)="takeAction($event)"></app-code-language-menu>
      <app-code-editor [code]="code" [codeLanguage]="codeLanguage" (userCodeChanged)="onUserCodeChanged($event)"></app-code-editor>
    </div>
    <div class="col-sm-4">
      <div class="row bg-dark">
        <div class="btn btn-small btn-dark text-center text-white col-sm-12">Result</div>
      </div>
      <div *ngIf="codeLanguage == 'html'" class="col-sm-12 p-0" style="height: 1000px">
        <app-code-preview [code]="code" [wrapperCode]="wrapperCode" [codeLanguage]="codeLanguage"></app-code-preview>
      </div>
      <div *ngIf="codeLanguage == 'python'" class="col-sm-12 p-0" style="height: 1000px">
        <app-python-result [pythonResult]="previewContent"></app-python-result>
      </div>
    </div>
  </div>
</div>
