<div class="container align-items-center" *ngIf="game && account.role === Role.Admin">
  <div class="row mt-5 text-center">
    <div class="col-sm-12">
      <h2>{{ game.name | uppercase }}</h2>
    </div>
    <div class="btn btn-small btn-success text-center col-sm-12" (click)="addQuestToGame(null)">New Quest</div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-6">
      <div class="btn-group btn-group-toggle text-white" data-toggle="buttons">
        <label class="btn btn-secondary" (click)="displayJSON(true)">
          <input type="radio" name="options" id="option1" autocomplete="off" /> JSON
        </label>
        <label class="btn btn-secondary active" (click)="displayJSON(false)">
          <input type="radio" name="options" id="option2" autocomplete="off" checked /> Form Fields
        </label>
      </div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-danger" (click)="goBack()">Back</div>
    </div>
    <div class="col-sm-3 text-right">
      <div class="btn btn-primary" (click)="save()">Save Game</div>
    </div>
  </div>
  <div *ngIf="showJSON">
    <div class="row mt-5">
      <div class="col-sm-12">
        <textarea rows="25" cols="10" class="form-control form-control-lg" (change)="onValueChange($event)">
                {{ game | json }}
             </textarea
        >
      </div>
    </div>
  </div>
  <div *ngIf="!showJSON">
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Game Name:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="game.name" name="gameName" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Game Description:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="game.description" name="gameDescription" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Game File Name:</div>
      <div class="col-lg-9">
        <input [(ngModel)]="game.gameFile" name="gameFile" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 text-right">Game Subscription:</div>
      <div class="col-lg-9">
        <!-- <input [(ngModel)]="game.gameSubscription" name="gameSubscription" /> -->
        <select class="form-control" [(ngModel)]="game.gameSubscription" name="gameSubscription">
          <option value="FREE">FREE</option>
          <option value="PREMIUM">PREMIUM</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-sm-12">
      <h2 class="col-sm-12 text-center">Note: Save each quest individually</h2>
      <div *ngFor="let quest of game.quests; let i = index">
        <div id="accordion_game">
          <div class="card">
            <div class="card-header" id="heading_quest_{{ i }}">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse_quest_' + i"
                      aria-expanded="true"
                      aria-controls="collapse_quest_{{ i }}"
                    >
                      Quest #{{ i + 1 }}
                    </button>
                  </h5>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveUp(i)" *ngIf="i != 0">Move Up</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-info" (click)="moveDown(i)" *ngIf="i != game.quests.length - 1">Move Down</button>
                </div>
                <div class="col-sm-3 text-right">
                  <button class="btn btn-danger" (click)="removeQuestFromGame(i)">Delete Quest</button>
                </div>
              </div>
            </div>

            <div id="collapse_quest_{{ i }}" class="collapse" aria-labelledby="heading_quest_{{ i }}" data-parent="#accordion_game">
              <div class="card-body">
                <app-quest-detail [questId]="quest" [quest]="null"></app-quest-detail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-sm-8 text-right"></div>
    <div class="col-sm-2 text-right">
      <div class="btn btn-info" (click)="duplicateLastQuest()">Duplicate Last Quest</div>
    </div>
    <div class="col-sm-2 text-right">
      <div class="btn btn-success" (click)="addQuestToGame()">Add Quest</div>
    </div>
  </div>
</div>
